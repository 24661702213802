import { useMemo } from 'react';

import { PolygonLayer } from '@deck.gl/layers';

import { PolygonFeature } from 'src/api';
import { useGetFieldOutlineFeatureCollection } from 'src/pages/MapView/components/Map/hooks/useGetFieldOutlineFeatureCollection';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { MapBackground, MapLayer } from 'src/pages/MapView/MapView.types';

export function useFieldOutline() {
  const { filteredOutlineFeatures } = useGetFieldOutlineFeatureCollection();
  const { backgroundLayer } = useMapOptions();

  interface FieldOutlineFeature {
    feature: PolygonFeature;
    isInsideMainOutline: boolean;
  }

  return useMemo(
    () =>
      new PolygonLayer({
        id: MapLayer.FIELD_OUTLINE,
        filled: backgroundLayer !== MapBackground.INTERPOLATED,
        getFillColor: (d: FieldOutlineFeature) =>
          d.isInsideMainOutline ? [220, 220, 220] : [255, 255, 255], // d.isInsideMainOutline ? ui.background.medium : colors.text.static_icons__primary_white
        data: filteredOutlineFeatures,
        getPolygon: (d: FieldOutlineFeature) => d.feature.geometry?.coordinates,
        getLineColor: [111, 111, 111], // Equinor text - static icons tertiary
        getLineWidth: 1,
        lineWidthMinPixels: 2,
      }),
    [backgroundLayer, filteredOutlineFeatures]
  );
}
