import React, { FC, useEffect, useMemo } from 'react';

import { HeaderRow } from '../../HeaderRow/HeaderRow';
import { NoItemsFound } from '../../NoItemsFound/NoItemsFound';
import { PrognosisCard } from '../../PrognosisCard/PrognosisCard';
import { PrognosisCardSkeleton } from '../../PrognosisCard/PrognosisCardSkeleton';
import { PageContent, PrognosesContainer } from '../CommonTabStyles.styles';
import { MIDDLE_PAGE } from 'src/constants/queryKeys';
import { TabType } from 'src/constants/tab';
import { useInView, usePrognoses, useSortDirection } from 'src/hooks';
import { useDashboard } from 'src/pages/Dashboard/hooks/useDashboard';
import { groupByWellBore, sortByValid, sortGroupedData } from 'src/utils/tab';

interface TabProps {
  currentTab: TabType;
}

export const Tab: FC<TabProps> = ({ currentTab }) => {
  const { sortDirection, handleSort } = useSortDirection();
  const { filterValue } = useDashboard();
  const { ref, inView } = useInView(undefined);
  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isPending,
    isError,
    isSearching,
  } = usePrognoses({
    currentTab: currentTab,
    filterValue,
    sortDirection: sortDirection,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const sortedData = useMemo(() => {
    if (currentTab === TabType.APPROVED) {
      const sortedGroupedData = sortGroupedData(
        groupByWellBore(data?.pages[0])
      );
      return sortByValid(sortedGroupedData, sortDirection);
    }
    return {};
  }, [currentTab, data, sortDirection]);

  const isEmpty =
    !data || data.pages.length === 0 || data.pages[0].length === 0;

  if (
    isError ||
    (isEmpty && !isPending && !isFetchingNextPage && !isSearching)
  ) {
    return <NoItemsFound tabType={currentTab} />;
  }

  return (
    <PageContent>
      <PrognosesContainer>
        <HeaderRow
          currentTab={currentTab}
          handleSort={handleSort}
          sortDirection={sortDirection}
        />
        {isPending || isSearching
          ? new Array(5)
              .fill(0)
              .map((skeleton, index) => (
                <PrognosisCardSkeleton
                  key={`skeleton-${skeleton + index}`}
                  offset={index * 100}
                />
              ))
          : data?.pages.map((page, i) => (
              <React.Fragment key={i}>
                {currentTab === TabType.APPROVED
                  ? Object.keys(sortedData ?? {}).map((wellbore, index) => {
                      const [firstPrognosis, ...relatedPrognoses] =
                        sortedData[wellbore];
                      return (
                        <div
                          key={index}
                          ref={index === MIDDLE_PAGE ? ref : null}
                        >
                          <PrognosisCard
                            {...firstPrognosis}
                            relatedPrognoses={relatedPrognoses}
                            currentTab={currentTab}
                          />
                        </div>
                      );
                    })
                  : page?.map((data, index) => (
                      <div key={index} ref={index === MIDDLE_PAGE ? ref : null}>
                        <PrognosisCard {...data} currentTab={currentTab} />
                      </div>
                    ))}
              </React.Fragment>
            ))}
        {isFetchingNextPage &&
          new Array(5)
            .fill(0)
            .map((skeleton, index) => (
              <PrognosisCardSkeleton
                key={`skeleton-${skeleton + index}`}
                offset={index * 100}
              />
            ))}
      </PrognosesContainer>
    </PageContent>
  );
};
