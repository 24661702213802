import { spacings } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const Container = styled.div``;

export const SingleSelectContainer = styled.div`
  flex: 1;
`;

export const ComboSelectContainer = styled.div`
  flex: 2;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: ${spacings.small};
`;

export const ChipContainer = styled.div`
  display: flex;
  gap: ${spacings.small};
  padding-bottom: ${spacings.medium};
  flex-wrap: wrap;
`;

export const OptionsContainer = styled.div`
  overflow: scroll;
  height: 400px;
`;
