import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { spacings } from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';
import { useIsMutating } from '@tanstack/react-query';

import { AttributeChip } from '../../AttributeChip/AttributeChip';
import { BorderedSection } from '../BorderedSection/BorderedSection';
import { PrognosisDetailDto } from 'src/api';
import { SHOW_ATTRIBUTE_CHIPS, SHOW_COMINGLED_CHIP } from 'src/constants';
import { useDeleteDetail } from 'src/pages/EditPrognosis/hooks/useDeleteDetail';
import { useOptionalAttributes } from 'src/pages/EditPrognosis/hooks/useOptionalAttributes';
import { usePostDetail } from 'src/pages/EditPrognosis/hooks/usePostDetail';
import { usePutDetail } from 'src/pages/EditPrognosis/hooks/usePutDetail';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

import { useGetAllWellbores } from 'hooks/useGetWellbores';
import { usePrognosisDetailsFromId } from 'hooks/usePrognosisDetailsFromId';
import { styled } from 'styled-components';

const ChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${spacings.medium};
`;

const Row = styled.div`
  display: flex;
  gap: ${spacings.medium};
`;

export const OptionalAttributes: FC = () => {
  const { optionalAttributes, setOptionalAttributes } = useOptionalAttributes();
  const { mutate: addPrognosisDetail } = usePostDetail();
  const { mutate: deletePrognosisDetail } = useDeleteDetail();
  const { mutate: updatePrognosisDetail } = usePutDetail();
  const { data } = usePrognosisDetailsFromId();
  const isMutating = useIsMutating();
  const { watch } = useFormContext<EditPrognosisFormValues>();

  const { showContent: showAttributeChips } = useFeatureToggling({
    featureKey: SHOW_ATTRIBUTE_CHIPS,
  });

  const { showContent: showCoMingledChip } = useFeatureToggling({
    featureKey: SHOW_COMINGLED_CHIP,
  });

  const { data: wellboreData } = useGetAllWellbores();
  const { wellbore, prognosisDetails } = watch();

  const selectedWellboreData = wellboreData?.find(
    (item) => item.wellboreUuid === wellbore?.value
  );

  const onToggleSegments = () => {
    // TODO: bulk update rows to remove segment column
    if (isMutating) return;
    setOptionalAttributes((prev) => ({
      ...prev,
      segment: !prev.segment,
    }));

    if (optionalAttributes.segment) {
      prognosisDetails.map((detail) => {
        if (detail.segment) {
          detail.segment = undefined;
          updatePrognosisDetail(detail);
        }
      });
    }
  };

  const onToggleDatum = () => {
    // TODO: bulk update rows to remove datum columns
    setOptionalAttributes((prev) => ({
      ...prev,
      datumDepth: !prev.datumDepth,
    }));
  };

  const onToggleInitialPressure = () => {
    // TODO: bulk update rows to remove initial pressure columns
    setOptionalAttributes((prev) => ({
      ...prev,
      initialPressure: !prev.initialPressure,
    }));
  };

  const onToggleMinPressure = () => {
    // TODO: bulk update rows to remove min pressure columns
    setOptionalAttributes((prev) => ({
      ...prev,
      minimumPressure: !prev.minimumPressure,
    }));
  };

  const onToggleMaxPressure = () => {
    // TODO: bulk update rows to remove max pressure columns
    setOptionalAttributes((prev) => ({
      ...prev,
      maximumPressure: !prev.maximumPressure,
    }));
  };

  const onToggleTotalDepthRow = () => {
    if (isMutating) return;
    setOptionalAttributes((prev) => ({
      ...prev,
      totalDepthRow: !prev.totalDepthRow,
    }));

    if (!optionalAttributes.totalDepthRow) {
      const totalDepthRowDetail: Partial<PrognosisDetailDto> = {
        lithostratSubzone: 'Total depth',
        reservoirZone: 'Total depth',
        stratColumnIdentifier: 'Total depth',
        referenceDepth: selectedWellboreData?.totalDepthDrillerTvd ?? 0,
      };

      addPrognosisDetail({ detail: totalDepthRowDetail });
    } else {
      const detailToDelete = data?.find(
        (detail) => detail.reservoirZone === 'Total depth'
      );

      if (detailToDelete) {
        deletePrognosisDetail(detailToDelete?.id);
      }
    }
  };

  return (
    <BorderedSection title="Select the prognosis attributes">
      <ChipContainer>
        <Row>
          {showCoMingledChip && (
            <AttributeChip variant="disabled" label="Co-mingled zones" />
          )}
          {showAttributeChips && (
            <>
              <AttributeChip
                variant="disabled"
                label="Use prognosed depth to zone top from Pozo"
              />
              <AttributeChip
                variant="disabled"
                label="Use STAT wellpick for top zone"
              />
              <AttributeChip
                variant="disabled"
                label="Use existing reference data"
              />
              <AttributeChip
                variant="disabled"
                label="Use existing field values"
              />
            </>
          )}
        </Row>
        <Row>
          <AttributeChip
            label="Datum depth"
            onClick={onToggleDatum}
            isSelected={optionalAttributes.datumDepth}
          />
          <AttributeChip
            label="Segments"
            onClick={onToggleSegments}
            isSelected={optionalAttributes.segment}
          />
          <AttributeChip
            label="Initial pressure"
            onClick={onToggleInitialPressure}
            isSelected={optionalAttributes.initialPressure}
          />
          <AttributeChip
            label="Min pressure"
            onClick={onToggleMinPressure}
            isSelected={optionalAttributes.minimumPressure}
          />
          <AttributeChip
            label="Max pressure"
            onClick={onToggleMaxPressure}
            isSelected={optionalAttributes.maximumPressure}
          />
          <AttributeChip
            label="Total depth"
            onClick={onToggleTotalDepthRow}
            isSelected={optionalAttributes.totalDepthRow}
          />
        </Row>
      </ChipContainer>
    </BorderedSection>
    //
  );
};
