import { FC } from 'react';

import {
  colors,
  spacings,
  Switch,
  Typography,
} from '@equinor/amplify-component-lib';

import { Unit } from 'src/types/prognosisDetails';

import { useApp } from 'hooks/useApp';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.ui.background__medium.rgba};
  border-radius: ${spacings.small};
  padding: 0px ${spacings.small};
  align-self: flex-end;
  position: relative;
`;

export const UnitToggle: FC = () => {
  const { unit, setUnit } = useApp();

  const handleUnitToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setUnit(Unit.SG);
    } else {
      setUnit(Unit.BAR);
    }
  };

  const setActiveColor = (activeUnit: Unit) => {
    if (unit === activeUnit) {
      return colors.interactive.primary__resting.rgba;
    }
    return colors.text.static_icons__tertiary.rgba;
  };

  return (
    <Container>
      <Typography color={setActiveColor(Unit.BAR)} bold>
        Bar
      </Typography>
      <Switch
        label=""
        onChange={handleUnitToggle}
        value={unit}
        checked={unit === Unit.SG}
      />
      <Typography bold color={setActiveColor(Unit.SG)}>
        SG
      </Typography>
    </Container>
  );
};
