import { spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors, shape, elevation } = tokens;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  padding: ${spacings.large};
  background: ${colors.ui.background__default.rgba};
  border-radius: ${shape.corners.borderRadius};
  box-shadow: ${elevation.raised};
`;

export const ChartContainer = styled.div`
  height: 100%;
  padding: ${spacings.medium};
  border-radius: ${shape.corners.borderRadius};
  background-color: ${colors.ui.background__default.rgba};
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
