import { FC } from 'react';

import { TopBar } from '@equinor/amplify-component-lib';
import { SettingsProps } from '@equinor/amplify-component-lib/dist/organisms/TopBar';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { TOGGLE_APPROVER_OPTIONS } from 'src/constants';
import { useApp } from 'src/hooks';
import { ViewPrognosesOptions } from 'src/utils/dashboard';

import { useGetUserAccess } from 'hooks/useGetUserAccess';

export const Settings: FC = () => {
  const { draftGroup, setDraftGroup } = useApp();
  const { userAccessForField } = useGetUserAccess();
  const { showContent } = useFeatureToggling({
    featureKey: TOGGLE_APPROVER_OPTIONS,
  });

  const settingsOptions: SettingsProps = {
    allSettings: [],
  };

  if (userAccessForField?.userRoles?.approver && showContent) {
    (settingsOptions.allSettings ?? []).push({
      title: 'Approver view options',
      value: draftGroup,
      onChange: (val: string) => setDraftGroup(val as ViewPrognosesOptions),
      items: [
        {
          label: 'Prognoses submitted to me',
          name: 'draft-group',
          value: ViewPrognosesOptions.SUBMITTED_TO_ME,
        },
        {
          label: 'All prognoses assigned to me',
          name: 'draft-group',
          value: ViewPrognosesOptions.ASSIGNED_TO_ME,
        },
        {
          label: 'All prognoses on the field',
          name: 'draft-group',
          value: ViewPrognosesOptions.ALL_PROGNOSES,
        },
      ],
    });
  }

  return <TopBar.Settings allSettings={settingsOptions.allSettings} />;
};
