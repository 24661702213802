import { PrognosisDetailDto } from 'src/api';
import { useOptionalAttributes } from 'src/pages/EditPrognosis/hooks/useOptionalAttributes';
import { OptionalAttributes } from 'src/pages/EditPrognosis/providers/OptionalAttributeProvider';

import { z } from 'zod';

/**
 * Creates a function that validates if a given number or string value
 * conforms to the specified precision and scale.
 *
 * @param precision - The total number of digits allowed, including both integer and fractional parts.
 * @param scale - The number of digits allowed after the decimal point.
 * @returns A function that takes a number or string value and returns a boolean indicating
 *          whether the value conforms to the specified precision and scale.
 *
 * @example
 * const validate = precisionScale(5, 2);
 * validate(123.45); // true
 * validate(1234.5); // true
 * validate(12345.67); // false
 * validate('123.45'); // true
 * validate('1234.567'); // false
 */
const precisionScale =
  (precision: number, scale: number) => (value: number | string) => {
    const normalizedValue = value.toString().replace(',', '.');
    const [integerPart, decimalPart] = normalizedValue.split('.');
    return (
      integerPart.length <= precision - scale &&
      (!decimalPart || decimalPart.length <= scale)
    );
  };

const createPrognosisDetailSchema = (
  optionalAttributes: OptionalAttributes
): z.ZodType<PrognosisDetailDto> => {
  return z.object({
    id: z.number(),
    prognosisId: z.number(),
    lithostratSubzone: z.string(),
    reservoirZoneNickname: z.coerce
      .string()
      .max(30, 'Nickname must be less than 30 characters')
      .optional(),
    referenceDepth: z.coerce
      .number()
      .positive('Reference depth must be greater than 0')
      .refine(
        precisionScale(7, 3),
        'Reference depth must have a precision of 7, 3'
      ),
    pressureInitial: optionalAttributes.initialPressure
      ? z.coerce
          .number()
          .positive('Initial reference pressure must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'Initial reference pressure must have a precision of 7, 3'
          )
      : z.coerce.number().optional(),
    pressureExpected: z.coerce
      .number()
      .positive('Expected reference pressure must be greater than 0')
      .refine(
        precisionScale(7, 3),
        'Expected reference pressure must have a precision of 7, 3'
      ),
    pressureLow: z.coerce
      .number()
      .positive('Low reference pressure must be greater than 0')
      .refine(
        precisionScale(7, 3),
        'Low reference pressure must have a precision of 7, 3'
      ),
    pressureHigh: z.coerce
      .number()
      .positive('High reference pressure must be greater than 0')
      .refine(
        precisionScale(7, 3),
        'High reference pressure must have a precision of 7, 3'
      ),
    pressureGradientDepth: z.coerce
      .number()
      .positive('Pressure gradient depth must be greater than 0')
      .refine(
        precisionScale(7, 4),
        'Pressure gradient depth must have a precision of 7, 4'
      ),
    fluidType: z.string().min(1, 'Fluid type is required'),
    comment: z.coerce
      .string()
      .max(100, 'Comment must be less than 100 characters')
      .optional(),
    segment: optionalAttributes.segment
      ? z
          .string()
          .min(1, 'Segment is required')
          .max(30, 'Segment must be less than 30 characters')
      : z.coerce
          .string()
          .max(30, 'Segment must be less than 30 characters')
          .optional(),
    datumDepth: optionalAttributes.datumDepth
      ? z.coerce
          .number()
          .positive('Datum depth must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'Datum depth must have a precision of 7, 3'
          )
      : z.coerce.number().optional(),
    pressureDatumExpected: optionalAttributes.datumDepth
      ? z.coerce
          .number()
          .positive('Expected datum pressure must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'Expected datum pressure must have a precision of 7, 3'
          )
      : z.coerce.number().optional(),
    pressureDatumLow: optionalAttributes.datumDepth
      ? z.coerce
          .number()
          .positive('Low datum pressure must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'Low datum pressure must have a precision of 7, 3'
          )
      : z.coerce.number().optional(),
    pressureDatumHigh: optionalAttributes.datumDepth
      ? z.coerce
          .number()
          .positive('High datum pressure must be greater than 0')
          .refine(
            precisionScale(7, 3),
            'High datum pressure must have a precision of 7, 3'
          )
      : z.coerce.number().optional(),
    pressureGradientToDatumDepth: optionalAttributes.datumDepth
      ? z.coerce
          .number()
          .positive('Gradient to datum depth must be greater than 0')
          .refine(
            precisionScale(7, 4),
            'Gradient to datum depth must have a precision of 7, 4'
          )
      : z.coerce.number().optional(),
    stratColumnIdentifier: z.string(),
    reservoirZone: z.string(),
    depthUnit: z.string(),
    pressureUnit: z.string(),
    temperatureUnit: z.string(),
    pressureGradientDepthUnit: z.string(),
  });
};

const createSubmissionSchema = (optionalAttributes: OptionalAttributes) => {
  const prognosisDetailSchema = createPrognosisDetailSchema(optionalAttributes);

  return z
    .object({
      title: z
        .string()
        .min(1, 'Title is required')
        .refine((val) => val.length >= 5, {
          message: 'Minimum of 5 characters required',
        }),
      operation: z.object({
        value: z.string(),
        label: z.string(),
      }),
      operationPhase: z
        .object({
          value: z.string(),
          label: z.string(),
        })
        .nullable()
        .refine((val) => val !== null && val.value !== '', {
          message: 'Operation phase is required',
        }),
      mainApprover: z.object({
        value: z.string(),
        label: z.string(),
      }),
      backupApprover: z.object({
        value: z.string(),
        label: z.string(),
      }),
      validFrom: z
        .string()
        .min(1, 'Valid from date is required')
        .refine(
          (val) => {
            const validFromDate = new Date(val);
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set today to 00:00

            return validFromDate >= today;
          },
          {
            message: 'Valid from date must be in the future',
          }
        ),
      validTo: z
        .string()
        .min(1, 'Valid to date is required')
        .refine(
          (val) => {
            const validToDate = new Date(val);
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Set today to 00:00

            return validToDate >= today;
          },
          {
            message: 'Valid to date must be in the future',
          }
        ),
      wellbore: z.object({
        value: z.string(),
        label: z.string(),
      }),
      comment: z.string().optional(),
      prognosisDetails: z.array(prognosisDetailSchema),
      stratColumnIdentifier: z
        .object({
          value: z.string(),
          label: z.string(),
        })
        .optional(),
    })
    .refine(
      (data) => {
        const validFromDate = new Date(data.validFrom);
        const validToDate = new Date(data.validTo);
        return validFromDate < validToDate;
      },
      {
        message: 'Valid to date must be after valid from date',
        path: ['validTo'],
      }
    );
};

export const useEditPrognosisSchema = () => {
  const { optionalAttributes } = useOptionalAttributes();
  const schema = createSubmissionSchema(optionalAttributes);
  return schema;
};

export type EditPrognosisFormValues = z.infer<
  ReturnType<typeof useEditPrognosisSchema>
>;
