import { FC, useMemo, useState } from 'react';

import {
  Button,
  Icon,
  SelectOptionRequired,
  Typography,
} from '@equinor/amplify-component-lib';
import { view_list } from '@equinor/eds-icons';

import { useDashboard } from '../../hooks/useDashboard';
import { FilterValue } from '../../providers/DashboardProvider';
import { FilterSaveDialog } from './FilterSaveDialog/FilterSaveDialog';
import { FilterViewEditDialog } from './FilterViewEditDialog/FilterViewEditDialog';
import { ModifiedDateRow } from './ModifiedDateRow/ModifiedDateRow';
import { FilterOption } from './FilterOption';
import {
  Filters,
  Header,
  Section,
  StyledTypography,
  Wrapper,
} from './FilterSidebar.styles';
import { useLookupValues } from 'src/hooks';
import { lookupValuesToComboBoxRequired } from 'src/utils/lookup';

import { AnimatePresence } from 'framer-motion';

const SELECT_FILTERS: (keyof FilterValue)[] = [
  'status',
  'operation',
  'wellbore',
  'reservoirZone',
  'createdBy',
  'approver',
  'mainApprover',
  'backupApprover',
];

interface FilterSidebarProps {
  open: boolean;
}

export const FilterSidebar: FC<FilterSidebarProps> = ({ open }) => {
  const { filterValue, clearFilterValue } = useDashboard();
  const { data } = useLookupValues();

  const [isFilterViewEditDialogOpen, setIsFilterViewEditDialogOpen] =
    useState(false);
  const [isFilterSaveDialogOpen, setIsFilterSaveDialogOpen] = useState(false);

  const isDisabled = Object.values(filterValue).every((value) => !value);

  const filterOptions: Record<string, SelectOptionRequired[]> = useMemo(() => {
    return {
      status: lookupValuesToComboBoxRequired(data?.statuses, true),
      operation: lookupValuesToComboBoxRequired(data?.operations, true),
      wellbore: lookupValuesToComboBoxRequired(data?.wellbores),
      reservoirZone: lookupValuesToComboBoxRequired(data?.reservoirZones),
      createdBy: lookupValuesToComboBoxRequired(data?.createUsers),
      approver: lookupValuesToComboBoxRequired(data?.approveUsers),
    };
  }, [data]);

  const toggleFilterViewEditDialog = () => {
    setIsFilterViewEditDialogOpen((prev) => !prev);
  };

  const toggleFilterSaveDialog = () => {
    setIsFilterSaveDialogOpen((prev) => !prev);
  };

  return (
    <>
      <AnimatePresence>
        {open && (
          <Wrapper
            initial={{ width: 0 }}
            animate={{ width: '320px' }}
            exit={{ width: 0 }}
          >
            <Filters>
              <Header>
                <StyledTypography group="heading" variant="h5">
                  Filters
                </StyledTypography>
                <Button variant="ghost" onClick={toggleFilterViewEditDialog}>
                  Load view
                  <Icon data={view_list} />
                </Button>
              </Header>
              <Section>
                {SELECT_FILTERS.map((filterValueKey) => (
                  <FilterOption
                    key={`filter-option-${filterValueKey}`}
                    filterValueKey={filterValueKey}
                    items={filterOptions[filterValueKey] ?? []}
                    placeholder="All"
                  />
                ))}
              </Section>
              <Section>
                <Typography group="navigation" variant="menu_tabs">
                  Last modified
                </Typography>
                <ModifiedDateRow />
              </Section>
              <Section>
                <Button
                  variant="outlined"
                  onClick={clearFilterValue}
                  disabled={isDisabled}
                >
                  Clear Filters
                </Button>
                <Button onClick={toggleFilterSaveDialog}>
                  Save this filtered view
                </Button>
              </Section>
            </Filters>
          </Wrapper>
        )}
      </AnimatePresence>
      <FilterViewEditDialog
        open={isFilterViewEditDialogOpen}
        toggleFilterViewEditDialog={toggleFilterViewEditDialog}
      />
      <FilterSaveDialog
        open={isFilterSaveDialogOpen}
        toggleFilterSaveDialog={toggleFilterSaveDialog}
      />
    </>
  );
};
