import { FC, Fragment, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Dialog,
  SelectOptionRequired,
  Typography,
} from '@equinor/amplify-component-lib';
import { Icon } from '@equinor/eds-core-react';
import { search } from '@equinor/eds-icons';

import { LastUsedZones } from './LastUsedZones/LastUsedZones';
import { SelectColumn } from './SelectColumn/SelectColumn';
import {
  MappedStratigraphicUnit,
  ZoneNavigator,
} from './ZoneNavigator/ZoneNavigator';
import {
  ColumnName,
  Container,
  ContentWrapper,
  DialogChildren,
  OpenDialogButton,
  TypographyWithMargin,
} from './ZoneSelector.styles';
import { PrognosisDetailDto, Stratigraphy } from 'src/api';
import {
  useGetStratigraphicValues,
  useGetStratigraphicValuesDrilled,
  useGetStratigraphicValuesPlanned,
} from 'src/hooks/useGetStratigraphicValues';
import { usePostDetail } from 'src/pages/EditPrognosis/hooks/usePostDetail';
import { EditPrognosisFormValues, OperationValues } from 'src/utils';

export interface SelectOptionRequiredWithColor extends SelectOptionRequired {
  colorHtml: string;
  topAge?: number;
  stratColumnIdentifier?: string;
}

export const ZoneSelector: FC = () => {
  const { watch } = useFormContext<EditPrognosisFormValues>();

  const { operation } = watch();
  const { mutate: addPrognosisDetail } = usePostDetail();
  const { data: all } = useGetStratigraphicValues();
  const { data: planned } = useGetStratigraphicValuesPlanned();
  const { data: drilled } = useGetStratigraphicValuesDrilled();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [subZoneList, setSubZoneList] = useState<Partial<PrognosisDetailDto>[]>(
    []
  );

  const stratigraphy: Stratigraphy[] = useMemo(() => {
    if (operation?.value.toString() === OperationValues.NEW_WELL.toString()) {
      return planned ?? [];
    }

    if (
      operation?.value.toString() === OperationValues.INTERVENTION.toString() ||
      operation?.value.toString() === OperationValues.P_N_A.toString()
    ) {
      return drilled ?? [];
    }

    if (operation?.value.toString() === OperationValues.OTHER.toString()) {
      return all ?? [];
    }

    return [];
  }, [all, drilled, operation, planned]);

  const handleAddPrognosisDetails = (subzones: MappedStratigraphicUnit[]) => {
    const newDetails = subzones.map((subzone) => {
      const newDetail: Partial<PrognosisDetailDto> = {
        lithostratSubzone: subzone.value,
        stratColumnIdentifier: subzone.stratColumnIdentifier!,
        topAge: subzone.topAge,
        colorHtml: subzone.colorHtml,
      };
      return newDetail;
    });

    setSubZoneList(newDetails);
  };

  const handleDialogOpen = () => setDialogOpen(true);

  const handleAddRows = () => {
    subZoneList.map((subzone: Partial<PrognosisDetailDto>) => {
      addPrognosisDetail({ detail: subzone });
    });
    reset();
  };

  const reset = () => {
    setSubZoneList([]);
    setDialogOpen(false);
  };

  return (
    <Container>
      <TypographyWithMargin variant="body_short">
        Select a stratigraphic column to load zones in
      </TypographyWithMargin>
      <ContentWrapper>
        <SelectColumn />
        <LastUsedZones />
        <OpenDialogButton
          onClick={handleDialogOpen}
          data-testid="open-zone-selector"
        >
          <Icon data={search} />
          Open zone selector
        </OpenDialogButton>
      </ContentWrapper>
      <Dialog
        isDismissable
        actions={[
          {
            onClick: reset,
            text: 'Cancel',
            variant: 'ghost',
          },
          {
            onClick: handleAddRows,
            text: 'Add rows',
            variant: 'outlined',
          },
        ]}
        title={[
          <Typography key="title" variant="h4">
            Zone selector
          </Typography>,
          <Typography key="subtitle" variant="caption">
            Search and/or navigate to the zone(s) you wish to make a pressure
            prognosis for
          </Typography>,
        ]}
        width={960}
        withContentPadding={{ vertical: true, horizontal: true }}
        open={dialogOpen}
        onClose={reset}
        withBorders
      >
        <DialogChildren>
          {stratigraphy.length > 0 ? (
            stratigraphy?.map((column: Stratigraphy) => (
              <Fragment key={column.stratColumnIdentifier}>
                <ColumnName variant="h4">
                  {column.stratColumnIdentifier}
                </ColumnName>
                <ZoneNavigator
                  column={column.stratColumnIdentifier!}
                  data={stratigraphy}
                  handleAddPrognosisDetails={handleAddPrognosisDetails}
                  values={subZoneList}
                />
              </Fragment>
            ))
          ) : (
            <Typography variant="body_short">
              No data found in the stratigraphic column
            </Typography>
          )}
        </DialogChildren>
      </Dialog>
    </Container>
  );
};
