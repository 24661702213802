import { FC, useEffect, useRef, useState } from 'react';

import {
  Button,
  Icon,
  RichTextDisplay,
  Theme,
  Typography,
} from '@equinor/amplify-component-lib';
import { chevron_down, chevron_up } from '@equinor/eds-icons';

import { MoreOptionsMenu } from 'components/index';

import {
  Actions,
  Container,
  Content,
  Header,
} from './MainAssumptionsCard.styles';
import { TextSkeleton } from './TextSkeleton';
import { usePrognosisFromId } from 'src/hooks';
import { useGetBlob } from 'src/pages/EditPrognosis/hooks/useGetBlob';

export const MainAssumptionsCard: FC = () => {
  const { data, isLoading } = usePrognosisFromId();
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [showMore, setShowMore] = useState(false);
  const { insertImages } = useGetBlob();
  const [commentWithImage, setCommentWithImage] = useState<string>('');

  const handleOnToggleReadMore = () => setShowMore((prev) => !prev);

  useEffect(() => {
    const fetchCommentWithImage = async () => {
      const image = await insertImages(data?.comment ?? '');
      setCommentWithImage(image);
    };

    fetchCommentWithImage();
  }, [data?.comment, insertImages]);

  return (
    <Container ref={cardRef}>
      <Header>
        <Typography variant="h4">Main assumptions</Typography>
        <MoreOptionsMenu cardElement={cardRef.current} />
      </Header>
      <Content
        initial={{ height: '200px' }}
        animate={{ height: showMore ? '100%' : '200px' }}
        $showGradient={!showMore}
        data-theme={Theme.LIGHT}
      >
        {isLoading ? (
          <TextSkeleton />
        ) : (
          <RichTextDisplay key={commentWithImage} value={commentWithImage} />
        )}
      </Content>
      <Actions>
        <Button variant="outlined" onClick={handleOnToggleReadMore}>
          Read {showMore ? 'less' : 'more'}
          <Icon data={showMore ? chevron_up : chevron_down} />
        </Button>
      </Actions>
    </Container>
  );
};
