import { useCallback, useEffect, useRef, useState } from 'react';

import {
  FlyToInterpolator,
  MapViewState,
  TRANSITION_EVENTS,
} from '@deck.gl/core';

import { useApp } from 'hooks/useApp';
import { useGetFieldConfig } from 'hooks/useGetFieldConfig';
import { debounce } from 'lodash';

interface MapViewStateArgs {
  debounceMS?: number;
}

export const useMapViewState = (args?: MapViewStateArgs) => {
  const { data } = useGetFieldConfig();
  const { field } = useApp();
  const hasInitialisedViewStateForField = useRef<string | null>(null);
  const [viewState, setViewState] = useState<MapViewState>({
    longitude: 0,
    latitude: 0,
    zoom: 10,
  });

  useEffect(() => {
    if (data && hasInitialisedViewStateForField.current !== field?.uuid) {
      let newViewState: MapViewState = {
        bearing: 0,
        latitude: data.latitude,
        longitude: data.longitude,
        maxZoom: data.zoom + 2.5,
        minZoom: data.zoom - 2.5,
        pitch: 0,
        zoom: data.zoom,
      };
      if (hasInitialisedViewStateForField.current) {
        newViewState = {
          ...newViewState,
          transitionDuration: 'auto',
          transitionInterpolator: new FlyToInterpolator(),
          transitionInterruption: TRANSITION_EVENTS.IGNORE,
        };
      }
      setViewState(newViewState);
      hasInitialisedViewStateForField.current = field?.uuid ?? null;
    }
  }, [data, field?.uuid]);

  const debouncedSetViewState = useCallback(
    () => debounce(setViewState, 300),
    [setViewState]
  );

  if (args?.debounceMS) {
    return [viewState, debouncedSetViewState] as [
      MapViewState,
      typeof debouncedSetViewState,
    ];
  }

  return [viewState, setViewState] as [MapViewState, typeof setViewState];
};
