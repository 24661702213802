import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, useSnackbar } from '@equinor/amplify-component-lib';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';

import { useEditPrognosis } from '../../hooks/useEditPrognosis';
import { ButtonContainer, Container } from './ActionFooter.styles';
import { SavingChip } from './SavingChip';
import { SubmitDialog } from './SubmitDialog';
import { UserService } from 'src/api';
import { FULL_NAME_QUERY } from 'src/constants';
import { EditPrognosisFormValues } from 'src/utils';

import { usePrognosisFromId } from 'hooks/usePrognosisFromId';
import { useSavingState } from 'hooks/useSavingState';

export const ActionFooter: FC = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const isMutating = useIsMutating();
  const { watch, trigger } = useFormContext<EditPrognosisFormValues>();
  const { prognosisId } = useParams();
  const { data } = usePrognosisFromId();
  const queryClient = useQueryClient();
  const isSaving = useSavingState(isMutating);
  const { isInitializing } = useEditPrognosis();

  const { title, prognosisDetails } = watch();

  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);

  const onCreatePage = prognosisId === undefined;

  const handleOnCloseSubmitDialog = () => setOpenSubmitDialog(false);

  const handleOnOpenSubmitDialog = async () => {
    const isValid = await trigger();

    if (prognosisDetails.length <= 0) {
      showSnackbar('Please fill in the prognosis data.');
    } else if (isValid) {
      setOpenSubmitDialog(true);
      queryClient.prefetchQuery({
        queryKey: [FULL_NAME_QUERY, data?.createUser],
        queryFn: () => UserService.getApiV1UserFullname(data?.createUser ?? ''),
      });
    }
  };

  const handleOnCancel = () => navigate(-1);

  const handlePreviewPrognosis = () => {
    navigate(`/preview/${prognosisId}`);
  };

  return (
    <>
      <Container>
        {onCreatePage ? (
          <Button variant="outlined" onClick={handleOnCancel}>
            Cancel and discard
          </Button>
        ) : (
          <Button variant="ghost" onClick={() => navigate('/dashboard')}>
            Return to dashboard
          </Button>
        )}
        <ButtonContainer>
          {!onCreatePage && !isInitializing && (
            <SavingChip isSaving={isSaving} />
          )}
          <Button variant="outlined" onClick={handlePreviewPrognosis}>
            Preview prognosis
          </Button>
          <Button onClick={handleOnOpenSubmitDialog}>
            Submit for approval
          </Button>
        </ButtonContainer>
      </Container>
      <SubmitDialog
        open={openSubmitDialog}
        title={title}
        onClose={handleOnCloseSubmitDialog}
      />
    </>
  );
};
