import { FC } from 'react';

import { ActionFooter } from './components/ActionFooter/ActionFooter';
import { Breadcrumbs } from './components/Breadcrumbs/Breadcrumbs';
import { FormData } from './components/FormData/FormData';
import { FormHeader } from './components/FormHeader/FormHeader';
import { OverlappingNotification } from './components/OverlappingNotification/OverlappingNotification';
import { Title } from './components/Title/Title';
import { EditPrognosisProvider } from './providers/EditPrognosisProvider';
import { Container, MainContent } from './EditPrognosis.styles';

export const EditPrognosis: FC = () => {
  return (
    <EditPrognosisProvider>
      <Container>
        <MainContent>
          <Breadcrumbs />
          <OverlappingNotification />
          <Title />
          <FormHeader />
          <FormData />
        </MainContent>
        <ActionFooter />
      </Container>
    </EditPrognosisProvider>
  );
};
