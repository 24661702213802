import { FC, useState } from 'react';

import { colors, Icon } from '@equinor/amplify-component-lib';
import { IconData } from '@equinor/eds-icons';

import { Container, StyledTypography } from './BaseChip.styles';

import { motion } from 'framer-motion';

export interface BaseChipProps {
  label?: string;
  textColor?: string;
  backgroundColor?: string;
  icon?: IconData;
  hover?: boolean;
  iconColor?: string;
  borderColor?: string;
  disabled?: boolean;
  asButton?: boolean;
  onClick?: () => void;
  expandible?: boolean;
}

export const BaseChip: FC<BaseChipProps> = ({
  label,
  backgroundColor = colors.ui.background__light.rgba,
  icon,
  textColor = colors.interactive.primary__resting.rgba,
  hover = false,
  iconColor,
  borderColor = colors.ui.background__medium.rgba,
  disabled,
  asButton,
  onClick,
  expandible: expandable,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const showText = expandable ? (isHovered ? true : false) : true;

  return (
    <Container
      as={motion[asButton ? 'button' : 'div']}
      $backgroundColor={backgroundColor}
      $textColor={textColor}
      $hoverEnabled={hover}
      $borderColor={borderColor}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      key={`container-${isHovered}`}
      initial={expandable ? { width: '26px' } : { width: 'fit-content' }}
      animate={{ width: 'fit-content' }}
      transition={{ duration: 0.2 }}
    >
      {icon && (
        <Icon
          data={icon}
          size={16}
          color={
            isHovered && !expandable
              ? colors.interactive.primary__hover.rgba
              : (iconColor ?? textColor)
          }
          style={{ flexShrink: 0 }} // prevents icon from shrinking
        />
      )}
      {showText && (
        <StyledTypography group="ui" variant="chip__badge" color="inherit">
          {label}
        </StyledTypography>
      )}
    </Container>
  );
};
