import { FC } from 'react';

import {
  Button,
  exportComponent,
  Icon,
  useSnackbar,
} from '@equinor/amplify-component-lib';
import { download } from '@equinor/eds-icons';

import { MoreOptionsMenu } from 'components/index';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

interface ActionsProps {
  cardElement: HTMLDivElement | null;
  showToggle?: boolean;
}

export const Actions: FC<ActionsProps> = ({ cardElement, showToggle }) => {
  const { showSnackbar } = useSnackbar();

  const handleOnDownload = async () => {
    if (!cardElement) return;

    // Temporary remove box-shadow because it breaks export image
    cardElement.setAttribute('style', 'box-shadow: none');

    const blob = await exportComponent({ current: cardElement });

    cardElement.removeAttribute('style');

    const suggestedName = 'prognosis.png';

    const fileName = suggestedName;
    let handle: FileSystemFileHandle | undefined;
    const file = new File([blob], fileName);

    if (handle) {
      const writable = await handle.createWritable();
      await writable.write(file);
      await writable.close();
    } else {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(file);
      link.download = suggestedName;
      link.click();
      setTimeout(() => URL.revokeObjectURL(link.href), 1000);
    }

    showSnackbar('Downloaded chart');
  };

  return (
    <Container>
      <Button
        variant="ghost_icon"
        onClick={handleOnDownload}
        aria-label="Download"
      >
        <Icon data={download} />
      </Button>
      <MoreOptionsMenu cardElement={cardElement} showToggle={showToggle} />
    </Container>
  );
};
