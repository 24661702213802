/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PrognosisDetailDto } from '../models/PrognosisDetailDto';
import type { PrognosisDto } from '../models/PrognosisDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LastSubmittedPrognosisService {
  /**
   * Get last submitted prognosis
   * @param prognosisId
   * @returns PrognosisDto OK
   * @throws ApiError
   */
  public static getApiV1PrognosesLastSubmitted(
    prognosisId: number
  ): CancelablePromise<PrognosisDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/{prognosisId}/LastSubmitted',
      path: {
        prognosisId: prognosisId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get last submitted prognosis details
   * @param prognosisId
   * @returns PrognosisDetailDto OK
   * @throws ApiError
   */
  public static getApiV1PrognosesDetailsLastSubmitted(
    prognosisId: number
  ): CancelablePromise<Array<PrognosisDetailDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/{prognosisId}/Details/LastSubmitted',
      path: {
        prognosisId: prognosisId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
}
