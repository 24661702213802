import { shape, spacings, Typography } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding-right: ${spacings.small};
  justify-content: flex-end;
  max-width: 100%;
  min-width: 60px;
`;

interface TypographyContainerProps {
  $backgroundColor?: string;
}

export const TypographyContainer = styled.div<TypographyContainerProps>`
  display: flex;
  border-radius: ${shape.corners.borderRadius};
  padding: 0 ${spacings.x_small};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  text-align: right;
`;
