import { FC } from 'react';

import {
  Icon,
  OptionalTooltip,
  shape,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { info_circle } from '@equinor/eds-icons';

import { getCellColor } from 'src/utils';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: ${spacings.small};
  height: ${spacings.large};
`;

interface TypographyContainerProps {
  $backgroundColor?: string;
}

export const TypographyContainer = styled.div<TypographyContainerProps>`
  display: flex;
  border-radius: ${shape.corners.borderRadius};
  padding: 0 ${spacings.x_small};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  text-align: right;
`;

interface InfoWrapperProps {
  hasErrors: boolean;
  hasUpdatedCell: boolean;
}
export const InfoWrapper: FC<InfoWrapperProps> = ({
  hasErrors,
  hasUpdatedCell,
}) => {
  const invalidColor = getCellColor('invalid');
  const updatedColor = getCellColor('updated');

  return (
    <Container>
      {hasErrors && (
        <>
          <Icon data={info_circle} />
          <OptionalTooltip title="These cells contain validation errors.">
            <TypographyContainer
              data-testid="invalid-cell"
              $backgroundColor={invalidColor.backgroundColor}
            >
              <StyledTypography
                variant="cell_text"
                group="table"
                color={invalidColor.textColor}
              >
                Invalid for submission
              </StyledTypography>
            </TypographyContainer>
          </OptionalTooltip>
        </>
      )}
      {hasUpdatedCell && (
        <>
          <Icon data={info_circle} />
          <OptionalTooltip title="These cells have been updated recently.">
            <TypographyContainer
              data-testid="updated-cell"
              $backgroundColor={updatedColor.backgroundColor}
            >
              <StyledTypography
                variant="cell_text"
                group="table"
                color={updatedColor.textColor}
              >
                Updated
              </StyledTypography>
            </TypographyContainer>
          </OptionalTooltip>
        </>
      )}
      {/* TODO: Add more chips */}
    </Container>
  );
};
