import { useFormContext } from 'react-hook-form';

import { useQuery } from '@tanstack/react-query';

import { useApp } from './useApp';
import { SmdaService, Wellbore } from 'src/api';
import {
  WELLBORE_ALL_KEY,
  WELLBORE_BY_IDENTIFIER_KEY,
  WELLBORE_DRILLED_KEY,
  WELLBORE_PLANNED_KEY,
} from 'src/constants/queryKeys';
import { EditPrognosisFormValues } from 'src/utils';

export const useGetWellboreByIdentifier = () => {
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { wellbore } = getValues();

  const cleanStringForUrl = (str: string): string => {
    return encodeURIComponent(str);
  };

  const identifier = cleanStringForUrl(wellbore?.label) ?? '';

  const query = useQuery({
    queryKey: [identifier, WELLBORE_BY_IDENTIFIER_KEY],
    queryFn: () => SmdaService.getApiV1SmdaWellbores(identifier),
    enabled: !!wellbore?.label,
  });

  return { ...query };
};

export const useGetAllWellbores = () => {
  const { field } = useApp();
  return useQuery<Wellbore[]>({
    queryKey: [WELLBORE_ALL_KEY, field?.uuid],
    queryFn: () =>
      SmdaService.getApiV1SmdaFieldsWellboresAll(field?.uuid ?? ''),
  });
};

export const useGetDrilledWellbores = () => {
  const { field } = useApp();
  return useQuery<Wellbore[]>({
    queryKey: [WELLBORE_DRILLED_KEY, field?.uuid],
    queryFn: () =>
      SmdaService.getApiV1SmdaFieldsWellboresDrilled(field?.uuid ?? ''),
  });
};

export const useGetPlannedWellbores = () => {
  const { field } = useApp();
  return useQuery<Wellbore[]>({
    queryKey: [WELLBORE_PLANNED_KEY, field?.uuid],
    queryFn: () =>
      SmdaService.getApiV1SmdaFieldsWellboresPlanned(field?.uuid ?? ''),
  });
};
