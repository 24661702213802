import { FC, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  SelectOptionRequired,
  SingleSelect,
} from '@equinor/amplify-component-lib';

import { ConfirmDialog } from '../../../ConfimDialog/ConfirmDialog';
import { useDeleteDetail } from 'src/pages/EditPrognosis/hooks/useDeleteDetail';
import { usePutDraftPrognosis } from 'src/pages/EditPrognosis/hooks/usePutDraftPrognosis';
import { NextValueProps } from 'src/pages/EditPrognosis/utils/formUtils';
import { EditPrognosisFormValues } from 'src/utils';

import { useGetStratColumns } from 'hooks/useGetStratigraphicValues';
import styled from 'styled-components';

const ComboboxContainer = styled.div`
  width: 100%;
  max-width: 320px;
  align-self: flex-start;
`;

export const SelectColumn: FC = () => {
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useFormContext<EditPrognosisFormValues>();
  const { mutate: updateDraftPrognosis } = usePutDraftPrognosis();
  const [nextValue, setNextValue] = useState<NextValueProps>();
  const [open, setOpen] = useState(false);
  const { prognosisDetails } = watch();
  const { mutate: deletePrognosisDetail } = useDeleteDetail();

  const { data: stratColumn, isLoading } = useGetStratColumns();

  const handleUpdateStratColumn = ({ key, item }: NextValueProps) => {
    const previousValue: SelectOptionRequired | undefined = getValues(
      'stratColumnIdentifier'
    );
    const isFieldDirty = item?.value !== previousValue?.value;

    if (isFieldDirty) {
      if (prognosisDetails?.length !== 0) {
        setOpen(true);
        setNextValue({ key, item });
        return;
      }

      if (!item) return;
      setValue(key, item);

      updateDraftPrognosis();
    }
  };

  const handleConfirm = () => {
    setOpen(false);

    if (!nextValue) return;

    prognosisDetails.map((detail) => {
      try {
        deletePrognosisDetail(detail.id);
      } catch (error) {
        console.error(error);
        return;
      }
    });

    const { key, item } = nextValue;

    if (!item) return;
    setValue(key, item);

    updateDraftPrognosis();
  };

  const identifierItems: SelectOptionRequired[] = useMemo(() => {
    return (
      stratColumn?.map(({ identifier }) => ({
        value: identifier ?? '',
        label: identifier ?? '',
      })) ?? []
    );
  }, [stratColumn]);

  return (
    <ComboboxContainer>
      <Controller
        name={'stratColumnIdentifier'}
        control={control}
        rules={{ required: 'Wellbore is required' }}
        render={({ field }) => {
          return (
            <SingleSelect
              items={identifierItems}
              placeholder="Select one..."
              value={field.value}
              loading={isLoading}
              onSelect={(item) => {
                handleUpdateStratColumn({
                  key: 'stratColumnIdentifier',
                  item,
                });
              }}
              variant={errors.wellbore ? 'error' : undefined}
              clearable={false}
            />
          );
        }}
      />
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => handleConfirm()}
      />
    </ComboboxContainer>
  );
};
