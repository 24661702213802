import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import { useInitializeEditProgram } from '../hooks/useInitializeEditProgram';
import { useOptionalAttributes } from '../hooks/useOptionalAttributes';

export interface EditPrognosisContextType {
  isInitializing: boolean;
  imageFiles: File[];
  selectedRows: number[]; // IDs of selected rows
  setSelectedRows: (rows: number[]) => void;
  addImageFile: (file: File) => void;
  hasUpdatedCell: boolean;
  setHasUpdatedCell: Dispatch<SetStateAction<boolean>>;
}

export const EditPrognosisContext = createContext<
  EditPrognosisContextType | undefined
>(undefined);

interface EditPrognosisProviderProps {
  children: ReactNode;
}

export const EditPrognosisProvider: FC<EditPrognosisProviderProps> = ({
  children,
}) => {
  const { setOptionalAttributes } = useOptionalAttributes();
  const { isInitializing } = useInitializeEditProgram({
    setOptionalAttributes,
  });

  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [hasUpdatedCell, setHasUpdatedCell] = useState(false);

  const handleAddImageFile = (file: File) => {
    const newFiles = [...imageFiles, file];
    setImageFiles(newFiles);
  };

  return (
    <EditPrognosisContext.Provider
      value={{
        isInitializing,
        imageFiles,
        selectedRows,
        setSelectedRows,
        addImageFile: handleAddImageFile,
        hasUpdatedCell,
        setHasUpdatedCell,
      }}
    >
      {children}
    </EditPrognosisContext.Provider>
  );
};
