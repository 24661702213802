import { FC, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { FilterSidebar } from './components/FilterSidebar/FilterSidebar';
import { Header } from './components/Header/Header';
import { DashboardProvider } from './providers/DashboardProvider';
import { Container, MainContent } from './Dashboard.styles';
import {
  ConfigService,
  OperationPhaseService,
  PrognosisOperationService,
  SmdaService,
  UserService,
} from 'src/api';
import {
  INTERVENTION_PHASE,
  MEASURED_DATA_ACCESS_CHECK_KEY,
  NEW_WELL_PHASE,
  PROGNOSIS_TYPES_KEY,
  USER_KEY,
  WELLBORE_ALL_KEY,
} from 'src/constants';
import { ApplicationTabs } from 'src/pages/Dashboard/components/ApplicationTabs/ApplicationTabs';

import { useApp } from 'hooks/useApp';

export const Dashboard: FC = () => {
  const queryClient = useQueryClient();
  const { field } = useApp();

  const [showFilterSidebar, setShowFilterSidebar] = useState(false);

  // prefetch data that can be used in any prognosis
  useEffect(() => {
    // prefetch wellbores
    queryClient.prefetchQuery({
      queryKey: [WELLBORE_ALL_KEY, field?.uuid],
      queryFn: () =>
        SmdaService.getApiV1SmdaFieldsWellboresAll(field?.uuid ?? ''),
      staleTime: 60000,
    });

    // prefetch approvers
    queryClient.prefetchQuery({
      queryKey: [USER_KEY, field?.name],
      queryFn: () => UserService.getApiV1UserApprovers(field?.name ?? ''),
      staleTime: 60000,
    });

    // prefetch operations
    queryClient.prefetchQuery({
      queryKey: [PROGNOSIS_TYPES_KEY],
      queryFn: () => PrognosisOperationService.getApiV1PrognosesOperations(),
      staleTime: 60000,
    });

    // prefetch new well operation subtypes
    queryClient.prefetchQuery({
      queryKey: [NEW_WELL_PHASE],
      queryFn: () =>
        OperationPhaseService.getApiV1PrognosesOperationPhasesNewWell(),
      staleTime: 60000,
    });

    // prefetch intervention operation subtypes
    queryClient.prefetchQuery({
      queryKey: [INTERVENTION_PHASE],
      queryFn: () =>
        OperationPhaseService.getApiV1PrognosesOperationPhasesIntervention(),
      staleTime: 60000,
    });

    // TODO: Look into a more generalized place we can prefetch something more general like this. For now Dashboard is a decent place, since it covers most of the cases.
    // prefetch access check for measured data pages, for more seamless navigation without extra FullPageSpinner
    queryClient.prefetchQuery({
      queryKey: [MEASURED_DATA_ACCESS_CHECK_KEY, field?.uuid],
      queryFn: () => ConfigService.accessCheck(field?.uuid ?? ''),
      staleTime: 60000,
    });
  }, [field?.name, field?.uuid, queryClient]);

  const handleOnToggleFilterSidebar = () =>
    setShowFilterSidebar((prev) => !prev);

  return (
    <DashboardProvider>
      <Container>
        <div>
          <FilterSidebar open={showFilterSidebar} />
        </div>
        <MainContent>
          <Header />
          <ApplicationTabs
            handleShowFilterSidebar={handleOnToggleFilterSidebar}
            showFilterSidebar={showFilterSidebar}
          />
        </MainContent>
      </Container>
    </DashboardProvider>
  );
};
