import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

export interface FilterValue {
  search?: string | null;
  status?: string | null;
  operation?: string | null;
  wellPlanningPhase?: string | null;
  wellbore?: string | null;
  reservoirZone?: string | null;
  createdBy?: string | null;
  approver?: string | null;
  mainApprover?: string | null;
  backupApprover?: string | null;
  lastModifiedFrom?: string | null;
  lastModifiedTo?: string | null;
}

const FILTER_VALUE_KEYS: (keyof FilterValue)[] = [
  'search',
  'status',
  'operation',
  'wellPlanningPhase',
  'wellbore',
  'reservoirZone',
  'createdBy',
  'approver',
  'lastModifiedFrom',
  'lastModifiedTo',
];

interface DashboardProviderProps {
  filterValue: FilterValue;
  updateFilterValue: (value: Partial<FilterValue>) => void;
  clearFilterValue: () => void;
  activeTab: number;
  handleTabChange: (tabIndex: number) => void;
}

export const DashboardContext = createContext<
  DashboardProviderProps | undefined
>(undefined);

interface CreatePrognosisProviderProps {
  children: ReactNode;
}

const filterValueStructure: FilterValue = {
  search: null,
  status: null,
  operation: null,
  wellPlanningPhase: null,
  wellbore: null,
  reservoirZone: null,
  createdBy: null,
  approver: null,
  lastModifiedFrom: null,
  lastModifiedTo: null,
  mainApprover: null,
  backupApprover: null,
};

export const DashboardProvider: FC<CreatePrognosisProviderProps> = ({
  children,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterValue, setFilterValue] =
    useState<FilterValue>(filterValueStructure);
  const [activeTab, setActiveTab] = useState<number>(0);
  const initializedFilterValues = useRef<boolean>(false);

  useEffect(() => {
    if (!initializedFilterValues.current) {
      initializedFilterValues.current = true;
      const newFilterValues: FilterValue = { ...filterValueStructure };
      for (const key of FILTER_VALUE_KEYS) {
        newFilterValues[key] = searchParams.get(key);
      }
      setFilterValue(newFilterValues);
    }

    const currentTabIndex = searchParams.get('tab');
    setActiveTab(Number(currentTabIndex) ?? 0);
  }, [searchParams]);

  const handleOnUpdateFilterValue = (value: Partial<FilterValue>) => {
    const keys = Object.keys(value) as (keyof FilterValue)[];
    for (const key of keys) {
      const keyValue = value[key];
      if (keyValue) {
        searchParams.set(key, keyValue);
      } else {
        searchParams.delete(key);
      }
    }
    setSearchParams(searchParams);
    setFilterValue((prev) => ({ ...prev, ...value }));
  };

  const handleOnClearFilterValue = () => {
    setSearchParams({});
    setFilterValue(filterValueStructure);
  };

  const handleTabChange = (index: number) => {
    searchParams.set('tab', index.toString());
    setSearchParams(searchParams);

    const currentTabIndex = searchParams.get('tab');
    if (filterValue.status) {
      setFilterValue(
        (prev) =>
          ({
            ...prev,
            status: null,
          }) as FilterValue
      );
    }
    setActiveTab(Number(currentTabIndex) ?? index);
  };

  return (
    <DashboardContext.Provider
      value={{
        filterValue,
        updateFilterValue: handleOnUpdateFilterValue,
        clearFilterValue: handleOnClearFilterValue,
        activeTab,
        handleTabChange,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
