import { FC } from 'react';

import { colors, Icon, OptionalTooltip } from '@equinor/amplify-component-lib';
import { arrow_drop_right } from '@equinor/eds-icons';

import {
  ColorCircle,
  Container,
  Row,
  StyledTypography,
  TextContainer,
} from 'components/Table/ZoneCell/ZoneCell.styles';

interface ZoneCellProps {
  zoneName: string;
  segment?: string;
  nickname?: string | null;
  color?: string | null;
  isSelected?: boolean;
}

export const ZoneCell: FC<ZoneCellProps> = ({
  zoneName,
  nickname,
  color,
  isSelected = false,
}) => {
  return (
    <Container>
      <Row>
        {color && <ColorCircle color={color} $isSelected={isSelected} />}
        <TextContainer>
          <OptionalTooltip title={zoneName} placement="top" enterDelay={1000}>
            <StyledTypography
              variant="cell_header"
              data-testid="zone-cell"
              group="table"
              color={colors.interactive.primary__resting.rgba}
            >
              {zoneName}
            </StyledTypography>
          </OptionalTooltip>
        </TextContainer>
      </Row>
      {nickname && (
        <Row>
          <Icon
            data={arrow_drop_right}
            size={16}
            color={colors.text.static_icons__tertiary.rgba}
          />
          <TextContainer>
            <StyledTypography
              group="navigation"
              variant="label"
              color={colors.text.static_icons__tertiary.rgba}
            >
              {nickname}
            </StyledTypography>
          </TextContainer>
        </Row>
      )}
    </Container>
  );
};
