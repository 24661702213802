import { useQuery } from '@tanstack/react-query';

import { ConfigService } from '../api';
import { useApp } from './useApp';
import { GET_FIELD_CONFIG_KEY } from 'src/constants';

export function useGetFieldConfig() {
  const { field } = useApp();
  return useQuery({
    queryKey: [GET_FIELD_CONFIG_KEY, field?.uuid],
    queryFn: () => ConfigService.getFieldConfig(field?.uuid ?? ''),
    gcTime: 1000 * 60 * 60 * 12, // 12 hours
    staleTime: 1000 * 60 * 60 * 12,
    enabled: !!field?.uuid,
  });
}
