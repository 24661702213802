import { FC, useMemo, useState } from 'react';

import { Theme } from '@equinor/amplify-component-lib';
import { ParentSize } from '@visx/responsive';

import { ChartHeader } from 'components/Charts/XYChart/Header/ChartHeader';
import {
  ChartDataCategory,
  ChartSelect,
} from 'components/Charts/XYChart/Utils/Chart.types';

import { Card, ChartContainer } from '../Chart.styles';
import { Chart } from './Chart';
import { PrognosisDetailDto } from 'src/api';
import { CHART_HEIGHT } from 'src/pages/DetailedView/constants/chart';
import { Unit } from 'src/types/prognosisDetails';
import { ConvertPrognosisToSG } from 'src/utils/unitConversion';

import { useApp } from 'hooks/useApp';
import { useGetWellboreByIdentifier } from 'hooks/useGetWellbores';

interface PrognosisChartProps {
  prognosisDetailData: PrognosisDetailDto[];
}

export const PrognosisChart: FC<PrognosisChartProps> = ({
  prognosisDetailData,
}) => {
  const [cardElement, setCardElement] = useState<HTMLDivElement | null>(null);
  const [selectedData, setSelectedData] = useState<ChartSelect>({
    id: ChartDataCategory.REFERENCE_ID as number,
    name: ChartDataCategory.REFERENCE as string,
  });
  const { data: wellbore } = useGetWellboreByIdentifier();
  const { unit } = useApp();

  const dataCopy = useMemo(() => {
    return prognosisDetailData?.map((prognosis) => {
      if (unit === Unit.SG && wellbore) {
        return ConvertPrognosisToSG({
          prognosis,
          depthReferenceElevation: wellbore.depthReferenceElevation || 0,
        });
      }
      return prognosis;
    });
  }, [unit, prognosisDetailData, wellbore]);

  const elements = [
    {
      id: ChartDataCategory.REFERENCE_ID as number,
      name: ChartDataCategory.REFERENCE as string,
    },
    {
      id: ChartDataCategory.DATUM_ID as number,
      name: ChartDataCategory.DATUM as string,
    },
  ];

  return (
    <Card ref={setCardElement}>
      <ChartHeader
        cardElement={cardElement}
        selected={selectedData}
        setSelected={setSelectedData}
        elements={elements}
        title="Overview"
        showToggle
      />
      <ChartContainer data-theme={Theme.LIGHT}>
        <ParentSize ignoreDimensions="height">
          {({ width }) => (
            <Chart data={dataCopy} width={width} height={CHART_HEIGHT} />
          )}
        </ParentSize>
      </ChartContainer>
    </Card>
  );
};
