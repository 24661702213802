import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Container,
  StyledTypography,
  TypographyContainer,
} from './DetailsCell.styles';
import { PrognosisDetailDto } from 'src/api';
import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';
import { EditPrognosisFormValues, findPreviousDetail } from 'src/utils';
import { getCellColor } from 'src/utils/color';
import { getPrognosisDetailError } from 'src/utils/error';

import { useGetPreviousPrognosisDetails } from 'hooks/useGetPreviousPrognosisDetails';

interface DetailsCellProps {
  detailValue?: string;
  detailKey: string;
  index: number;
  prognosisId: number;
  isStatusSubmitted: boolean;
}

export const DetailsCell: FC<DetailsCellProps> = ({
  detailValue,
  detailKey,
  index,
  prognosisId,
  isStatusSubmitted,
}) => {
  const {
    formState: { errors },
  } = useFormContext<EditPrognosisFormValues>();
  const showPlaceholder = detailValue == undefined || detailValue == '';
  const { data: previousData } = useGetPreviousPrognosisDetails();
  const { setHasUpdatedCell } = useEditPrognosis();

  const [isUpdated, setIsUpdated] = useState(false);

  const previous = useMemo(() => {
    const previousDetail = findPreviousDetail(
      detailKey as keyof PrognosisDetailDto,
      prognosisId ?? 0,
      previousData ?? []
    );

    return previousDetail;
  }, [detailKey, previousData, prognosisId]);

  // converts the result into a boolean
  const hasError = !!getPrognosisDetailError(
    errors,
    index,
    detailKey as keyof PrognosisDetailDto
  );

  useEffect(() => {
    const updated = !!(
      previous &&
      previous.toString() !== detailValue?.toString() &&
      isStatusSubmitted
    );
    setIsUpdated(updated);
    if (updated) {
      setHasUpdatedCell(true);
    }
  }, [previous, detailValue, setHasUpdatedCell, isStatusSubmitted]);

  let textColor, backgroundColor;

  if (hasError) {
    ({ textColor, backgroundColor } = getCellColor('invalid'));
  } else if (isUpdated) {
    ({ textColor, backgroundColor } = getCellColor('updated'));
  } else if (showPlaceholder) {
    ({ textColor, backgroundColor } = getCellColor('placeholder'));
  } else {
    ({ textColor, backgroundColor } = getCellColor());
  }

  return (
    <Container>
      <TypographyContainer $backgroundColor={backgroundColor}>
        <StyledTypography variant="cell_text" group="table" color={textColor}>
          {showPlaceholder ? '0.0' : detailValue}
        </StyledTypography>
      </TypographyContainer>
    </Container>
  );
};
