import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useOptionalAttributes } from './useOptionalAttributes';
import { PrognosisDetailDto, PrognosisDetailService } from 'src/api';
import { PROGNOSIS_DETAIL } from 'src/constants';
import { cleanPrognosisDetail, EditPrognosisFormValues } from 'src/utils';

export const usePutDetail = () => {
  const { prognosisId } = useParams();
  const { optionalAttributes } = useOptionalAttributes();
  const { setValue, resetField } = useFormContext<EditPrognosisFormValues>();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const usingId = Number(prognosisId);

  return useMutation({
    mutationKey: [PROGNOSIS_DETAIL, prognosisId],
    mutationFn: async (detail: Partial<PrognosisDetailDto>) => {
      const cleanedDetails = cleanPrognosisDetail(detail, optionalAttributes);
      return await PrognosisDetailService.putApiV1PrognosesDetails(
        usingId,
        cleanedDetails
      );
    },
    onMutate: async (detail: Partial<PrognosisDetailDto>) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_DETAIL, prognosisId],
      });

      // Snapshot the previous value
      const previousDetails: PrognosisDetailDto[] =
        queryClient.getQueryData([PROGNOSIS_DETAIL, prognosisId]) ?? [];

      const cleanedDetails = cleanPrognosisDetail(detail, optionalAttributes);

      // Find the index of the detail to update
      const index = previousDetails.findIndex(
        (d) => d.id === cleanedDetails.id
      );

      // Create a new array with the updated detail
      const updatedDetails = [...previousDetails];
      if (index !== -1) {
        updatedDetails[index] = cleanedDetails;
      }

      // Optimistically update to the new value
      queryClient.setQueryData([PROGNOSIS_DETAIL, prognosisId], updatedDetails);

      // Add the new detail to the form
      setValue('prognosisDetails', updatedDetails);

      // Return a context object with the snapshotted value
      return {
        previousDetails,
        optimisticDetail: cleanedDetails,
        updatedDetails,
      };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_DETAIL, prognosisId],
        context?.previousDetails ?? []
      );
      // Reset the form with the previous details
      resetField('prognosisDetails', {
        defaultValue: context?.previousDetails,
      });
      showSnackbar(`Failed to update detail. ` + err.message);
    },
    onSuccess: (err, values, context) => {
      // Reset the form with the new details
      resetField('prognosisDetails', {
        defaultValue: context?.updatedDetails,
        keepError: true,
      });
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_DETAIL, prognosisId],
      });
    },
  });
};
