import { Card, spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import { motion } from 'framer-motion';
import { css, styled } from 'styled-components';

const { colors, elevation, shape } = tokens;

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${spacings.large};
  box-shadow: ${elevation.raised};
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

interface ContentProps {
  $showGradient: boolean;
}

export const Content = styled(motion.section)<ContentProps>`
  border: 1px solid ${colors.ui.background__medium.rgba};
  border-radius: ${shape.corners.borderRadius};
  background-color: ${colors.ui.background__default.rgba};
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    transition: background 200ms;
    background: ${({ $showGradient }) =>
      $showGradient
        ? css`linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 98%
      )`
        : 'transparent'};
    position: absolute;
    height: 40px;
    bottom: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  > button {
    width: 9rem;
  }
`;
