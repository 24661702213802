import { FC } from 'react';

import { MapMarker } from 'src/pages/MapView/components/MapElements/MapMarker/MapMarker';
import { MapMarkersForArea } from 'src/pages/MapView/components/MapElements/MapMarkersForArea/MapMarkersForArea';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { useGetFieldConfigForMap } from 'src/pages/MapView/hooks/useGetFieldConfigForMap';

export const MapElements: FC = () => {
  const { viewState, filteredPressurePoints } = useMapOptions();
  const { groupingThreshold } = useGetFieldConfigForMap();
  if (!groupingThreshold || viewState.zoom < groupingThreshold) {
    return <MapMarkersForArea />;
  }
  return filteredPressurePoints.map((pressurePoint) => {
    return (
      <MapMarker
        key={`${pressurePoint.wellboreUuid}//${pressurePoint.dhpg}`}
        {...pressurePoint}
      />
    );
  });
};
