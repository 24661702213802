import { useFormContext } from 'react-hook-form';

import { useQuery } from '@tanstack/react-query';

import { SmdaService } from 'src/api';
import {
  STRAT_COLUMN_KEY,
  STRATIGRAPHIC_UNIT_ALL_KEY,
  STRATIGRAPHIC_UNIT_DRILLED_KEY,
  STRATIGRAPHIC_UNIT_PLANNED_KEY,
} from 'src/constants/queryKeys';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

export const useGetStratColumns = () => {
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { wellbore } = getValues();

  const query = useQuery({
    queryKey: [STRAT_COLUMN_KEY, wellbore?.value],
    queryFn: () =>
      SmdaService.getApiV1SmdaWellboresStratColumns(wellbore.value ?? ''),
    enabled: !!wellbore?.value,
  });

  return { ...query };
};

export const useGetStratigraphicValues = () => {
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { stratColumnIdentifier } = getValues();

  const query = useQuery({
    queryKey: [stratColumnIdentifier, STRATIGRAPHIC_UNIT_ALL_KEY],
    queryFn: () =>
      SmdaService.getApiV1SmdaWellboresStratColumnsStratUnitsAll(
        stratColumnIdentifier?.value ?? ''
      ),
  });

  return { ...query };
};

export const useGetStratigraphicValuesPlanned = () => {
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { wellbore, stratColumnIdentifier } = getValues();

  const query = useQuery({
    queryKey: [stratColumnIdentifier, STRATIGRAPHIC_UNIT_PLANNED_KEY],
    queryFn: () =>
      SmdaService.getApiV1SmdaWellboresStratColumnsStratUnitsPlanned(
        wellbore?.value ?? '',
        stratColumnIdentifier?.value ?? ''
      ),
  });

  return { ...query };
};

export const useGetStratigraphicValuesDrilled = () => {
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { wellbore, stratColumnIdentifier } = getValues();

  const query = useQuery({
    queryKey: [stratColumnIdentifier, STRATIGRAPHIC_UNIT_DRILLED_KEY],
    queryFn: () =>
      SmdaService.getApiV1SmdaWellboresStratColumnsStratUnitsDrilled(
        wellbore?.value ?? '',
        stratColumnIdentifier?.value ?? ''
      ),
  });

  return { ...query };
};
