export const TURF_INTERPOLATE_WEIGHT = 3;
export const TURF_CELL_SIZE_MIN = 20;
export const TURF_CELL_SIZE_MAX = 80;
export const MAP_ZOOM_MIN = 10;
export const MAP_ZOOM_MAX = 13;
export const WEIGHTS_TEXTURE_SIZE = 512;
export const HEATMAP_LAYER_RADIUS_PIXELS = 100;
export const HEATMAP_GRID_CORNER_COORDS_OFFSET = 0.1;
export const MAP_ZOOM_MIN_OFFSET = 0.7;
export const MAP_ZOOM_MAX_OFFSET = 3.5;
export const MAP_RECENTER_OFFSET_LAT = 0.5;
export const MAP_RECENTER_OFFSET_LNG = 0.7;

export const FABIO_BATLOW_W_HEATMAP_COLOR_DOMAIN: [number, number, number][] = [
  [255, 254, 254],
  [255, 225, 223],
  [247, 186, 171],
  [227, 169, 122],
  [195, 159, 73],
  [148, 143, 50],
  [105, 128, 64],
  [68, 115, 83],
  [37, 99, 96],
  [21, 79, 98],
  [14, 57, 95],
  [1, 25, 89],
] as const;

// Keeping this for now, in case we go back - 13.12.24
// Keeping all RODRIGO_COLOR_DOMAINS in case we want to swap, but we like RADIOACTIVE_NICE at the moment 16.12.24
export const RODRIGO_HEATMAP_COLOR_DOMAIN_MOSS: [number, number, number][] = [
  [255, 255, 255],
  [205, 255, 231],
  [190, 243, 218],
  [163, 226, 197],
  [139, 205, 174],
  [117, 184, 152],
  [92, 157, 126],
  [73, 131, 111],
  [37, 99, 96],
  [21, 79, 98],
  [14, 57, 95],
  [1, 26, 90],
];

export const RODRIGO_HEATMAP_COLOR_DOMAIN_PURPLE: [number, number, number][] = [
  [255, 251, 232],
  [255, 243, 188],
  [253, 231, 146],
  [238, 201, 140],
  [219, 163, 133],
  [192, 133, 150],
  [155, 98, 180],
  [123, 70, 197],
  [98, 53, 189],
  [67, 33, 180],
  [41, 24, 155],
  [15, 16, 129],
];

export const RODRIGO_HEATMAP_COLOR_DOMAIN_RADIOACTIVE: [
  number,
  number,
  number,
][] = [
  [249, 253, 217],
  [234, 248, 127],
  [219, 244, 45],
  [153, 228, 87],
  [95, 213, 124],
  [61, 184, 155],
  [47, 147, 178],
  [35, 116, 195],
  [29, 90, 180],
  [23, 65, 165],
  [16, 53, 147],
  [5, 37, 120],
];

export const RODRIGO_HEATMAP_COLOR_DOMAIN_RADIOACTIVE_NICE: [
  number,
  number,
  number,
][] = [
  [248, 250, 234],
  [234, 241, 185],
  [216, 230, 137],
  [177, 212, 146],
  [133, 192, 153],
  [110, 167, 155],
  [93, 139, 155],
  [77, 113, 151],
  [67, 94, 137],
  [56, 75, 123],
  [47, 65, 109],
  [38, 54, 94],
];

export const INTERPOLATED_MAP_COLOR_DOMAIN: [number, number, number][] = [
  [220, 220, 220], // colors.ui.background.medium to blend the edge of the map with the background
  ...RODRIGO_HEATMAP_COLOR_DOMAIN_RADIOACTIVE_NICE,
];
