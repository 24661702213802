import { FC, useMemo } from 'react';

import { colors } from '@equinor/amplify-component-lib';
import { scaleLinear } from '@visx/scale';
import {
  AnimatedAxis,
  AnimatedGlyphSeries,
  AnimatedGrid,
  DataContext,
  XYChart,
} from '@visx/xychart';

import { RectGlyph } from 'components/Charts/XYChart/Glyphs/RectGlyph';
import { Legend } from 'components/Charts/XYChart/Legend/Legend';
import { XyTooltip } from 'components/Charts/XYChart/Tooltip/XyTooltip';
import { ChartProps } from 'components/Charts/XYChart/Utils/Chart.types';
import {
  getDepthDomain,
  getPressureDomain,
} from 'components/Charts/XYChart/Utils/Chart.utils';

import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';

import { useApp } from 'hooks/useApp';

export const Chart: FC<ChartProps> = ({ width, height, data }) => {
  const { unit, togglePrognosisChartXY } = useApp();
  const sortedData = data.sort((a, b) => a.referenceDepth! - b.referenceDepth!);

  const { selectedRows } = useEditPrognosis();

  const graphColors = data
    .map((d) => d.colorHtml)
    .filter((color): color is string => color !== undefined);

  const XScale = useMemo(() => {
    return scaleLinear({
      range: [0, width],
      domain: togglePrognosisChartXY
        ? getDepthDomain(sortedData)
        : getPressureDomain(sortedData),
      zero: false,
      clamp: true,
    });
  }, [sortedData, width, togglePrognosisChartXY]);

  const YScale = useMemo(() => {
    return scaleLinear({
      range: [height, 0],
      domain: togglePrognosisChartXY
        ? getPressureDomain(sortedData)
        : getDepthDomain(sortedData),
      zero: false,
      clamp: true,
    });
  }, [sortedData, height, togglePrognosisChartXY]);

  return (
    <DataContext.Provider
      value={{
        xScale: XScale,
        yScale: YScale,
      }}
    >
      <XYChart
        height={height}
        width={width}
        xScale={{
          type: 'linear',
          domain: togglePrognosisChartXY
            ? getDepthDomain(sortedData)
            : getPressureDomain(sortedData),
          zero: false,
          clamp: true,
        }}
        yScale={{
          type: 'linear',
          domain: togglePrognosisChartXY
            ? getPressureDomain(sortedData)
            : getDepthDomain(sortedData),
          zero: false,
          clamp: true,
        }}
        margin={{ top: 0, right: 0, bottom: 60, left: 60 }}
        horizontal={!togglePrognosisChartXY}
      >
        <AnimatedGrid
          columns={false}
          numTicks={5}
          stroke={colors.ui.background__medium.hex}
        />

        <AnimatedAxis
          orientation="bottom"
          label={togglePrognosisChartXY ? 'Depth (m)' : `Pressure (${unit})`}
          stroke={colors.ui.background__medium.hex}
          tickStroke={colors.ui.background__medium.hex}
        />
        <AnimatedAxis
          orientation="left"
          label={togglePrognosisChartXY ? `Pressure (${unit})` : 'Depth (m)'}
          hideZero
          hideAxisLine
          labelOffset={28}
          numTicks={5}
          hideTicks
        />
        <AnimatedGlyphSeries
          dataKey="highlow"
          enableEvents={false}
          data={sortedData}
          xAccessor={(d) => {
            return togglePrognosisChartXY ? d.referenceDepth : d?.pressureLow;
          }}
          yAccessor={(d) =>
            togglePrognosisChartXY ? d.pressureHigh : d.referenceDepth
          }
          renderGlyph={(glyphProps) => {
            const isSelected = selectedRows?.includes(glyphProps.datum.id);
            return (
              <RectGlyph
                datum={glyphProps.datum}
                color={glyphProps.datum.colorHtml!}
                toggleXY={togglePrognosisChartXY}
                y={togglePrognosisChartXY ? 0 : isSelected ? -6 : -3}
                x={togglePrognosisChartXY ? (isSelected ? -6 : -3) : 0}
                selectedZone={selectedRows ? selectedRows[0] : undefined}
              />
            );
          }}
          size={10}
        />

        <AnimatedGlyphSeries
          dataKey="expected"
          enableEvents={false}
          data={sortedData}
          yAccessor={(d) =>
            togglePrognosisChartXY ? d.pressureExpected : d.referenceDepth
          }
          xAccessor={(d) =>
            togglePrognosisChartXY ? d.referenceDepth : d.pressureExpected
          }
          renderGlyph={(glyphProps) => {
            const isSelected = selectedRows?.includes(glyphProps.datum.id);
            return (
              <g>
                <circle
                  cx={glyphProps.x}
                  cy={glyphProps.y}
                  r={isSelected ? 7 : 5}
                  fill="black"
                  opacity="0.4"
                />
                <circle
                  cx={glyphProps.x}
                  cy={glyphProps.y}
                  r={isSelected ? 3 : 2}
                  stroke="white"
                  fill="white"
                />
              </g>
            );
          }}
        />

        <AnimatedGlyphSeries
          dataKey="initial"
          data={sortedData}
          yAccessor={(d) =>
            togglePrognosisChartXY ? d?.pressureInitial : d?.referenceDepth
          }
          xAccessor={(d) =>
            togglePrognosisChartXY ? d?.referenceDepth : d?.pressureInitial
          }
          colorAccessor={() => colors.text.static_icons__default.hex}
        />
        <XyTooltip toggleXy={togglePrognosisChartXY} />
      </XYChart>
      <Legend graphColors={graphColors} />
    </DataContext.Provider>
  );
};
