import { FC } from 'react';

import { Typography } from '@equinor/amplify-component-lib';

import {
  LegendsDivider,
  MapLegends,
  SliderAndButtons,
  SliderInner,
  Wrapper,
} from './Footer.styles';
import { PressureTypeChip } from 'src/components/PressureTypeChip/PressureTypeChip';
import { PRESSURE_TYPES_IN_ORDER } from 'src/constants';
import { DateRangeSelection } from 'src/pages/MapView/components/Footer/components/DateRangeSelection';
import { GuidelinesMenu } from 'src/pages/MapView/components/Footer/components/GuidelinesMenu';
import { InterpolatedMapLegend } from 'src/pages/MapView/components/Footer/components/InterpolatedMapLegend/InterpolatedMapLegend';
import { Slider } from 'src/pages/MapView/components/Footer/components/Slider/Slider';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { MapBackground } from 'src/pages/MapView/MapView.types';
import { WellborePurpose } from 'src/types';

export const Footer: FC = () => {
  const { backgroundLayer } = useMapOptions();

  return (
    <Wrapper>
      <MapLegends>
        {backgroundLayer === MapBackground.INTERPOLATED && (
          <>
            <InterpolatedMapLegend />
            <LegendsDivider />
          </>
        )}
        <Typography group="input" variant="label">
          AVG = Average
        </Typography>
        <LegendsDivider />
        {PRESSURE_TYPES_IN_ORDER.map((type) => {
          return <PressureTypeChip key={type} type={type} />;
        })}
        <PressureTypeChip type={WellborePurpose.PRODUCTION} />
        <PressureTypeChip type={WellborePurpose.INJECTION} />
      </MapLegends>
      <SliderAndButtons>
        <GuidelinesMenu />
        <SliderInner>
          <Slider />
        </SliderInner>
        <DateRangeSelection />
      </SliderAndButtons>
    </Wrapper>
  );
};
