import { FC, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  RichTextEditor,
  RichTextEditorFeatures,
  Theme,
  Typography,
} from '@equinor/amplify-component-lib';

import { Section } from '../../../EditPrognosis.styles';
import { usePutDraftPrognosis } from 'src/pages/EditPrognosis/hooks/usePutDraftPrognosis';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

import { debounce } from 'lodash';
import styled from 'styled-components';

const EDITOR_PADDING = 32;

const Container = styled(Section)`
  .tiptap {
    min-height: calc(280px - ${EDITOR_PADDING}px);
    background: none;
  }
`;

export const PrognosisComments: FC = () => {
  const { control } = useFormContext<EditPrognosisFormValues>();
  // const { showSnackbar } = useSnackbar();
  // const { addImageFile } = useEditPrognosis();
  const { mutate: updateDraftPrognosis } = usePutDraftPrognosis();

  // we know that updateDraftPrognosis will never change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateDraftPrognosis = useCallback(
    debounce(() => {
      updateDraftPrognosis();
    }, 500),
    []
  );

  // const handleOnImageUpload = async (file: File) => {
  //   const image = new File([file], `${new Date().getTime()}ms_${file.name}`, {
  //     type: file.type,
  //     lastModified: file.lastModified,
  //   });

  //   if (image.size > ONE_MB) {
  //     showSnackbar('The image is too big! File size limit is 1MB');
  //     return;
  //   }

  //   const b64 = await imageToB64(file);

  //   addImageFile(file); // add image to the list of images to be handled on save

  //   // TODO: upload image to blob storage from here, so we don’t have to do it in usePutDraftPrognosis
  //   // TODO: also handle reverting the upload/delete if saving the draft fails
  //   return { b64, url: '' };
  // };

  return (
    <Container>
      <Typography variant="h4">Prognosis comments</Typography>
      <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <div data-theme={Theme.LIGHT}>
            <RichTextEditor
              maxHeight="560px"
              value={field.value}
              removeFeatures={[RichTextEditorFeatures.IMAGES]}
              onChange={(e) => {
                field.onChange(e);
                debouncedUpdateDraftPrognosis();
              }}
              // onImageUpload={handleOnImageUpload}
            />
          </div>
        )}
      />
    </Container>
  );
};
