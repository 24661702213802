import { useCallback, useMemo } from 'react';

import {
  FlyToInterpolator,
  MapViewState,
  TRANSITION_EVENTS,
} from '@deck.gl/core';

import { MapTool } from 'src/pages/MapView/components/MapSettings/MapTools';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { useGetFieldConfigForMap } from 'src/pages/MapView/hooks/useGetFieldConfigForMap';

export function useViewStateHandler() {
  const {
    viewState,
    setViewState,
    infoFooterViewState,
    setInfoFooterViewState,
    mapTool,
    isDraggingMeasureAreaPoint,
  } = useMapOptions();
  const {
    latitude,
    longitude,
    zoom,
    minZoom,
    maxZoom,
    reCenterLngRange,
    reCenterLatRange,
  } = useGetFieldConfigForMap();

  const isOutsideBounds = useMemo(() => {
    if (!reCenterLatRange || !reCenterLngRange) return;
    const { longitude, latitude } = viewState;
    const [latMin, latMax] = reCenterLatRange;
    const [lngMin, lngMax] = reCenterLngRange;

    return (
      longitude <= lngMin ||
      longitude >= lngMax ||
      latitude <= latMin ||
      latitude >= latMax
    );
  }, [reCenterLatRange, reCenterLngRange, viewState]);

  const handleOnViewStateChanged = useCallback(
    ({ viewState }: { viewState: MapViewState }) => {
      const changedViewState = viewState;

      if (changedViewState.latitude === 0 && changedViewState.longitude === 0)
        return;

      const noPanning =
        mapTool === MapTool.SELECT_AREA || isDraggingMeasureAreaPoint.current;
      setViewState((prev) => {
        return {
          longitude: noPanning ? prev.longitude : changedViewState.longitude,
          latitude: noPanning ? prev.latitude : changedViewState.latitude,
          zoom: changedViewState.zoom,
          maxZoom: maxZoom,
          minZoom: minZoom,
        };
      });
      setInfoFooterViewState(changedViewState);
    },
    [
      setInfoFooterViewState,
      isDraggingMeasureAreaPoint,
      mapTool,
      maxZoom,
      minZoom,
      setViewState,
    ]
  );

  const handleReCenter = () => {
    if (!latitude || !longitude || !zoom) return;
    setViewState({
      longitude: longitude,
      latitude: latitude,
      zoom: zoom,
      transitionDuration: 'auto',
      transitionInterruption: TRANSITION_EVENTS.IGNORE,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  return {
    infoFooterViewState,
    handleReCenter,
    isOutsideBounds,
    viewState,
    setViewState,
    handleOnViewStateChanged,
  };
}
