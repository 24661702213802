export const getDateForDaysIntoPast = (days: number) =>
  new Date(
    new Date(
      new Date(new Date().setHours(0, 0, 0, 0)).getTime() -
        new Date().getTimezoneOffset() * 60000
    ).valueOf() -
      1000 * 60 * 60 * 24 * days
  );

const treatAsUTC = (date: Date) => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

export const getDaysBetweenDates = (from: Date, to: Date) => {
  const fromTime = treatAsUTC(from).getTime();

  const toTime = treatAsUTC(to).getTime();

  return (toTime - fromTime) / (1000 * 3600 * 24);
};

// Get short month from date with first letter capitalized.
// Example: 01.01.2021 => Jan.
export const getShortMonth = (date: number) => {
  const month = new Date(date).toLocaleString('no-NO', { month: 'short' });
  return `${month.charAt(0).toUpperCase()}${month.slice(1)}.`;
};

// Get year from date.
// Example: 01.01.2021 => 2021
export const getYear = (date: number) => {
  return new Date(date).toLocaleString('no-NO', { year: 'numeric' });
};

export const isFirstDayOfMonth = (date: number) => {
  return new Date(date).getDate() === 1;
};

export const isFistMonthOfYear = (date: number) => {
  return new Date(date).getMonth() === 0;
};

/**
 * Parses a date string and returns it in ISO format.
 *
 * @param dateString - The date string to parse. If undefined, an empty string will be used.
 * @returns The ISO string representation of the parsed date. If the date string is invalid, the current date in ISO format will be returned.
 */
export const parseDate = (dateString: string | undefined): string => {
  const date = new Date(dateString ?? '');
  return isNaN(date.getTime()) ? new Date().toISOString() : date.toISOString();
};

export const formatTick = (date: Date) => {
  const convertToDate = new Date(date);

  return convertToDate.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
  });
};
