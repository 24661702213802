import {
  Banner,
  colors,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';

import { OverlappingPrognosis } from './OverlappingPrognosis';
import { StatusNameEnum } from 'src/types';

import { useOverlappingPrognoses } from 'hooks/useOverlappingPrognoses';
import { styled } from 'styled-components';

const color = colors.interactive.secondary__resting.rgba;

const Container = styled.div`
  border: 1px solid transparent;
  border-radius: ${spacings.x_small};
  overflow: hidden;
  width: 100%;
  min-width: 650px;
`;

const List = styled.div`
  grid-column: -1 / 1;
  list-style-type: none;
`;

const SpacingTop = styled.div`
  margin-top: ${spacings.medium};
`;

export const OverlappingNotification = () => {
  const { data: overlappingPrognoses } = useOverlappingPrognoses();

  if (
    !overlappingPrognoses ||
    overlappingPrognoses.length === 0 ||
    !overlappingPrognoses.some(
      (prognosis) =>
        prognosis.status === StatusNameEnum.APPROVED.toString() ||
        prognosis.status === StatusNameEnum.SUBMITTED.toString()
    )
  ) {
    return null;
  }

  return (
    <Container>
      <Banner variant="info">
        <Typography color={color} data-testid="overlapping-notification">
          <strong>Operation and Validity Dates Overlap:</strong> Overlapping
          dates on a submitted and approved prognosis on the same operation.
          Submitting a new prognosis will move the existing approved prognosis
          to historical.
        </Typography>
        <List>
          {overlappingPrognoses.some(
            (prognosis) =>
              prognosis.status === StatusNameEnum.APPROVED.toString()
          ) && (
            <Typography group="input" variant="label" color={color}>
              <strong>Overlapping approved Prognosis</strong>
            </Typography>
          )}
          {overlappingPrognoses
            .filter((prognosis) => {
              return prognosis.status === StatusNameEnum.APPROVED.toString();
            })
            .map((prognosis) => {
              return (
                <OverlappingPrognosis
                  key={prognosis.id}
                  prognosis={prognosis}
                />
              );
            })}
          <SpacingTop />
          {overlappingPrognoses.some(
            (prognosis) =>
              prognosis.status === StatusNameEnum.SUBMITTED.toString()
          ) && (
            <Typography group="input" variant="label" color={color}>
              <strong>Overlapping submitted Prognosis(es)</strong>
            </Typography>
          )}
          {overlappingPrognoses
            .filter((prognosis) => {
              return prognosis.status === StatusNameEnum.SUBMITTED.toString();
            })
            .map((prognosis) => {
              return (
                <OverlappingPrognosis
                  key={prognosis.id}
                  prognosis={prognosis}
                />
              );
            })}
        </List>
      </Banner>
    </Container>
  );
};
