export const MEASURED_DATA_FEATURE_KEY = 'measuredData';
export const UNDO_REDO_FEATURE_KEY = 'undoRedo';
export const TIME_CHART_FEATURE_KEY = 'timeChart';
export const TOTAL_DEPTH_ROW_FEATURE_KEY = 'totalDepthRow';
export const TOGGLE_UNITS_FEATURE_KEY = 'toggleUnits';
export const TOGGLE_APPROVER_OPTIONS = 'approverViewOptions';
export const SHOW_ADVANCED_FILTERS = 'showAdvancedFilters';
export const SHOW_ATTRIBUTE_CHIPS = 'attributeChips';
export const SHOW_COMINGLED_CHIP = 'coMingledChip';
export const SHOW_MEASURED_DATA_ALL_FIELDS = 'measuredDataAllFields';
export const TOPBAR_GUIDELINES = 'topbarGuidelines';
