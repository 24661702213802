import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import isPointInPolygon from '@turf/boolean-point-in-polygon';
import { polygon } from '@turf/helpers';

import { FieldService } from 'src/api';
import {
  FIELD_OUTLINE_QUERY_KEY,
  MEASURED_DATA_MAP_KEY,
} from 'src/constants/queryKeys';
import { useApp } from 'src/hooks';
import { FieldOutlineFeature } from 'src/pages/MapView/MapView.types';

export function useGetFieldOutlineFeatureCollection() {
  const { field } = useApp();

  const useQueryResult = useQuery({
    queryKey: [MEASURED_DATA_MAP_KEY, FIELD_OUTLINE_QUERY_KEY, field?.uuid],
    queryFn: async () => FieldService.getFieldOutlines(field?.uuid ?? ''),
    staleTime: Infinity,
    enabled: !!field?.uuid,
  });

  const featureLengthsArray = useQueryResult.data?.features?.map(
    (feature) => feature.geometry?.coordinates.at(0)?.length ?? 0
  );

  const mainFieldOutlineIndex =
    featureLengthsArray?.indexOf(Math.max(...featureLengthsArray)) ?? -1;

  const mainFieldOutline = useQueryResult.data?.features?.at(
    mainFieldOutlineIndex
  );

  const featuresWithIsInsideBoolean: FieldOutlineFeature[] = useMemo(() => {
    return (useQueryResult.data?.features ?? []).map((feature, index) => {
      const point = feature.geometry?.coordinates.at(0)?.at(0);

      const polygonCoordinates = mainFieldOutline?.geometry?.coordinates;
      if (!point || !polygonCoordinates)
        return { feature: feature, isInsideMainOutline: false };
      const polygonToCheck = polygon(polygonCoordinates);

      return {
        feature: feature,
        isInsideMainOutline:
          index !== mainFieldOutlineIndex &&
          isPointInPolygon(point, polygonToCheck),
      };
    });
  }, [useQueryResult.data, mainFieldOutline, mainFieldOutlineIndex]);

  const filteredOutlineFeatures = useMemo(
    () =>
      featuresWithIsInsideBoolean?.filter((feature) => {
        const lengthOfFeature =
          feature.feature.geometry?.coordinates.at(0)?.length;
        return lengthOfFeature && lengthOfFeature > 40;
      }) ?? [],
    [featuresWithIsInsideBoolean]
  );

  return { filteredOutlineFeatures, mainFieldOutline, mainFieldOutlineIndex };
}
