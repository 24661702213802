import { useFormContext } from 'react-hook-form';

import { useQuery } from '@tanstack/react-query';

import { ReservoirZoneService } from 'src/api';
import { LAST_USED_ZONES_KEY, TOP_ZONES_KEY } from 'src/constants/queryKeys';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

export const useGetLastUsedZones = () => {
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { wellbore, stratColumnIdentifier, operation } = getValues();

  const cleanStringForUrl = (str: string | undefined): string => {
    return encodeURIComponent(str!);
  };

  const identifier = cleanStringForUrl(wellbore?.label);
  const columnIdentifier: string = cleanStringForUrl(
    stratColumnIdentifier?.label
  );

  return useQuery({
    queryKey: [
      columnIdentifier,
      identifier,
      operation.value,
      LAST_USED_ZONES_KEY,
    ],
    queryFn: () =>
      ReservoirZoneService.getApiV1ReservoirZonesWellboresStratColumnsOperationLastUsed(
        identifier,
        columnIdentifier,
        Number(operation?.value)
      ),
  });
};

export const useGetTopUsedZones = () => {
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { wellbore, stratColumnIdentifier, operation } = getValues();

  const cleanStringForUrl = (str: string): string => {
    return encodeURIComponent(str);
  };

  const identifier = cleanStringForUrl(wellbore?.label) ?? '';
  const columnIdentifier = cleanStringForUrl(
    stratColumnIdentifier?.label ?? ''
  );

  return useQuery({
    queryKey: [columnIdentifier, identifier, operation.value, TOP_ZONES_KEY],
    queryFn: () =>
      ReservoirZoneService.getApiV1ReservoirZonesWellboresStratColumnsOperationTopUsed(
        identifier,
        columnIdentifier,
        Number(operation?.value)
      ),
  });
};
