import { PolygonFeature } from 'src/api';

export interface LegendRange {
  min: number;
  max: number;
}

export type DataSet = [number, number, number][];

export enum MapLayer {
  FIELD_OUTLINE = 'Field outline',
  FAULT_LINES = 'Fault lines',
  AREAS_OUTLINE = 'Areas outline',
  INJECTORS = 'Injectors',
  PRODUCERS = 'Producers',
  FLOWING_WELLS = 'Flowing wells',
  NON_FLOWING_WELLS = 'Non-flowing wells',
  MISSING_WELLS = 'Missing wells',
  WELLBORE_PATHS = 'Wellbore paths',
  PRESSURE_VALUE = 'Pressure value',
  WELLBORE_LABEL = 'Wellbore label',
}

export enum MapBackground {
  INTERPOLATED = 'Interpolated map',
}

export interface FieldOutlineFeature {
  feature: PolygonFeature;
  isInsideMainOutline: boolean;
}
