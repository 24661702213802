import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import { CircularProgress, Typography } from '@equinor/amplify-component-lib';

import { Table } from 'components/Table/Table';

import { Section } from '../../EditPrognosis.styles';
import { useEditPrognosis } from '../../hooks/useEditPrognosis';
import { checkShowWellbore } from '../../utils/formUtils';
import { BorderedSection } from './BorderedSection/BorderedSection';
import { OptionalAttributes } from './OptionalAttributes/OptionalAttributes';
import { PrognosisComments } from './PrognosisComments/PrognosisComments';
import { SelectWellbore } from './SelectWellbore/SelectWellbore';
import { ZoneSelector } from './ZoneSelector/ZoneSelector';
import { InitializingContainer, MainContent } from './FormData.styles';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

export const FormData: FC = () => {
  const { watch } = useFormContext<EditPrognosisFormValues>();
  const { isInitializing } = useEditPrognosis();
  const { prognosisId } = useParams();
  const formData = watch();
  const { stratColumnIdentifier } = watch();

  const showWellbore = checkShowWellbore(formData, prognosisId === undefined);

  if (isInitializing) {
    return (
      <MainContent>
        <InitializingContainer>
          <Typography variant="h2">Loading existing prognosis...</Typography>
          <CircularProgress color="primary" />
        </InitializingContainer>
      </MainContent>
    );
  }

  return (
    <MainContent $disabled={!showWellbore}>
      <SelectWellbore disabled={!showWellbore} />
      {prognosisId && (
        <>
          <OptionalAttributes />
          <BorderedSection title="Zone selection">
            <ZoneSelector />
          </BorderedSection>
          {stratColumnIdentifier && (
            <>
              <Section>
                <Table isEditing={true} />
              </Section>
              <PrognosisComments />
            </>
          )}
        </>
      )}
    </MainContent>
  );
};
