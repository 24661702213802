import {
  colors,
  formatDate,
  isBetweenDates,
} from '@equinor/amplify-component-lib';

import { PressureDataSimple } from '../api';
import { MeasuredDataFilter } from 'src/api';
import { DataType } from 'src/api';
import { FilterCondition } from 'src/api';
import { FilterDataTypes } from 'src/api';
import { HelpLine } from 'src/api';
import { PressureType } from 'src/api';
import { WellboreDetailed } from 'src/api';
import {
  HELP_LINE_OPACITY_HEX_COLOR_STRING,
  LINE_PLOT_WELL_COLORS,
  PressureTypesValues,
  RIGHT_AXES,
  TEMP_ID_PREFIX,
} from 'src/constants';
import { MapLayer } from 'src/pages/MapView/MapView.types';
import {
  IndefiniteTimeStringType,
  LineStyleType,
  Measurement,
  RightAxisEnum,
  WellborePurpose,
} from 'src/types';

export const getMeasurementFromPressureDataSimple = (
  data: PressureDataSimple
) => {
  return {
    wellboreUuid: data.wellboreUuid,
    value: `${data.wellboreUuid}:${data.screenPerfCount === 1 ? 1 : 0}`,
    label: data.wellboreName ?? '',
    dhpg: data.dhpg,
  };
};

export const getNameFromMeasurement = (
  measurement: Measurement | PressureDataSimple | undefined
) => {
  if (!measurement) return 'Wellbore';

  if ('label' in measurement) {
    return measurement.label;
  }

  if ('wellboreName' in measurement && 'screenPerfCount' in measurement) {
    const dhpg = measurement.screenPerfCount > 1 ? `:${measurement.dhpg}` : '';
    return `${measurement.wellboreName}${dhpg}`;
  }
  return ''; // Should never happen, each if above check for its own of the two types, just returning string to keep type as string
};

export const getNameFromDetailed = (
  wellBore: WellboreDetailed,
  dhpg?: number
): string => {
  if (wellBore?.screenPerfCount && wellBore?.screenPerfCount >= 2 && dhpg) {
    return `${wellBore.wellName}:${dhpg}`;
  }

  return `${wellBore.wellName}`;
};

export const compareMeasurements = (
  measurementA: Measurement | PressureDataSimple,
  measurementB: Measurement | PressureDataSimple
): boolean => {
  return (
    getNameFromMeasurement(measurementA) ===
    getNameFromMeasurement(measurementB)
  );
};

export const compareHelpLine = (
  helpLineA: HelpLine,
  helpLineB: HelpLine
): boolean => {
  return (
    helpLineA.name === helpLineB.name &&
    helpLineA.measureType === helpLineB.measureType &&
    helpLineA.type === helpLineB.type
  );
};

export const compareHelpLineByName = (
  helpLineA: HelpLine,
  helpLineB: HelpLine
): boolean => {
  return helpLineA.name === helpLineB.name;
};

const linePlotColorState = new Set<Measurement | PressureDataSimple>();

export function getLineChartLineColor(
  measurement: Measurement | PressureDataSimple
): string {
  const index = Array.from(linePlotColorState).findIndex((m) =>
    compareMeasurements(m, measurement)
  );
  if (index === -1 && linePlotColorState.size >= LINE_PLOT_WELL_COLORS.length) {
    let sum = measurement?.dhpg ?? 0;
    const value = measurement.wellboreUuid;

    if (value)
      for (let i = 0; i < value.length; i++) {
        sum += value.charCodeAt(i);
      }
    return LINE_PLOT_WELL_COLORS[sum % LINE_PLOT_WELL_COLORS.length];
  }
  if (index === -1) {
    linePlotColorState.add(measurement);
  }
  const index2 = Array.from(linePlotColorState).findIndex((m) =>
    compareMeasurements(m, measurement)
  );
  return LINE_PLOT_WELL_COLORS[index2 % LINE_PLOT_WELL_COLORS.length];
}

const helplineColorState = new Set<HelpLine>();

export function getHelpLineColor(helpLine: HelpLine): string {
  const index = Array.from(helplineColorState).findIndex((m) =>
    compareHelpLineByName(m, helpLine)
  );
  if (index === -1 && helplineColorState.size >= LINE_PLOT_WELL_COLORS.length) {
    let sum = 0;
    if (helpLine.name) {
      for (let i = 0; i < helpLine.name.length; i++) {
        sum += helpLine.name.charCodeAt(i);
      }
    }
    return `${LINE_PLOT_WELL_COLORS[sum % LINE_PLOT_WELL_COLORS.length]}${HELP_LINE_OPACITY_HEX_COLOR_STRING}`;
  }
  if (index === -1) {
    helplineColorState.add(helpLine);
  }
  const index2 = Array.from(helplineColorState).findIndex((m) =>
    compareHelpLineByName(m, helpLine)
  );
  return `${LINE_PLOT_WELL_COLORS[index2 % LINE_PLOT_WELL_COLORS.length]}${HELP_LINE_OPACITY_HEX_COLOR_STRING}`;
}

export interface LabelColorType {
  background: string;
  text: string;
}

export const getMapLabelColors = (type: PressureType) => {
  const labelColors: LabelColorType = {
    background: colors.text.static_icons__secondary.rgba,
    text: colors.text.static_icons__primary_white.rgba,
  };

  if (type !== undefined) {
    labelColors.background = PressureTypesValues[type].background;
    labelColors.text = PressureTypesValues[type].color;
  }
  return labelColors;
};

export const sortMeasuredDataFilters = (
  a: MeasuredDataFilter,
  b: MeasuredDataFilter
) => {
  let aDate: Date | undefined = undefined;
  let bDate: Date | undefined = undefined;

  if ('changes' in a && 'changes' in b) {
    {
      const aDateString = a.changes?.at(0)?.date;
      const bDateString = b.changes?.at(0)?.date;
      if (aDateString && bDateString) {
        aDate = new Date(aDateString);
        bDate = new Date(bDateString);
      }
    }
  }
  if ('rule' in a && 'rule' in b) {
    const aDateString = a.rule?.fromDate;
    const bDateString = b.rule?.fromDate;
    if (aDateString && bDateString) {
      aDate = new Date(aDateString);
      bDate = new Date(bDateString);
    }
  }
  if (aDate && bDate) {
    return bDate.getTime() - aDate.getTime(); // Newest date first
  }

  if (a.filterOrder && b.filterOrder) {
    return b.filterOrder - a.filterOrder;
  }
  return 0;
};

export const getHelpLineStrokeDashArray = (
  dataType: DataType | undefined,
  inLegend?: boolean
) => {
  switch (dataType) {
    case DataType.CUMULATIVE_INJECTION:
    case DataType.CUMULATIVE_VOIDAGE:
    case DataType.CUMULATIVE_PRODUCTION:
    case DataType.INSTANT_INJECTION:
    case DataType.INSTANT_PRODUCTION:
    case DataType.INSTANT_VOIDAGE:
    case DataType.PRODUCTIVITY_INDEX:
    case DataType.PRODUCTIVITY_INDEX_INTERPOLATED:
    case DataType.ON_STREAM_HOURS:
    case DataType.GRADIENT:
      return inLegend ? '4, 5, 10, 5' : '4, 4, 12, 4';
    case DataType.RAW:
      return inLegend ? '6, 8' : '5, 7';
    case DataType.DEPTH_CORRECTED:
      return inLegend ? '7,5' : '3, 3';
    case DataType.FINAL:
      return inLegend ? '1, 5' : '1, 3';
    default:
      return '';
  }
};

export const addHelpLineStyles = (
  helpLines: HelpLine[],
  lineStyles: LineStyleType[]
) => {
  const lineStyleArray = structuredClone(lineStyles);
  if (helpLines.some((helpLine) => helpLine.type === DataType.RAW)) {
    lineStyleArray.push({
      label: 'Raw data',
      strokeDasharray: getHelpLineStrokeDashArray(DataType.RAW, true),
    });
  }
  if (
    helpLines.some((helpLine) => helpLine.type === DataType.DEPTH_CORRECTED)
  ) {
    lineStyleArray.push({
      label: 'Datum data',
      strokeDasharray: getHelpLineStrokeDashArray(
        DataType.DEPTH_CORRECTED,
        true
      ),
    });
  }
  if (helpLines.some((helpLine) => helpLine.type === DataType.PICORRECTED)) {
    lineStyleArray.push({
      label: 'PI corr',
      strokeDasharray: getHelpLineStrokeDashArray(DataType.PICORRECTED, true),
    });
  }
  if (helpLines.some((helpLine) => helpLine.type === DataType.FINAL)) {
    lineStyleArray.push({
      label: 'QC data',
      strokeDasharray: getHelpLineStrokeDashArray(DataType.FINAL, true),
    });
  }
  if (helpLines.some((helpLine) => RIGHT_AXES.includes(helpLine.type))) {
    lineStyleArray.push({
      label: 'Right axis',
      strokeDasharray: getHelpLineStrokeDashArray(DataType.GRADIENT, true),
    });
  }
  return lineStyleArray;
};

export const createTemporaryId = () => {
  const unixTimeStamp = new Date().getTime();
  return `${TEMP_ID_PREFIX}${unixTimeStamp}`;
};

const isIdTemporary = (id: string) => {
  return id.includes(TEMP_ID_PREFIX);
};

export const cleanFiltersOfTempId = (filters: MeasuredDataFilter[]) => {
  const clonedFilters = structuredClone(filters);
  for (const filter of clonedFilters) {
    if (filter.id && isIdTemporary(filter.id)) {
      delete filter.id;
    }
    if (filter.rule?.ruleId && isIdTemporary(filter.rule?.ruleId)) {
      delete filter.rule?.ruleId;
    }
    if (!filter.rule?.criteria) continue;

    for (const criteria of filter.rule.criteria) {
      if (criteria.criteriaId && isIdTemporary(criteria.criteriaId)) {
        delete criteria.criteriaId;
      }
    }
  }
  return clonedFilters;
};

export const getOperatorSymbol = (operator: FilterCondition | undefined) => {
  switch (operator) {
    case FilterCondition.LESSTHAN:
      return '<';
    case FilterCondition.LESSTHANOREQUAL:
      return '<=';
    case FilterCondition.EQUAL:
      return '=';
    case FilterCondition.GREATERTHAN:
      return '>';
    case FilterCondition.GREATERTHANOREQUAL:
      return '>=';
    default:
      return '';
  }
};

export const getShortCriteriaDatatype = (datatype: FilterDataTypes) => {
  switch (datatype) {
    case FilterDataTypes.CUMULATIVE_PRODUCTION:
      return 'CumulVol';
    case FilterDataTypes.INSTANT_PRODUCTION:
      return 'InstRate';
    case FilterDataTypes.PRESSURE:
      return 'Pressure';
    case FilterDataTypes.ON_STREAM_HOURS:
      return 'OnStrHrs';
  }
};

export const getDefaultFilterTitle = (filter: MeasuredDataFilter) => {
  const firstCriteria = filter.rule?.criteria?.at(0);
  if (firstCriteria?.dataType && firstCriteria) {
    return `${getShortCriteriaDatatype(firstCriteria.dataType)} ${getOperatorSymbol(firstCriteria.condition) ?? ''} ${firstCriteria.criteria ?? ''}`;
  }
  return 'Rule';
};

export const getIndefiniteTimeString = (type: IndefiniteTimeStringType) => {
  if (type === IndefiniteTimeStringType.PAST) {
    return new Date('1. jan 1980').toISOString();
  }
  if (type === IndefiniteTimeStringType.FUTURE) {
    return new Date('1. jan 2099').toISOString();
  }
};

export const isDateIndefinite = (date: string | undefined | null) => {
  if (!date) return false;

  const pastDate = new Date('1. jan 1981');
  const futureDate = new Date('1. jan 2098');
  return !isBetweenDates(new Date(date), [pastDate, futureDate]);
};

export const getDateRangeLabelWithIndefinite = (from: string, to: string) => {
  const isFromIndefinite = isDateIndefinite(from);
  const isToIndefinite = isDateIndefinite(to);
  if (isFromIndefinite && isToIndefinite) {
    return 'Indefinite';
  } else if (isFromIndefinite && !isToIndefinite) {
    return `Indefinite - ${formatDate(to)}`;
  } else if (!isFromIndefinite && isToIndefinite) {
    return `${formatDate(from)} - Indefinite`;
  }
  return `${formatDate(from)} - ${formatDate(to)}`;
};

export const hasInstantData = (helpLines: HelpLine[]) => {
  return (
    helpLines.find((value) => value.type === DataType.INSTANT_PRODUCTION) !==
      undefined ||
    helpLines.find((value) => value.type === DataType.INSTANT_INJECTION) !==
      undefined
  );
};

export const hasCumulativeData = (helpLines: HelpLine[]) => {
  return (
    helpLines.find((value) => value.type === DataType.CUMULATIVE_PRODUCTION) !==
      undefined ||
    helpLines.find((value) => value.type === DataType.CUMULATIVE_INJECTION) !==
      undefined
  );
};

export const hasGradient = (helpLines: HelpLine[]) => {
  return (
    helpLines.find((value) => value.type === DataType.GRADIENT) !== undefined
  );
};

export const hasOnStreamHours = (helpLines: HelpLine[]) => {
  return (
    helpLines.find((value) => value.type === DataType.ON_STREAM_HOURS) !==
    undefined
  );
};
export const hasProductivityIndex = (helpLines: HelpLine[]) => {
  return (
    helpLines.find(
      (value) =>
        value.type === DataType.PRODUCTIVITY_INDEX ||
        value.type === DataType.PRODUCTIVITY_INDEX_INTERPOLATED
    ) !== undefined
  );
};

export const showRightAxisComboBox = (helpLines: HelpLine[]) => {
  const uniqueRightAxisTypes: DataType[] = [];
  helpLines.forEach((helpLine) => {
    if (
      uniqueRightAxisTypes.findIndex((tempType) => tempType === helpLine.type) <
      0
    ) {
      uniqueRightAxisTypes.push(helpLine.type);
    }
  });

  return uniqueRightAxisTypes.length > 1;
};

export const getRightAxisArray = (helpLines: HelpLine[]) => {
  const activeRightAxisHelpLines: RightAxisEnum[] = [];
  if (
    helpLines.find(
      (helpLine) =>
        helpLine.type === DataType.INSTANT_PRODUCTION ||
        helpLine.type === DataType.INSTANT_INJECTION
    )
  ) {
    activeRightAxisHelpLines.push(RightAxisEnum.INSTANT);
  }
  if (
    helpLines.find(
      (helpLine) =>
        helpLine.type === DataType.CUMULATIVE_PRODUCTION ||
        helpLine.type === DataType.CUMULATIVE_INJECTION
    )
  ) {
    activeRightAxisHelpLines.push(RightAxisEnum.CUMULATIVE);
  }
  if (helpLines.find((helpLine) => helpLine.type === DataType.GRADIENT)) {
    activeRightAxisHelpLines.push(RightAxisEnum.GRADIENT);
  }
  if (
    helpLines.find((helpLine) => helpLine.type === DataType.ON_STREAM_HOURS)
  ) {
    activeRightAxisHelpLines.push(RightAxisEnum.ON_STREAM_HOURS);
  }
  if (
    helpLines.find((helpLine) => helpLine.type === DataType.PRODUCTIVITY_INDEX)
  ) {
    activeRightAxisHelpLines.push(RightAxisEnum.PRODUCTIVITY_INDEX);
  }
  if (
    helpLines.find(
      (helpLine) => helpLine.type === DataType.PRODUCTIVITY_INDEX_INTERPOLATED
    )
  ) {
    activeRightAxisHelpLines.push(RightAxisEnum.PRODUCTIVITY_INDEX);
  }
  return activeRightAxisHelpLines;
};

export const filterPressurePoints = (
  pressurePoints: PressureDataSimple[],
  wellBores: WellboreDetailed[],
  mapLayers: MapLayer[]
) => {
  return pressurePoints.filter((point) => {
    const wellbore = wellBores.find(
      (wellbore) => wellbore.wellboreUuid === point.wellboreUuid
    );
    const showMissing =
      point.pressureType !== PressureType.MISSING ||
      mapLayers.includes(MapLayer.MISSING_WELLS);

    const showPurpose =
      wellbore?.purpose === (WellborePurpose.PRODUCTION as string)
        ? mapLayers.includes(MapLayer.PRODUCERS)
        : mapLayers.includes(MapLayer.INJECTORS);

    const showOpenClosed =
      point?.onStreamHours && point?.onStreamHours > 0
        ? mapLayers.includes(MapLayer.FLOWING_WELLS)
        : mapLayers.includes(MapLayer.NON_FLOWING_WELLS);

    return showPurpose && showOpenClosed && showMissing;
  });
};
