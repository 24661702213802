import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from 'src/App';
import { Providers } from 'src/providers/Providers';

const container = document.getElementById('app') as HTMLDivElement;
const root = createRoot(container);

root.render(
  <BrowserRouter
    future={{
      v7_relativeSplatPath: true,
      v7_startTransition: true,
    }}
  >
    <Providers>
      <StrictMode>
        <App />
      </StrictMode>
    </Providers>
  </BrowserRouter>
);
