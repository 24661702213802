import { FC, useState } from 'react';

import { TextField, Typography } from '@equinor/amplify-component-lib';

import { MainDialogContent, Spacing } from '../Actions.styles';
import { usePrognosisApproval } from 'src/pages/DetailedView/hooks/usePrognosisApproval';

interface RejectDialogProps {
  open: boolean;
  title: string;
  isBackupApprover: boolean;
  onClose: () => void;
}

import { Dialog } from '@equinor/amplify-component-lib';

export const RejectDialog: FC<RejectDialogProps> = ({
  open,
  title,
  isBackupApprover,
  onClose,
}) => {
  const [optionalComment, setOptionalComment] = useState<string>('');

  const { revisePrognosis } = usePrognosisApproval();
  const { mutate: rejectMutate, isPending: isRejectPending } = revisePrognosis;

  const handleOnSubmit = () => {
    if (isRejectPending) return;
    rejectMutate(optionalComment);
  };

  const handleOnTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionalComment(e.target.value);
  };

  if (!open) return null;

  return (
    <Dialog
      title={`Reject prognosis - ${title}`}
      open={open}
      onClose={onClose}
      width={500}
      withContentPadding={{ vertical: true, horizontal: true }}
      withBorders
      actions={[
        {
          position: 'right',
          onClick: onClose,
          text: 'Cancel',
          variant: 'outlined',
        },
        {
          position: 'right',
          onClick: handleOnSubmit,
          text: 'Reject',
          color: 'danger',
          disabled: isRejectPending,
          isLoading: isRejectPending,
        },
      ]}
    >
      <MainDialogContent>
        <TextField
          id="reject"
          label="Add comment"
          value={optionalComment}
          onChange={handleOnTextFieldChange}
          multiline
          rows={3}
          placeholder="Add a comment based on the decision taken"
        />
        <Typography variant="body_short">
          Rejecting this prognosis will return it to the creator with the
          comment you add.
          <strong> This action cannot be undone.</strong>
          {isBackupApprover && (
            <>
              <Spacing />
              <strong>
                You are rejecting this prognosis as a backup approver.
              </strong>
            </>
          )}
        </Typography>
      </MainDialogContent>
    </Dialog>
  );
};
