import { FC, ReactNode, useEffect, useState } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes as ReactRoutes,
} from 'react-router-dom';

import {
  FullPageSpinner,
  PageNotFound,
  Status,
  useAuth,
} from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { Dashboard } from './Dashboard/Dashboard';
import { DetailedView } from './DetailedView/DetailedView';
import { EditMeasuredData } from './EditMeasuredData/EditMeasuredData';
import { EditPrognosis } from './EditPrognosis/EditPrognosis';
import { MapView } from './MapView/MapView';
import { PlotView } from './PlotView/PlotView';
import { PreviewPrognosis } from './Preview/PreviewPrognosis';
import { SelectField } from './SelectField';
import {
  FIELDS_WITH_MEASURED_DATA,
  SHOW_MEASURED_DATA_ALL_FIELDS,
} from 'src/constants';
import {
  useApp,
  useMeasuredDataAccessCheck,
  usePrognosisFromId,
} from 'src/hooks';
import { MeasuredDataPage } from 'src/types';
import { checkIfUserCanEdit, getUsernameFromEmail } from 'src/utils';

const PrivateRoutes = () => {
  const { field } = useApp();

  if (field?.uuid === null || field?.uuid === '' || field?.uuid === undefined) {
    return <Navigate replace to="/select-field" />;
  }
  return <Outlet />;
};

const MeasuredDataRoutes = () => {
  const { account } = useAuth();
  const { field } = useApp();
  const { showContent } = useFeatureToggling({
    username: account?.username ?? '',
    featureKey: SHOW_MEASURED_DATA_ALL_FIELDS,
  });
  const { data: missingAccesses, isLoading: isLoadingAccessCheck } =
    useMeasuredDataAccessCheck();

  // TODO: remove the 'FIELDS_WITH_MEASURED_DATA' check when all fields have measured data
  if (!showContent && !FIELDS_WITH_MEASURED_DATA.includes(field?.name ?? '')) {
    return <Navigate replace to="/dashboard" />;
  }

  if (isLoadingAccessCheck) {
    return <FullPageSpinner />;
  }

  if (missingAccesses && missingAccesses.length > 0) {
    return (
      <Status>
        <Status.Title title="Missing data accesses for this page" />
        <Status.MissingAccesses
          text="In order to view measured data in Premo, you need the following accesses, with their respective links to apply for:"
          accesses={missingAccesses.map((access) => {
            return { title: access.dataBaseAccess, url: access.accessItLink };
          })}
        />
      </Status>
    );
  }

  return <Outlet />;
};

const EditRoute = () => {
  const { data: prognosisData } = usePrognosisFromId();
  const { account } = useAuth();
  const currentUser = getUsernameFromEmail(account?.username ?? '');
  const [canEdit, setCanEdit] = useState<boolean>(true);

  useEffect(() => {
    if (prognosisData !== undefined) {
      setCanEdit(
        checkIfUserCanEdit({
          creator: prognosisData?.createUser ?? '',
          currentUser,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run this when we have the prognosisData
  }, [prognosisData]);

  if (!canEdit) return <Navigate replace to="/dashboard" />;

  return <Outlet />;
};

interface AppRoutesProps {
  children?: ReactNode;
}

export const AppRoutes: FC<AppRoutesProps> = ({ children }) => {
  return (
    <ReactRoutes>
      <Route element={<PrivateRoutes />}>
        <Route
          path="/"
          element={<Navigate replace to="/measured-data/map" />}
        />
        <Route path="/detailed-view/:prognosisId" element={<DetailedView />} />
        <Route path="/preview/:prognosisId" element={<PreviewPrognosis />} />
        <Route element={<MeasuredDataRoutes />}>
          <Route
            path={`measured-data/${MeasuredDataPage.MAP}`}
            element={<MapView />}
          />
          <Route
            path={`measured-data/${MeasuredDataPage.PLOT}`}
            element={<PlotView />}
          />
          <Route
            path={`measured-data/${MeasuredDataPage.EDIT}/:wellId/:dhpg`}
            element={<EditMeasuredData />}
          />
        </Route>
        <Route path="dashboard">
          <Route index element={<Dashboard />} />
          <Route path="create-new-prognosis" element={<EditPrognosis />} />
          <Route element={<EditRoute />}>
            <Route
              path="edit-prognosis/:prognosisId"
              element={<EditPrognosis />}
            />
          </Route>
          <Route path="*" element={<Navigate replace to="/dashboard" />} />
        </Route>
      </Route>
      <Route path="/select-field" element={<SelectField />} />
      <Route path="*" element={<PageNotFound />} />
      {children}
    </ReactRoutes>
  );
};
