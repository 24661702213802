import { FC, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  SelectOptionRequired,
  SingleSelect,
  Typography,
} from '@equinor/amplify-component-lib';

import { ConfirmDialog } from '../../ConfimDialog/ConfirmDialog';
import { ComboboxContainer } from '../FormData.styles';
import { Wellbore } from 'src/api';
import {
  useGetAllWellbores,
  useGetDrilledWellbores,
  useGetPlannedWellbores,
} from 'src/hooks';
import { Section } from 'src/pages/EditPrognosis/EditPrognosis.styles';
import { useDeleteDetail } from 'src/pages/EditPrognosis/hooks/useDeleteDetail';
import { usePostDraftPrognosis } from 'src/pages/EditPrognosis/hooks/usePostDraftPrognosis';
import { usePutDraftPrognosis } from 'src/pages/EditPrognosis/hooks/usePutDraftPrognosis';
import {
  checkPostDraftPrognosis,
  NextValueProps,
} from 'src/pages/EditPrognosis/utils/formUtils';
import { EditPrognosisFormValues, OperationValues } from 'src/utils';

interface SelectWellboreProps {
  disabled?: boolean;
}

export const SelectWellbore: FC<SelectWellboreProps> = ({
  disabled = false,
}) => {
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useFormContext<EditPrognosisFormValues>();
  const { prognosisDetails, operation } = watch();

  const { data: allWellbores, isLoading: isLoadingWellbores } =
    useGetAllWellbores();

  const { data: plannedWellbores } = useGetPlannedWellbores();
  const { data: drilledWellbores } = useGetDrilledWellbores();
  const { mutate: updateDraftPrognosis } = usePutDraftPrognosis();
  const { mutate: postDraftPrognosis, isPending: isCreating } =
    usePostDraftPrognosis();

  const { mutate: deletePrognosisDetail } = useDeleteDetail();
  const { prognosisId } = useParams();

  const [selectLabel, setSelectLabel] = useState('Wellbores');
  const [open, setOpen] = useState(false);
  const [nextValue, setNextValue] = useState<NextValueProps>();

  const handleUpdateWellbore = ({ key, item }: NextValueProps) => {
    const previousValue: SelectOptionRequired | undefined = getValues(key);
    const isFieldDirty = item?.value !== previousValue?.value;

    if (isFieldDirty) {
      if (prognosisDetails?.length !== 0) {
        setOpen(true);
        setNextValue({ key: key, item });
        return;
      }

      if (!item) return;
      setValue(key, item);

      const formData = watch();
      const shouldPostPrognosis = checkPostDraftPrognosis(
        formData,
        prognosisId == undefined,
        isCreating
      );

      if (shouldPostPrognosis) {
        postDraftPrognosis();
      } else {
        updateDraftPrognosis();
      }
    }
  };

  const handleConfirm = () => {
    setOpen(false);

    if (!nextValue) return;

    prognosisDetails.map((detail) => {
      try {
        deletePrognosisDetail(detail.id);
      } catch (error) {
        console.error(error);
        return;
      }
    });

    const { key, item } = nextValue;

    if (!item) return;
    setValue(key, item);

    const isValid = !errors[key] || Object.keys(errors[key]).length === 0;
    if (isValid) {
      updateDraftPrognosis();
    }
  };

  const wellboreOptions: SelectOptionRequired[] = useMemo(() => {
    if (operation?.value.toString() === OperationValues.NEW_WELL.toString()) {
      setSelectLabel('Planned wellbores');
      return (
        plannedWellbores?.map((item: Wellbore) => ({
          value: item.wellboreUuid ?? '',
          label: item.uniqueWellboreIdentifier ?? 'N/A',
        })) ?? []
      );
    }

    if (
      operation?.value.toString() === OperationValues.INTERVENTION.toString() ||
      operation?.value.toString() === OperationValues.P_N_A.toString()
    ) {
      setSelectLabel('Drilled wellbores');
      return (
        drilledWellbores?.map((item: Wellbore) => ({
          value: item.wellboreUuid ?? '',
          label: item.uniqueWellboreIdentifier ?? 'N/A',
        })) ?? []
      );
    }
    setSelectLabel('Wellbores');
    return (
      allWellbores?.map((item: Wellbore) => ({
        value: item.wellboreUuid ?? '',
        label: item.uniqueWellboreIdentifier ?? 'N/A',
      })) ?? []
    );
  }, [allWellbores, plannedWellbores, drilledWellbores, operation]);

  return (
    <Section>
      <Typography variant="h4">Select a wellbore to start</Typography>
      <ComboboxContainer>
        <Controller
          name="wellbore"
          control={control}
          rules={{ required: 'Wellbore is required' }}
          render={({ field }) => (
            <SingleSelect
              label={selectLabel}
              items={wellboreOptions}
              placeholder="Select one..."
              value={field.value}
              loading={isLoadingWellbores}
              onSelect={(item) => {
                handleUpdateWellbore({ key: 'wellbore', item });
              }}
              meta="Required"
              variant={errors.wellbore ? 'error' : undefined}
              clearable={false}
              disabled={disabled}
            />
          )}
        />
      </ComboboxContainer>
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => handleConfirm()}
      />
    </Section>
  );
};
