import { useNavigate, useParams } from 'react-router-dom';

import { useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation } from '@tanstack/react-query';

import { BlobService, PrognosisService } from 'src/api';

export function useDeletePrognosis() {
  const { prognosisId } = useParams();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async () => {
      await BlobService.deleteApiV1BlobByPrognosisId(Number(prognosisId)); // deletes images in blob storage
      return PrognosisService.deleteApiV1Prognoses(Number(prognosisId));
    },
    onSuccess: () => {
      showSnackbar('Prognosis deleted');
      navigate('/dashboard');
    },
  });
}
