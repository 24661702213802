import { useAuth } from '@equinor/amplify-component-lib';

import { usePrognosisFromId } from 'src/hooks';
import { useGetUserAccess } from 'src/hooks/useGetUserAccess';
import { StatusIdEnum } from 'src/types/status';

export function useShouldShowApproverActions() {
  const { data: prognosisData } = usePrognosisFromId();
  const { userAccessForField } = useGetUserAccess();
  const { account } = useAuth();
  const approverShortName = account?.username.split('@').at(0) ?? '';

  const isAssignedApprover =
    approverShortName === prognosisData?.mainApprover ||
    approverShortName === prognosisData?.backupApprover;

  const isBackupApprover = approverShortName === prognosisData?.backupApprover;

  const isApproverRole = userAccessForField?.userRoles?.approver;
  const isSubmitted = prognosisData?.statusId === StatusIdEnum.SUBMITTED;

  // Show approver actions if the user is an assigned approver or backup approver
  return (
    isAssignedApprover && (isApproverRole || isBackupApprover) && isSubmitted
  );
}
