import { FC } from 'react';

import { Typography } from '@equinor/amplify-component-lib';
import { Dialog } from '@equinor/amplify-component-lib';

import { useSubmitPrognosis } from '../../hooks/useSubmitPrognosis';

interface SubmitDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
}

export const SubmitDialog: FC<SubmitDialogProps> = ({
  open,
  title,
  onClose,
}) => {
  const { mutateAsync: postSubmittedPrognosis, isPending: isSaving } =
    useSubmitPrognosis();

  const handleOnSubmit = async () => {
    await postSubmittedPrognosis();
    onClose();
  };

  if (!open) return null;

  return (
    <Dialog
      title={`Confirm submit prognosis - ${title}`}
      open={open}
      onClose={onClose}
      width={500}
      withContentPadding={{ vertical: true, horizontal: true }}
      withBorders
      actions={[
        {
          position: 'right',
          onClick: onClose,
          text: 'Cancel',
          variant: 'outlined',
        },
        {
          position: 'right',
          onClick: handleOnSubmit,
          text: 'Submit',
          color: 'primary',
          disabled: isSaving,
          isLoading: isSaving,
        },
      ]}
    >
      <Typography variant="body_short">
        This action will notify the approver you selected and will be available
        for review until an action is taken.
      </Typography>
    </Dialog>
  );
};
