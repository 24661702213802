import { FC } from 'react';

import { spacings, Typography } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  padding: ${spacings.small} 0px 0px ${spacings.small};
`;

interface TableHeaderTitleProps {
  label: string;
}

export const TableHeaderTitle: FC<TableHeaderTitleProps> = ({ label }) => {
  return (
    <Container data-testid={`table-header-${label}`}>
      <Typography group="paragraph" variant="body_short">
        {label}
      </Typography>
    </Container>
  );
};
