import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog, Typography } from '@equinor/amplify-component-lib';
import {
  ColumnMeta,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { EditGroup } from 'components/Table/EditGroup/EditGroup';
import { InfoWrapper } from 'components/Table/InfoWrapper/InfoWrapper';

import { PinnedRow } from './PinnedRow/PinnedRow';
import {
  CheckboxGroup,
  Footer,
  Header,
  StyledCheckbox,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  Wrapper,
} from './Table.styles';
import { PrognosisDetailDto } from 'src/api';
import { defaultColumns, fallbackData } from 'src/constants/table';
import { DataInputDialog } from 'src/pages/EditPrognosis/components/FormData/DataInputDialog/DataInputDialog';
import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';
import { useOptionalAttributes } from 'src/pages/EditPrognosis/hooks/useOptionalAttributes';
import { StatusIdEnum, Unit } from 'src/types';
import { EditPrognosisFormValues } from 'src/utils';
import { ConvertPrognosisToSG } from 'src/utils/unitConversion';

import { useApp } from 'hooks/useApp';
import { useGetWellboreByIdentifier } from 'hooks/useGetWellbores';
import { usePrognosisDetailsFromId } from 'hooks/usePrognosisDetailsFromId';

export interface CustomColumnMeta
  extends ColumnMeta<PrognosisDetailDto, unknown> {
  headerProps?: React.HTMLAttributes<HTMLTableCellElement>;
  cellProps?: React.HTMLAttributes<HTMLTableCellElement>;
}

interface TableProps {
  isEditing?: boolean;
  statusId?: StatusIdEnum;
}

export const Table: FC<TableProps> = ({ isEditing = true, statusId }) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext<EditPrognosisFormValues>();
  const formPrognosisDetails = watch('prognosisDetails');
  const { selectedRows, setSelectedRows, hasUpdatedCell } = useEditPrognosis();
  const { optionalAttributes } = useOptionalAttributes();
  const { data: apiPrognosisDetails } = usePrognosisDetailsFromId();
  const { data: wellbore } = useGetWellboreByIdentifier();
  const { unit } = useApp();

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);
  const [gradientsChecked, setGradientsChecked] = useState(true);
  const [referenceChecked, setReferenceChecked] = useState(true);
  const [datumChecked, setDatumChecked] = useState(true);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [detailComment, setDetailComment] = useState<
    string | null | undefined
  >();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [prognosisDetailSnapshot, setPrognosisDetailSnapshot] =
    useState<PrognosisDetailDto>();

  const apiPrognosisDetailCopy = useMemo(() => {
    return apiPrognosisDetails?.map((prognosis) => {
      if (unit === Unit.SG && wellbore) {
        return ConvertPrognosisToSG({
          prognosis,
          depthReferenceElevation: wellbore?.depthReferenceElevation || 0,
        });
      }
      return prognosis;
    });
  }, [unit, apiPrognosisDetails, wellbore]);

  const details = isEditing ? formPrognosisDetails : apiPrognosisDetailCopy;

  const totalDepthRowId = useMemo(() => {
    return formPrognosisDetails
      .find((detail) => detail.reservoirZone === 'Total depth')
      ?.id.toString();
  }, [formPrognosisDetails]);

  const table = useReactTable({
    data: details ?? fallbackData,
    columns,
    initialState: {
      sorting: [
        {
          id: 'referenceDepth',
          desc: false,
        },
      ],
    },
    state: {
      rowSelection,
      columnVisibility: {
        ...columnVisibility,
        actions: isEditing,
        pressureInitial: isEditing
          ? optionalAttributes.initialPressure
          : referenceChecked,
        // TODO: Add more columns here based on optional attributes
        datumDepth: isEditing ? optionalAttributes.datumDepth : datumChecked,
        pressureMinHistorical: isEditing
          ? optionalAttributes.minimumPressure
          : referenceChecked,
        pressureMax: isEditing
          ? optionalAttributes.maximumPressure
          : referenceChecked,
        pressureDatumExpected: isEditing
          ? optionalAttributes.datumDepth
          : datumChecked,
        pressureDatumLow: isEditing
          ? optionalAttributes.datumDepth
          : datumChecked,
        pressureDatumHigh: isEditing
          ? optionalAttributes.datumDepth
          : datumChecked,
        pressureGradientToDatumDepth: isEditing
          ? optionalAttributes.datumDepth
          : datumChecked,
      },
      rowPinning: {
        bottom: totalDepthRowId ? [totalDepthRowId] : [],
      },
    },
    meta: {
      handleOnOpenComment: (detailComment: string | null | undefined) => {
        setOpenCommentModal(true);
        setDetailComment(detailComment);
      },
      handleOnOpenEditDialog: (detailRow: PrognosisDetailDto) => {
        setPrognosisDetailSnapshot(detailRow);
        setOpenEditModal(true);
      },
      isStatusSubmitted: statusId === StatusIdEnum.SUBMITTED,
    },
    enableRowSelection: true,
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    enableMultiRowSelection: isEditing,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id.toString(),
    enableRowPinning: true,
  });

  const hasErrors =
    errors.prognosisDetails && Object.keys(errors.prognosisDetails).length > 0;

  const toggleColumnGroup = useCallback(
    (groupIds: (keyof PrognosisDetailDto)[], visibility: boolean) => {
      groupIds.forEach((id) => {
        const column = table.getColumn(id);
        if (column) {
          column.toggleVisibility(visibility);
        }
      });
    },
    [table]
  );

  const handleOnCloseComment = () => {
    setOpenCommentModal(false);
  };

  const handleOnCloseEditDialog = () => {
    setOpenEditModal(false);
  };

  const handleDatumCheckboxChange = () => {
    setDatumChecked(!datumChecked);
    toggleColumnGroup(
      [
        'datumDepth',
        'pressureDatumExpected',
        'pressureDatumLow',
        'pressureDatumHigh',
        'pressureGradientToDatumDepth',
      ],
      !datumChecked
    );
  };

  const handleGradientCheckboxChange = () => {
    setGradientsChecked(!gradientsChecked);
    toggleColumnGroup(
      ['fluidType', 'pressureGradientDepth'],
      !gradientsChecked
    );
  };

  const handleReferenceChecked = () => {
    setReferenceChecked(!referenceChecked);
    toggleColumnGroup(
      [
        'referenceDepth',
        'pressureInitial',
        'pressureExpected',
        'pressureLow',
        'pressureHigh',
      ],
      !referenceChecked
    );
  };

  // Update selected rows context when rowSelection changes
  useEffect(() => {
    const updatedSelection = table.getState().rowSelection;
    const selectedIds = Object.keys(updatedSelection)
      .filter((id) => updatedSelection[id])
      .map(Number);

    setSelectedRows(selectedIds);
  }, [rowSelection, setSelectedRows, table]);

  return (
    <Wrapper>
      <Header>
        <Typography variant="h4">
          {isEditing ? 'Fill in the prognosis data' : 'Details'}
        </Typography>
        {isEditing ? (
          <EditGroup
            selectedRows={selectedRows}
            resetRowSelection={table.resetRowSelection}
          />
        ) : (
          <CheckboxGroup>
            <StyledCheckbox
              label="Reference"
              checked={referenceChecked}
              onChange={handleReferenceChecked}
            />
            <StyledCheckbox
              label="Gradients"
              checked={gradientsChecked}
              onChange={handleGradientCheckboxChange}
            />
            <StyledCheckbox
              label="Datum"
              checked={datumChecked}
              onChange={handleDatumCheckboxChange}
            />
          </CheckboxGroup>
        )}
      </Header>
      <TableContainer>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  key={header.id}
                  colSpan={header.colSpan}
                  {...(header.column.columnDef.meta as CustomColumnMeta)
                    ?.headerProps}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getCenterRows().map((row, index) => {
            const isLastRow = index === table.getRowModel().rows.length - 1;
            const isSelected = row.getIsSelected();
            return (
              <TableRow key={row.id} onClick={row.getToggleSelectedHandler()}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    $isLastRow={isLastRow}
                    $isSelected={isSelected}
                    {...(cell.column.columnDef.meta as CustomColumnMeta)
                      ?.cellProps}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
          {table.getBottomRows().map((row) => (
            <PinnedRow key={row.id} row={row} />
          ))}
        </tbody>
      </TableContainer>
      {(hasErrors || hasUpdatedCell) && (
        <Footer>
          <InfoWrapper
            hasErrors={hasErrors ?? false}
            hasUpdatedCell={hasUpdatedCell}
          />
        </Footer>
      )}
      {openCommentModal && (
        <Dialog
          title="Detail comment"
          onClose={handleOnCloseComment}
          open={openCommentModal}
          isDismissable
          width={400}
        >
          <Typography>{detailComment}</Typography>
        </Dialog>
      )}
      {openEditModal && prognosisDetailSnapshot && (
        <DataInputDialog
          open={openEditModal}
          close={handleOnCloseEditDialog}
          detailRow={prognosisDetailSnapshot}
        />
      )}
    </Wrapper>
  );
};
