import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  imageToB64,
  useAuth,
  useSnackbar,
} from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useEditPrognosis } from './useEditPrognosis';
import { usePostBlob } from './usePostBlob';
import { PrognosisDto, PrognosisService, PrognosisUpdateDto } from 'src/api';
import { GET_IMAGE, PROGNOSIS_KEY } from 'src/constants';
import { StatusIdEnum } from 'src/types';
import {
  checkIfImageExists,
  EditPrognosisFormValues,
  ImageType,
  OperationValues,
  parseDate,
  prepareComment,
} from 'src/utils';

import { usePrognosisFromId } from 'hooks/usePrognosisFromId';

export const usePutDraftPrognosis = () => {
  const { reset, watch } = useFormContext<EditPrognosisFormValues>();
  const { showSnackbar } = useSnackbar();
  const { mutateAsync: uploadImage } = usePostBlob();

  const queryClient = useQueryClient();
  const { account } = useAuth();
  const { imageFiles } = useEditPrognosis();
  const { data: existingPrognosis } = usePrognosisFromId();

  const {
    wellbore,
    title,
    operation,
    mainApprover,
    backupApprover,
    validFrom,
    validTo,
    operationPhase,
    stratColumnIdentifier,
    comment: currentComment,
  } = watch();
  const { prognosisId } = useParams();

  const usingId = Number(prognosisId);
  const username = account?.username;
  const createUserShortName = username ? username.split('@')[0] : '';
  const existingComment = existingPrognosis?.comment ?? '';
  const onCreatePage = prognosisId === undefined;

  const addedImages: ImageType[] = [];

  return useMutation({
    mutationKey: [PROGNOSIS_KEY, prognosisId],
    mutationFn: async () => {
      if (onCreatePage) {
        return;
      }
      // handle checking if image exists in comment, and upload if it does
      for (const image of imageFiles) {
        const b64 = await imageToB64(image);

        const isExisting = checkIfImageExists(currentComment, b64);
        if (isExisting) {
          const message = await uploadImage(image);

          addedImages.push({ b64, url: message.fileName });
        }
      }

      const cleanedComment = await prepareComment(
        existingComment,
        currentComment ?? '',
        addedImages
      );

      // If the operation is new well or intervention, include the operation phase id
      const shouldIncludeOperationPhaseId =
        operation?.value?.toString() === OperationValues.NEW_WELL.toString() ||
        operation?.value?.toString() ===
          OperationValues.INTERVENTION.toString();

      const body: PrognosisUpdateDto = {
        title,
        operationId: Number(operation?.value),
        statusId: StatusIdEnum.DRAFT,
        wellboreIdentifier: wellbore?.label, // Purposefully using the label here
        updateUser: createUserShortName,
        mainApprover: mainApprover?.value,
        backupApprover: backupApprover?.value,
        validFromDate: parseDate(validFrom),
        validToDate: parseDate(validTo),
        wellpathRevision: operation?.label,
        comment: cleanedComment,
        stratColumnIdentifier: stratColumnIdentifier?.value,
        ...(shouldIncludeOperationPhaseId && {
          operationPhaseId: Number(operationPhase?.value),
        }),
      };

      return await PrognosisService.putApiV1Prognoses(usingId, undefined, {
        ...body,
        updateUser: createUserShortName,
      });
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_KEY, prognosisId],
      });

      const cleanedComment = await prepareComment(
        existingComment,
        currentComment ?? '',
        addedImages
      );

      const shouldIncludeOperationPhaseId =
        operation?.value?.toString() === OperationValues.NEW_WELL.toString() ||
        operation?.value?.toString() ===
          OperationValues.INTERVENTION.toString();

      const body: PrognosisUpdateDto = {
        title,
        operationId: Number(operation?.value),
        statusId: StatusIdEnum.DRAFT,
        wellboreIdentifier: wellbore?.label, // Purposefully using the label here
        updateUser: createUserShortName,
        mainApprover: mainApprover?.value,
        backupApprover: backupApprover?.value,
        validFromDate: parseDate(validFrom),
        validToDate: parseDate(validTo),
        wellpathRevision: operation?.label,
        comment: cleanedComment,
        stratColumnIdentifier: stratColumnIdentifier?.value,
        ...(shouldIncludeOperationPhaseId && {
          operationPhaseId: Number(operationPhase?.value),
        }),
      };

      const previousPrognosis: PrognosisDto | undefined =
        queryClient.getQueryData([PROGNOSIS_KEY, prognosisId]);

      queryClient.setQueryData([PROGNOSIS_KEY, prognosisId], {
        ...previousPrognosis,
        ...body,
      } as PrognosisDto);

      return { previousPrognosis };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_KEY, prognosisId],
        context?.previousPrognosis ?? []
      );
      showSnackbar('Failed to update prognosis.');
      reset();
    },
    onSuccess: () => {
      const currentFormValues = watch();
      reset(currentFormValues, { keepErrors: true });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_KEY, prognosisId],
      });
      // fetches images from blob storage
      queryClient.invalidateQueries({
        queryKey: [GET_IMAGE, prognosisId],
      });
    },
  });
};
