import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';

import { MapViewState } from '@deck.gl/core';
import { useLocalStorage } from '@equinor/amplify-component-lib';

import { usePressurePoints } from '../hooks';
import { LegendRange, MapBackground, MapLayer } from '../MapView.types';
import { PressureDataSimple } from 'src/api';
import { MAP_LAYERS_STORAGE_KEY } from 'src/constants';
import { useMeasuredData } from 'src/hooks';
import { DragPoint } from 'src/pages/MapView/components/Map/hooks/useDeckGlMapProps/useMeasureArea.types';
import { MapTool } from 'src/pages/MapView/components/MapSettings/MapTools';
import { useMapViewState } from 'src/pages/MapView/hooks/useMapViewState';
import { filterPressurePoints } from 'src/utils';

export interface MapOptionsState {
  layers: MapLayer[];
  toggleLayer: (layer: MapLayer) => void;
  backgroundLayer: MapBackground | undefined;
  toggleBackgroundLayer: (layer: MapBackground) => void;
  legendRange: LegendRange;
  setLegendRange: Dispatch<SetStateAction<LegendRange>>;
  isSelectedWellboresOpen: boolean;
  setIsSelectedWellboresOpen: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  mapTool: MapTool;
  setMapTool: Dispatch<SetStateAction<MapTool>>;
  filteredPressurePoints: PressureDataSimple[];
  selectionAreaStart: MutableRefObject<number[] | undefined>;
  selectionAreaEnd: number[] | undefined;
  setSelectionAreaEnd: Dispatch<SetStateAction<number[] | undefined>>;
  isDraggingMeasureAreaPoint: MutableRefObject<DragPoint | undefined>;
  hoverPoint: number[] | undefined;
  setHoverPoint: Dispatch<SetStateAction<number[] | undefined>>;
  viewState: MapViewState;
  setViewState: Dispatch<SetStateAction<MapViewState>>;
  infoFooterViewState: MapViewState;
  setInfoFooterViewState: Dispatch<SetStateAction<MapViewState>>;
}

export const MapOptionsContext = createContext<MapOptionsState | undefined>(
  undefined
);

export const MapOptionsProvider = ({ children }: { children: ReactNode }) => {
  const [isSelectedWellboresOpen, setIsSelectedWellboresOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [legendRange, setLegendRange] = useState<LegendRange>({
    min: -Infinity,
    max: -Infinity,
  });
  const [viewState, setViewState] = useMapViewState();
  const [infoFooterViewState, setInfoFooterViewState] = useMapViewState({
    debounceMS: 300,
  });
  const [hoverPoint, setHoverPoint] = useState<number[] | undefined>(undefined);
  const selectionAreaStart = useRef<number[] | undefined>(undefined);
  const [selectionAreaEnd, setSelectionAreaEnd] = useState<
    number[] | undefined
  >(undefined);
  const isDraggingMeasureAreaPoint = useRef<DragPoint | undefined>(undefined);

  const { wellbores } = useMeasuredData();
  const { pressurePoints } = usePressurePoints();
  const [backgroundLayer, setBackgroundLayer] = useState<
    MapBackground | undefined
  >(undefined);
  const [mapTool, setMapTool] = useState<MapTool>(MapTool.DEFAULT);

  const [layers, setLayers] = useLocalStorage<MapLayer[]>(
    MAP_LAYERS_STORAGE_KEY,
    [
      MapLayer.FIELD_OUTLINE,
      MapLayer.WELLBORE_PATHS,
      MapLayer.PRESSURE_VALUE,
      MapLayer.WELLBORE_LABEL,
      MapLayer.INJECTORS,
      MapLayer.PRODUCERS,
      MapLayer.NON_FLOWING_WELLS,
      MapLayer.FLOWING_WELLS,
    ]
  );

  const filteredPressurePoints = useMemo(
    () => filterPressurePoints(pressurePoints, wellbores, layers),
    [layers, pressurePoints, wellbores]
  );

  const toggleLayer = (layer: MapLayer) => {
    if (layers.includes(layer)) {
      setLayers(layers.filter((prevLayer) => prevLayer !== layer));
    } else {
      setLayers([...layers, layer]);
    }
  };

  const toggleBackgroundLayer = (layer: MapBackground) => {
    if (backgroundLayer === layer) {
      setBackgroundLayer(undefined);
    } else {
      setBackgroundLayer(layer);
    }
  };

  if (!viewState) return null;

  return (
    <MapOptionsContext.Provider
      value={{
        layers,
        toggleLayer,
        backgroundLayer,
        toggleBackgroundLayer,
        legendRange,
        setLegendRange,
        isSelectedWellboresOpen,
        setIsSelectedWellboresOpen,
        isLoading,
        setIsLoading,
        mapTool,
        setMapTool,
        filteredPressurePoints,
        selectionAreaStart,
        selectionAreaEnd,
        setSelectionAreaEnd,
        isDraggingMeasureAreaPoint,
        hoverPoint,
        setHoverPoint,
        viewState,
        setViewState,
        infoFooterViewState,
        setInfoFooterViewState,
      }}
    >
      {children}
    </MapOptionsContext.Provider>
  );
};
