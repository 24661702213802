import { faker } from '@faker-js/faker';

import { PrognosisDetailDto, PrognosisDetailReservoirZoneDto } from 'src/api';

export interface ChartProps {
  width: number;
  height: number;
  data: PrognosisDetailReservoirZoneDto[] | PrognosisDetailDto[];
  toggleXY?: boolean;
}

export enum ChartDataPointCategory {
  EXPECTED = 'Expected',
  LOW_HIGH = 'Range',
  INITIAL = 'Initial',
}

export enum ChartDataCategory {
  REFERENCE = 'Reference',
  REFERENCE_ID = faker.number.int(),
  DATUM = 'Datum',
  DATUM_ID = faker.number.int(),
}

export interface ChartSelect {
  name: string;
  id: number;
}
