import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog, spacings, Typography } from '@equinor/amplify-component-lib';

import { General } from './General/General';
import { Gradients } from './Gradients/Gradients';
import { TextFields } from './TextFields/TextFields';
import { datumKeys, referenceKeys } from './DataInputDialog.utils';
import { PrognosisDetailDto } from 'src/api';
import { EMPTY_DETAIL } from 'src/constants';
import { useOptionalAttributes } from 'src/pages/EditPrognosis/hooks/useOptionalAttributes';
import { usePutDetail } from 'src/pages/EditPrognosis/hooks/usePutDetail';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.x_large};
  padding: ${spacings.medium};
  overflow-y: auto;
`;

interface DataInputDialogProps {
  open: boolean;
  close: () => void;
  detailRow: PrognosisDetailDto;
}

export const DataInputDialog: FC<DataInputDialogProps> = ({
  open,
  close,
  detailRow,
}) => {
  const {
    watch,
    formState: { errors, isDirty },
    resetField,
    clearErrors,
    setValue,
  } = useFormContext<EditPrognosisFormValues>();
  const { mutate: updateDetail } = usePutDetail();
  const { prognosisDetails: formPrognosisDetails } = watch();
  const { optionalAttributes } = useOptionalAttributes();

  const [detail, index] = useMemo(() => {
    const foundIndex = formPrognosisDetails.findIndex(
      (d) => d.id === detailRow.id
    );
    const foundDetail = formPrognosisDetails[foundIndex];
    return [foundDetail, foundIndex];
  }, [detailRow.id, formPrognosisDetails]);

  const isTotalDepthRow = detail?.reservoirZone === 'Total depth';

  const handleAddData = () => {
    const detailErrors = errors.prognosisDetails?.[index];
    const hasDetailErrors =
      detailErrors && Object.keys(detailErrors).length > 0;
    if (isDirty && !hasDetailErrors && detail) {
      updateDetail(detail);
      close();
    }
  };

  const handleOnCancel = () => {
    resetField(`prognosisDetails.${index}`, {
      defaultValue: detailRow,
    });
    close();
  };

  const handleOnClear = () => {
    const clearedDetail = {
      ...detailRow,
      ...EMPTY_DETAIL,
    } as PrognosisDetailDto;
    setValue(
      `prognosisDetails.${index}`,
      { ...clearedDetail },
      { shouldDirty: true }
    );
    clearErrors(`prognosisDetails.${index}`);
  };

  return (
    <Dialog
      actions={[
        {
          color: 'danger',
          onClick: handleOnClear,
          text: 'Clear values',
          variant: 'ghost',
          position: 'left',
        },
        {
          color: 'primary',
          onClick: handleOnCancel,
          text: 'Cancel',
          variant: 'outlined',
        },
        {
          color: 'primary',
          onClick: handleAddData,
          text: 'Save data',
          variant: 'contained',
        },
      ]}
      title={
        <>
          <Typography variant="h6">
            Fill data for {detail?.lithostratSubzone}
          </Typography>
        </>
      }
      width={840}
      withContentPadding={{ vertical: true, horizontal: true }}
      onClose={handleOnCancel}
      open={open}
      withBorders
    >
      <Container>
        <TextFields
          label="Reference"
          index={index}
          keys={referenceKeys}
          isTotalDepthRow={isTotalDepthRow}
        />
        {optionalAttributes.datumDepth && (
          <TextFields label="Datum" index={index} keys={datumKeys} />
        )}
        <Gradients label="Gradients" index={index} />
        {!isTotalDepthRow && <General label="General" index={index} />}
      </Container>
    </Dialog>
  );
};
