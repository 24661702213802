import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Chip, OptionDrawer, Search } from '@equinor/amplify-component-lib';
import { ToggleEventProps } from '@equinor/amplify-component-lib/dist/molecules/OptionDrawer/OptionDrawer';

import {
  ChipContainer,
  Container,
  OptionsContainer,
} from './ZoneNavigator.styles';
import { PrognosisDetailDto, StratigraphicUnit, Stratigraphy } from 'src/api';
import { EditPrognosisFormValues, flattenOptions } from 'src/utils';

interface ZoneNavigatorProps {
  column: string;
  data: Stratigraphy[];
  handleAddPrognosisDetails: (subzones: MappedStratigraphicUnit[]) => void;
  values: Partial<PrognosisDetailDto>[];
}

export interface MappedStratigraphicUnit extends StratigraphicUnit {
  id: string;
  label: string;
  value: string;
  children?: MappedStratigraphicUnit[];
  disabled?: boolean;
}

export const ZoneNavigator: FC<ZoneNavigatorProps> = ({
  column,
  data,
  handleAddPrognosisDetails,
}) => {
  const { watch } = useFormContext<EditPrognosisFormValues>();
  const { stratUnits } =
    data?.find(
      ({ stratColumnIdentifier }) => stratColumnIdentifier === column
    ) ?? {};
  const { prognosisDetails } = watch();
  const [searchValue, setSearchValue] = useState('');

  const [selectedItems, setSelectedItems] = useState<MappedStratigraphicUnit[]>(
    prognosisDetails.map((detail) => ({
      id: detail.lithostratSubzone || '',
      label: detail.lithostratSubzone || '',
      value: detail.lithostratSubzone || '',
      stratigraphicUnits: [],
    }))
  );

  const mapStratigraphicUnit = useCallback(
    (unit: StratigraphicUnit): MappedStratigraphicUnit => {
      const children = unit.stratigraphicUnits?.map(mapStratigraphicUnit) || [];
      return {
        id: unit.stratUnitIdentifier || '',
        label: unit.stratUnitIdentifier || '',
        value: unit.stratUnitIdentifier || '',
        disabled: prognosisDetails.some(
          (detail) => detail.lithostratSubzone === unit.stratUnitIdentifier
        ),
        ...unit,
        ...(children.length > 0 && { children }),
      };
    },
    [prognosisDetails]
  );

  const processIntermediateUnits = useCallback(() => {
    return stratUnits?.map(mapStratigraphicUnit) ?? [];
  }, [stratUnits, mapStratigraphicUnit]);

  const handleOnToggle = (event: ToggleEventProps<MappedStratigraphicUnit>) => {
    const item = event.items[0];

    const newSelectedItems = selectedItems.some(
      (selectedItem) => selectedItem.value === item.value
    )
      ? selectedItems.filter(
          (selectedItem) => selectedItem.value !== item.value
        )
      : [...selectedItems, item];

    setSelectedItems(newSelectedItems);

    const filteredItems = newSelectedItems.filter(
      (newItem) =>
        !prognosisDetails.some(
          (detail) => detail.lithostratSubzone === newItem.value
        )
    );

    handleAddPrognosisDetails(filteredItems);
  };

  const filterUnits = useMemo(() => {
    if (searchValue === '') {
      return processIntermediateUnits();
    }

    const flatOptions = flattenOptions(processIntermediateUnits());
    return flatOptions.filter((option) => {
      const searchTerms = searchValue.toLowerCase().split(' ');
      return searchTerms.every((term) =>
        option.label.toLowerCase().includes(term)
      );
    });
  }, [searchValue, processIntermediateUnits]);

  const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <Container>
      <ChipContainer>
        {selectedItems.map((item) => {
          const isDetail = prognosisDetails.some(
            (detail) => detail.lithostratSubzone === item.value
          );

          if (isDetail) {
            return <Chip key={item.value}>{item.value}</Chip>;
          }

          return (
            <Chip
              key={item.value}
              onDelete={() => {
                const newSelectedItems = selectedItems.filter(
                  (selectedItem) => selectedItem.value !== item.value
                );
                setSelectedItems(newSelectedItems);
                handleAddPrognosisDetails(newSelectedItems);
              }}
            >
              {item.value}
            </Chip>
          );
        })}
      </ChipContainer>

      <Search
        id="subzone-search"
        onChange={handleOnSearch}
        placeholder="Search for subzones"
      />
      <OptionsContainer>
        {filterUnits.map((unit) => {
          return (
            <OptionDrawer
              key={unit.stratUnitIdentifier}
              item={unit}
              onToggle={handleOnToggle}
              parentHasNestedItems
              singleSelect
              selectedItems={selectedItems}
              showIntermediateParent
            />
          );
        })}
      </OptionsContainer>
    </Container>
  );
};
