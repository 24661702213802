import { FC, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  Button,
  colors,
  Icon,
  Menu,
  Typography,
} from '@equinor/amplify-component-lib';
import { delete_forever, more_vertical } from '@equinor/eds-icons';

import { StatusChip } from 'components/BaseChip/StatusChip/StatusChip';
import { DeleteDialog } from 'components/DeleteDialog/DeleteDialog';

import { useEditPrognosis } from '../../hooks/useEditPrognosis';
import { Container, LeftGroup, TitleSkeleton } from './Title.styles';
import { usePrognosisFromId } from 'src/hooks';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

export const Title: FC = () => {
  const { watch } = useFormContext<EditPrognosisFormValues>();
  const { isInitializing } = useEditPrognosis();
  const { data } = usePrognosisFromId();
  const { prognosisId } = useParams();

  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const { title } = watch();
  const onCreatePage = prognosisId === undefined;

  const handleOnClick = () => setOpen((prev) => !prev);
  const handleOnClose = () => setOpen(false);

  const handleOnOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleOnCloseDeleteDialog = () => setOpenDeleteDialog(false);

  if (onCreatePage) {
    return (
      <Container>
        <Typography variant="h1" bold>
          Create new prognosis
        </Typography>
      </Container>
    );
  }

  if (isInitializing) {
    return (
      <Container>
        <TitleSkeleton />
      </Container>
    );
  }

  return (
    <>
      <Container>
        <LeftGroup>
          <Typography variant="h1" bold>
            {title}
          </Typography>
          {data && <StatusChip statusId={data?.statusId} />}
        </LeftGroup>
        <>
          <Button
            ref={anchorRef}
            variant="ghost_icon"
            onClick={handleOnClick}
            aria-label="Options"
          >
            <Icon data={more_vertical} />
          </Button>
          {open && (
            <Menu
              open
              anchorEl={anchorRef.current}
              placement="bottom-start"
              onClose={handleOnClose}
            >
              <Menu.Section>
                <Menu.Item onClick={handleOnOpenDeleteDialog}>
                  <Icon
                    data={delete_forever}
                    color={colors.interactive.danger__resting.rgba}
                  />
                  <Typography color={colors.interactive.danger__text.rgba}>
                    Delete this prognosis
                  </Typography>
                </Menu.Item>
              </Menu.Section>
            </Menu>
          )}
        </>
      </Container>
      <DeleteDialog
        open={openDeleteDialog}
        title={title}
        onClose={handleOnCloseDeleteDialog}
      />
    </>
  );
};
