import { FC, useMemo } from 'react';

import { colors } from '@equinor/amplify-component-lib';
import { scaleBand, scaleLinear } from '@visx/scale';
import {
  AnimatedAxis,
  AnimatedGlyphSeries,
  AnimatedGrid,
  AnimatedLineSeries,
  DataContext,
  XYChart,
} from '@visx/xychart';

import { RectGlyph } from 'components/Charts/XYChart/Glyphs/RectGlyph';
import { Legend } from 'components/Charts/XYChart/Legend/Legend';
import { XyTooltip } from 'components/Charts/XYChart/Tooltip/XyTooltip';
import { getPressureDomain } from 'components/Charts/XYChart/Utils/Chart.utils';

import { PrognosisDetailReservoirZoneDto } from 'src/api/models/PrognosisDetailReservoirZoneDto';
import { formatTick } from 'src/utils';

import { useApp } from 'hooks/useApp';

interface ChartProps {
  width: number;
  height: number;
  data: PrognosisDetailReservoirZoneDto[];
  selectedZone: number;
}

export const Chart: FC<ChartProps> = ({
  data,
  width,
  height,
  selectedZone,
}) => {
  const { unit } = useApp();
  const graphColors = data
    .map((d) => d.colorHtml)
    .filter((color): color is string => color !== undefined);

  // Filter data based on date is null
  const filteredData = data.filter((d) => d.approveDate !== null);

  const XScale = useMemo(() => {
    return scaleBand({
      range: [0, width],
      domain: filteredData.map((d) => {
        return d.approveDate!;
      }),
    });
  }, [filteredData, width]);

  const YScale = useMemo(() => {
    return scaleLinear({
      range: [height, 0],
      domain: getPressureDomain(filteredData),
      clamp: true,
      zero: false,
    });
  }, [filteredData, height]);

  return (
    <DataContext.Provider
      value={{
        xScale: XScale,
        yScale: YScale,
      }}
    >
      <XYChart
        height={height}
        width={width}
        xScale={{
          type: 'band',
          domain: filteredData.map((d) => d.approveDate),
        }}
        yScale={{
          type: 'linear',
          domain: getPressureDomain(filteredData),
          zero: false,
          clamp: true,
        }}
        margin={{ top: 0, right: 0, bottom: 60, left: 60 }}
      >
        <AnimatedGrid
          columns={false}
          numTicks={5}
          stroke={colors.ui.background__medium.hex}
        />

        <AnimatedAxis
          orientation="bottom"
          label="Date"
          tickFormat={formatTick}
          stroke={colors.ui.background__medium.hex}
          tickStroke={colors.ui.background__medium.hex}
        />
        <AnimatedAxis
          orientation="left"
          hideTicks
          hideAxisLine
          label={`Pressure (${unit})`}
          numTicks={5}
          labelOffset={28}
        />

        <AnimatedGlyphSeries
          dataKey="highlow"
          data={filteredData}
          xAccessor={(d) => d.approveDate}
          yAccessor={(d) => d.pressureHigh}
          renderGlyph={(glyphProps) => {
            const isSelected = selectedZone === glyphProps.datum.id;
            return (
              <RectGlyph
                datum={glyphProps.datum}
                x={glyphProps.x - (isSelected ? 6 : 3)}
                y={glyphProps.y}
                color={glyphProps.datum.colorHtml!}
                toggleXY
                selectedZone={selectedZone}
              />
            );
          }}
        />

        <AnimatedLineSeries
          strokeDasharray={'4,4'}
          dataKey="expected"
          data={filteredData}
          xAccessor={(d) => d.approveDate}
          yAccessor={(d) => d.pressureExpected}
          colorAccessor={() => colors.ui.background__scrim.hex}
        />

        <AnimatedLineSeries
          dataKey="initial"
          data={filteredData}
          xAccessor={(d) => d.approveDate}
          yAccessor={(d) => d.pressureInitial}
          stroke={colors.ui.background__scrim.hex}
          strokeOpacity={0.4}
        />

        <AnimatedGlyphSeries
          dataKey="expected"
          data={filteredData}
          xAccessor={(d) => d.approveDate}
          yAccessor={(d) => d.pressureExpected}
          renderGlyph={(glyphProps) => {
            const isSelected = selectedZone === glyphProps.datum.id;
            return (
              <g>
                <circle
                  cx={glyphProps.x}
                  cy={glyphProps.y}
                  r={isSelected ? 7 : 5}
                  fill="black"
                  opacity="0.4"
                />
                <circle
                  cx={glyphProps.x}
                  cy={glyphProps.y}
                  r={isSelected ? 3 : 2}
                  stroke="white"
                  fill="white"
                />
              </g>
            );
          }}
        />

        <AnimatedGlyphSeries
          dataKey="initial"
          data={filteredData}
          xAccessor={(d) => d.approveDate}
          yAccessor={(d) => d.pressureInitial}
          size={10}
          renderGlyph={({ x, y }) => (
            <g>
              <circle
                cx={x}
                cy={y}
                r={3}
                fill={colors.text.static_icons__default.hex}
                strokeOpacity={0.4}
                strokeWidth={4}
              />
            </g>
          )}
        />
        <XyTooltip useDateAsTitle />
      </XYChart>
      <Legend graphColors={graphColors} />
    </DataContext.Provider>
  );
};
