import { FC, useRef, useState } from 'react';

import {
  colors,
  Icon,
  Menu,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import {
  arrow_drop_down,
  arrow_drop_up,
  help_outline,
} from '@equinor/eds-icons';

import { PressureTypeChip } from 'components/PressureTypeChip/PressureTypeChip';

import { PRESSURE_TYPES_IN_ORDER } from 'src/constants';
import { StyledButton } from 'src/pages/MapView/components/Footer/Footer.styles';
import { getGuidelinesTextForPressureType } from 'src/pages/MapView/components/Footer/Footer.utils';

import styled from 'styled-components';

const Container = styled.div`
  margin-top: ${spacings.medium};
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  padding: 0 ${spacings.medium};
  width: 300px;
`;

const Divider = styled.div`
  border-bottom: 0.5px solid #dcdcdc;
  margin: 12px 0;
`;

const PressureTypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
`;

const ChipAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.x_small};
  > p {
    padding-left: ${spacings.small};
  }
`;

export const GuidelinesMenu: FC = () => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);
  return (
    <>
      <StyledButton
        variant="ghost"
        ref={anchorRef}
        onClick={() => setOpen((o) => !o)}
      >
        <Icon
          data={open ? arrow_drop_down : arrow_drop_up}
          color={colors.text.static_icons__default.rgba}
        />
        <Icon
          data={help_outline}
          color={colors.text.static_icons__default.rgba}
        />
      </StyledButton>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={() => setOpen(false)}
        placement="bottom-start"
      >
        <Container>
          <Typography group="paragraph" variant="overline">
            Pressure types
          </Typography>
          <PressureTypesWrapper>
            {PRESSURE_TYPES_IN_ORDER.map((key, index, array) => {
              return (
                <ChipAndDescriptionWrapper key={key}>
                  <PressureTypeChip type={key} />
                  <Typography>
                    {getGuidelinesTextForPressureType(key)}
                  </Typography>
                  {index < array.length - 1 && <Divider />}
                </ChipAndDescriptionWrapper>
              );
            })}
          </PressureTypesWrapper>
          <Divider />
          <Typography group="navigation" variant="menu_title">
            Map Functionality
          </Typography>
          <ul>
            <li>Scroll to zoom in and out.</li>
            <li>Hold click to drag around.</li>
            <li>Click on well or well group to get more information.</li>
            <li>Use filters to toggle on/off values.</li>
            <li>Use slider to set the date</li>
          </ul>
        </Container>
      </Menu>
    </>
  );
};
