import { FC, useEffect, useMemo, useState } from 'react';

import {
  CircularProgress,
  colors,
  Theme,
  Typography,
} from '@equinor/amplify-component-lib';
import { ParentSize } from '@visx/responsive';

import { Actions } from 'components/Charts/XYChart/Actions/Actions';
import { ChartSelect } from 'components/Charts/XYChart/Utils/Chart.types';

import { Card, ChartContainer, LoadingWrapper } from '../Chart.styles';
import { Chart } from './Chart';
import { PrognosisDetailDto, PrognosisDetailReservoirZoneDto } from 'src/api';
import { useApp } from 'src/hooks';
import { CHART_HEIGHT } from 'src/pages/DetailedView/constants/chart';
import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';
import { Unit } from 'src/types/prognosisDetails';
import { ConvertPrognosisToSG } from 'src/utils/unitConversion';

import { useGetReservoirZoneDetails } from 'hooks/useGetReservoirZoneDetails';
import { useGetWellboreByIdentifier } from 'hooks/useGetWellbores';
import styled from 'styled-components';

const Info = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface TimeChartProps {
  prognosisDetailData: PrognosisDetailDto[] | undefined;
}

export const TimeChart: FC<TimeChartProps> = ({ prognosisDetailData }) => {
  const { selectedRows } = useEditPrognosis();
  const [selectedData, setSelectedData] = useState<ChartSelect | undefined>(
    // Shows the first detail in the table by default
    prognosisDetailData && prognosisDetailData.length > 0
      ? {
          id: prognosisDetailData[0].id,
          name: prognosisDetailData[0].reservoirZone,
        }
      : undefined
  );
  const { data: reservoirZone, isFetching } = useGetReservoirZoneDetails(
    selectedData?.name || ''
  );
  const [cardElement, setCardElement] = useState<HTMLDivElement | null>(null);
  const { data: wellbore } = useGetWellboreByIdentifier();
  const { unit } = useApp();

  const reservoirZoneDetails: PrognosisDetailReservoirZoneDto[] =
    useMemo(() => {
      return (
        reservoirZone?.map((prognosis) => {
          if (unit === Unit.SG && wellbore) {
            return ConvertPrognosisToSG({
              prognosis,
              depthReferenceElevation: wellbore.depthReferenceElevation || 0,
            });
          }
          return prognosis;
        }) || []
      );
    }, [unit, reservoirZone, wellbore]);

  const selectedZoneInDetails = prognosisDetailData?.find(
    (item) => selectedRows && item.id === selectedRows[selectedRows.length - 1]
  );

  useEffect(() => {
    if (selectedZoneInDetails) {
      setSelectedData({
        id: selectedZoneInDetails.id,
        name: selectedZoneInDetails.reservoirZone,
      });
    }
  }, [selectedZoneInDetails]);

  return (
    <Card ref={setCardElement}>
      {isFetching ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <>
          <CardHeader>
            <Typography variant="h4">
              {selectedData
                ? `${selectedData?.name} - Approved over time`
                : 'Approved over time'}
            </Typography>
            <Actions cardElement={cardElement} showToggle={false} />
          </CardHeader>
          <ChartContainer data-theme={Theme.LIGHT}>
            {selectedData ? (
              <ParentSize ignoreDimensions="height">
                {({ width }) => {
                  if (reservoirZoneDetails.length > 2) {
                    return (
                      <Chart
                        width={width}
                        height={CHART_HEIGHT}
                        data={reservoirZoneDetails}
                        selectedZone={selectedZoneInDetails?.id ?? 0}
                      />
                    );
                  }

                  return (
                    <Info
                      group="table"
                      variant="cell_text"
                      color={colors.text.static_icons__default.rgba}
                    >
                      No data available for this zone
                    </Info>
                  );
                }}
              </ParentSize>
            ) : (
              <Info
                group="table"
                variant="cell_text"
                color={colors.text.static_icons__default.rgba}
              >
                Select a zone to display data, either in the select above or in
                the table below
              </Info>
            )}
          </ChartContainer>
        </>
      )}
    </Card>
  );
};
