import { FC } from 'react';

import { CircularProgress, Typography } from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';

import { Table } from 'components/Table/Table';

import { Breadcrumbs } from '../EditPrognosis/components/Breadcrumbs/Breadcrumbs';
import { EditPrognosisProvider } from '../EditPrognosis/providers/EditPrognosisProvider';
import { Actions } from './components/Actions/Actions';
import { PrognosisChart } from './components/Chart/PrognosisChart/PrognosisChart';
import { TimeChart } from './components/Chart/TimeChart/TimeChart';
import { HistoryCard } from './components/HistoryCard/HistoryCard';
import { MainAssumptionsCard } from './components/MainAssumptionsCard/MainAssumptionsCard';
import { PrognosisInfo } from './components/PrognosisInfo/PrognosisInfo';
import {
  CardContainer,
  Container,
  Content,
  Header,
  LoadingContainer,
  MainContent,
  Wrapper,
} from './DetailedView.styles';
import { TIME_CHART_FEATURE_KEY } from 'src/constants';
import { StatusIdEnum } from 'src/types';

import { usePrognosisDetailsFromId } from 'hooks/usePrognosisDetailsFromId';
import { usePrognosisFromId } from 'hooks/usePrognosisFromId';

interface DetailedViewProps {
  showPreviewChip?: boolean;
}

export const DetailedView: FC<DetailedViewProps> = ({
  showPreviewChip = false,
}) => {
  const { data } = usePrognosisFromId();
  const { data: prognosisDetailData, isFetching } = usePrognosisDetailsFromId();
  const { showContent } = useFeatureToggling({
    featureKey: TIME_CHART_FEATURE_KEY,
  });

  const isApproved = data?.statusId === StatusIdEnum.APPROVED;

  return (
    <EditPrognosisProvider>
      <Container>
        <MainContent>
          <Breadcrumbs />
          <Header>
            <Typography variant="h1_bold">{data?.title}</Typography>
            <Actions showPreviewChip={showPreviewChip} />
          </Header>
          <Content>
            <PrognosisInfo />
            {isFetching ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              <PrognosisChart prognosisDetailData={prognosisDetailData ?? []} />
            )}
            {showContent &&
              (isFetching ? (
                <LoadingContainer>
                  <CircularProgress />
                </LoadingContainer>
              ) : (
                <TimeChart prognosisDetailData={prognosisDetailData} />
              ))}
            <Wrapper>
              <Table isEditing={false} />
            </Wrapper>
            <CardContainer>
              <MainAssumptionsCard />
            </CardContainer>
            <CardContainer>
              <HistoryCard isApproved={isApproved} />
            </CardContainer>
          </Content>
        </MainContent>
      </Container>
    </EditPrognosisProvider>
  );
};
