import { useQuery } from '@tanstack/react-query';

import { useApp } from './useApp';
import { CancelablePromise, User, UserService } from 'src/api';
import { BACKUP_APPROVER } from 'src/constants/queryKeys';

export const useGetBackupApprovers = () => {
  const { field } = useApp();
  return useQuery<User[]>({
    queryKey: [BACKUP_APPROVER, field?.name],
    queryFn: () =>
      UserService.getApiV1UserApproversBackup(
        field?.name ?? ''
      ) as unknown as CancelablePromise<User[]>,
  });
};
