import { FC, useMemo, useState } from 'react';

import {
  colors,
  shape,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import center from '@turf/center';
import { points } from '@turf/helpers';

import { PressureDataSimple } from 'src/api';
import { useMapInner } from 'src/pages/MapView/components/Map/hooks/useMapInner';
import { ContextMenuArea } from 'src/pages/MapView/components/MapElements/MapMarkersForArea/ContextMenuArea';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { MapLayer } from 'src/pages/MapView/MapView.types';
import { WellborePurpose } from 'src/types';
import { roundTo } from 'src/utils';

import styled from 'styled-components';

interface PurposeMarkerProps {
  $purpose: WellborePurpose;
}

const PurposeMarker = styled.div<PurposeMarkerProps>`
  position: absolute;
  border-radius: ${shape.rounded.borderRadius};
  background-color: ${({ $purpose }) =>
    $purpose === WellborePurpose.PRODUCTION
      ? colors.text.static_icons__default.rgba
      : colors.ui.background__default.rgba};
  z-index: 20;
  padding: ${spacings.small} ${spacings.medium_small};
  ${({ $purpose }) =>
    $purpose && `border: 2px solid ${colors.text.static_icons__default.rgba}`};
  display: flex;
  gap: ${spacings.x_small};
  align-items: center;
`;

interface MapMarkerAreaProps {
  item: { block: string; purpose: WellborePurpose; data: PressureDataSimple[] };
}

export const MapMarkerArea: FC<MapMarkerAreaProps> = ({ item }) => {
  const { viewport } = useMapInner();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const { layers } = useMapOptions();
  const toggleContextMenu = () => {
    setIsContextMenuOpen((prev) => !prev);
  };

  const closeContextMenu = () => {
    setIsContextMenuOpen(false);
  };

  const showPressureLabel = useMemo(() => {
    return layers.includes(MapLayer.PRESSURE_VALUE);
  }, [layers]);

  const [left, top] = useMemo<number[]>(() => {
    const positions = item.data.map((item) => item.measurementPosition);

    const coordinates = positions
      .map((position) => position?.coordinates ?? [])
      .filter((c) => Array.isArray(c) && c.length !== 0);
    if (coordinates.length === 0) return [];
    const centerPosition = center(points(coordinates));
    const [lat, lng] = centerPosition.geometry?.coordinates ?? [0, 0];
    return viewport.project([lng, lat]);
  }, [item.data, viewport]);

  let sum = 0;
  let amountWithMeasurements = 0;
  for (const measurement of item.data) {
    if (measurement.pressureFinal && measurement.pressureFinal > 80) {
      sum += measurement.pressureFinal;
      amountWithMeasurements += 1;
    }
  }
  const average = roundTo(sum / amountWithMeasurements, 2);
  if (!left || !top) return null;
  return (
    <div>
      {showPressureLabel && (
        <PurposeMarker
          style={{
            top: top,
            left: left,
          }}
          onClick={toggleContextMenu}
          $purpose={item.purpose}
        >
          <Typography
            group="navigation"
            variant="label"
            color={
              item.purpose === WellborePurpose.PRODUCTION
                ? colors.ui.background__default.rgba
                : colors.text.static_icons__default.rgba
            }
          >
            AVG
          </Typography>
          <Typography
            variant="body_short"
            color={
              item.purpose === WellborePurpose.PRODUCTION
                ? colors.ui.background__default.rgba
                : colors.text.static_icons__default.rgba
            }
          >
            {!average || isNaN(average) ? -1 : average}
          </Typography>
        </PurposeMarker>
      )}
      <ContextMenuArea
        open={isContextMenuOpen}
        style={{
          top: top - 150,
          left: left - 105,
        }}
        measurements={item.data}
        block={item.block ?? ''}
        type={item.purpose}
        onClose={closeContextMenu}
        average={average?.toString() ?? ''}
      />
    </div>
  );
};
