import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Button,
  colors,
  Icon,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import {
  copy,
  delete_forever,
  info_circle,
  redo,
  undo,
} from '@equinor/eds-icons';
import { useFeatureToggling } from '@equinor/subsurface-app-management';
import { faker } from '@faker-js/faker';
import { useIsMutating } from '@tanstack/react-query';

import { UNDO_REDO_FEATURE_KEY } from 'src/constants';
import { useDeleteDetail } from 'src/pages/EditPrognosis/hooks/useDeleteDetail';
import { usePostDetail } from 'src/pages/EditPrognosis/hooks/usePostDetail';
import { EditPrognosisFormValues } from 'src/utils';

import { motion } from 'framer-motion';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
`;

const Divider = styled.div`
  border-left: 1px solid ${colors.ui.background__medium.rgba};
  align-self: stretch;
  width: 1px;
`;

const AnimateEditButtons = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: ${spacings.small};
`;

const SButton = styled(Button)`
  padding-left: ${spacings.small};
  padding-right: ${spacings.small};
`;

interface EditGroupProps {
  selectedRows: number[];
  resetRowSelection: (defaultState?: boolean) => void;
}

export const EditGroup: FC<EditGroupProps> = ({
  selectedRows,
  resetRowSelection,
}) => {
  const { watch } = useFormContext<EditPrognosisFormValues>();
  const isMutating = useIsMutating();
  const { prognosisDetails } = watch();
  const { mutate: deletePrognosisDetail } = useDeleteDetail();
  const { mutate: addPrognosisDetail } = usePostDetail();
  const { showContent } = useFeatureToggling({
    featureKey: UNDO_REDO_FEATURE_KEY,
  });

  const showButtons: boolean | null = selectedRows && selectedRows?.length > 0;

  const handleOnRemove = () => {
    if (selectedRows) {
      selectedRows.map((id) => {
        deletePrognosisDetail(id);
      });
      resetRowSelection();
    }
  };

  const handleCopy = () => {
    if (isMutating) return;
    if (selectedRows) {
      for (const id of selectedRows) {
        const detail = prognosisDetails.find((detail) => detail.id === id);
        if (detail) {
          // Replace the id before copying
          addPrognosisDetail({
            detail: {
              ...detail,
              id: faker.number.int(),
            },
          });
        }
      }
    }
  };

  return (
    <Container>
      <Button variant={'ghost_icon'} disabled>
        <Icon data={info_circle} />
      </Button>
      <Divider />

      <AnimateEditButtons
        animate={{
          scale: showButtons ? 1 : 0,
          opacity: showButtons ? 1 : 0,
          width: showButtons ? 'auto' : 0,
          transition: {
            duration: 0.25,
          },
        }}
      >
        <Typography variant="caption">Selection</Typography>
        <Button variant="ghost" onClick={handleOnRemove}>
          <Icon data={delete_forever} />
          Delete
        </Button>
        <Button variant="ghost" onClick={handleCopy}>
          <Icon data={copy} />
          Duplicate
        </Button>
        <Divider />
      </AnimateEditButtons>

      {showContent && (
        <>
          <SButton variant="contained" disabled>
            <Icon data={undo} />
          </SButton>
          <SButton variant="contained" disabled>
            <Icon data={redo} />
          </SButton>
        </>
      )}
    </Container>
  );
};
