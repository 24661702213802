import { FC } from 'react';

import { Typography } from '@equinor/amplify-component-lib';
import { Dialog } from '@equinor/amplify-component-lib';

import { useConvertToDraftPrognosis } from 'src/pages/EditPrognosis/hooks/useConvertToDraftPrognosis';

interface EditDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
}

export const EditDialog: FC<EditDialogProps> = ({ open, title, onClose }) => {
  const { isPending: isLoading, mutateAsync: editPrognosis } =
    useConvertToDraftPrognosis();

  const handleEditPrognosis = async () => {
    if (isLoading) return;

    await editPrognosis();
  };

  return (
    <Dialog
      title={`Confirm edit prognosis - ${title}`}
      open={open}
      onClose={onClose}
      width={500}
      withContentPadding={{ vertical: true, horizontal: true }}
      withBorders
      actions={[
        {
          position: 'right',
          onClick: onClose,
          text: 'Cancel',
          variant: 'outlined',
        },
        {
          position: 'right',
          onClick: handleEditPrognosis,
          text: 'Convert to draft and edit',
          color: 'primary',
          disabled: isLoading,
          isLoading: isLoading,
        },
      ]}
    >
      <Typography variant="body_short">
        This action will remove this prognosis from the approver&apos;s list,
        and be returned to your drafts.
      </Typography>
    </Dialog>
  );
};
