import { colors, spacings, Typography } from '@equinor/amplify-component-lib';

import { motion } from 'framer-motion';
import { styled } from 'styled-components';

interface ContainerProps {
  $backgroundColor: string;
  $textColor: string;
  $hoverEnabled: boolean;
  $borderColor?: string;
  disabled?: boolean;
}

export const Container = styled(motion.div)<ContainerProps>`
  display: flex;
  padding: ${spacings.x_small};
  align-items: center;
  border-radius: ${spacings.x_large};
  border: 1px solid ${({ $borderColor }) => $borderColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $textColor }) => $textColor};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  ${({ $hoverEnabled }) =>
    $hoverEnabled &&
    `
    &:hover {
      background-color: ${colors.interactive.primary__hover_alt.rgba};
      border-color: ${colors.interactive.primary__hover.rgba};
      color: ${colors.interactive.primary__hover.rgba};
    }
  `}
`;

export const StyledTypography = styled(Typography)`
  padding: 0px ${spacings.x_small};
  line-height: 16px;
  overflow: hidden;
`;
