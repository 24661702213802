import { FC } from 'react';
import { Path, useFormContext } from 'react-hook-form';

import {
  spacings,
  TextField,
  Typography,
} from '@equinor/amplify-component-lib';

import { TextFieldContainer } from '../DataInputDialog.styles';
import { PrognosisDetailDto } from 'src/api';
import { useOptionalAttributes } from 'src/pages/EditPrognosis/hooks/useOptionalAttributes';
import { getPrognosisDetailError } from 'src/utils/error';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
`;

const WideTextField = styled(TextField)`
  grid-column: span 2;
`;

const FullTextField = styled(TextField)`
  grid-column: span 3;
`;

interface GeneralProps {
  label: string;
  index: number;
}

export const General: FC<GeneralProps> = ({ label, index }) => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useFormContext<EditPrognosisFormValues>();
  const { optionalAttributes } = useOptionalAttributes();

  const segment: Path<EditPrognosisFormValues> = `prognosisDetails.${index}.segment`;
  const comment: Path<EditPrognosisFormValues> = `prognosisDetails.${index}.comment`;
  const nickname: Path<EditPrognosisFormValues> = `prognosisDetails.${index}.reservoirZoneNickname`;

  const handleError = (key: keyof PrognosisDetailDto) => {
    return getPrognosisDetailError(errors, index, key);
  };

  const handleOnChange = (fieldPath: Path<EditPrognosisFormValues>) => {
    trigger(fieldPath);
  };

  return (
    <Container>
      <Typography variant="h6">{label}</Typography>
      <TextFieldContainer>
        <FullTextField
          {...register(nickname, {
            onChange: () => handleOnChange(nickname),
          })}
          id={'Nickname'}
          placeholder="Add nickname"
          label="Nickname"
          data-testid={nickname}
          helperText={handleError('reservoirZoneNickname')?.message}
          variant={handleError('reservoirZoneNickname') ? 'error' : undefined}
        />
        {optionalAttributes.segment && (
          <TextField
            {...register(segment, {
              onChange: () => handleOnChange(segment),
            })}
            id={'Segment'}
            placeholder="0.0"
            label="Segment"
            data-testid={segment}
            helperText={handleError('segment')?.message}
            variant={handleError('segment') ? 'error' : undefined}
            disabled={!optionalAttributes.segment}
          />
        )}
        <WideTextField
          id={'Comment'}
          {...register(comment, {
            onChange: () => handleOnChange(comment),
          })}
          placeholder="Add simple comment"
          label="Comment"
          data-testid={comment}
          helperText={handleError('comment')?.message}
          variant={handleError('comment') ? 'error' : undefined}
        />
      </TextFieldContainer>
    </Container>
  );
};
