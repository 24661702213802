import { FC, useMemo } from 'react';

import { PressureType } from 'src/api';
import {
  Container,
  GradientElement,
  ValueLabel,
} from 'src/pages/MapView/components/Footer/components/InterpolatedMapLegend/InterpolatedMapLegend.styles';
import { usePressurePoints } from 'src/pages/MapView/hooks';
import { INTERPOLATED_MAP_COLOR_DOMAIN } from 'src/pages/MapView/MapView.constants';

export const InterpolatedMapLegend: FC = () => {
  const { pressurePoints } = usePressurePoints();

  const pressureValues = useMemo(() => {
    return pressurePoints
      .filter((point) => point.pressureType !== PressureType.MISSING)
      .map((point) => point.pressureFinal);
  }, [pressurePoints]);

  return (
    <Container>
      <GradientElement
        $colors={INTERPOLATED_MAP_COLOR_DOMAIN.slice(1)} // Slice first color here to remove grey color that is only shown on the outer edge of map and blends into background
      >
        <ValueLabel $position="left" group="input" variant="label">
          {Math.floor(Math.min(...pressureValues))}
        </ValueLabel>
        <ValueLabel $position="right" group="input" variant="label">
          {Math.ceil(Math.max(...pressureValues))}
        </ValueLabel>
      </GradientElement>
    </Container>
  );
};
