import { FC } from 'react';

import { colors, spacings, Typography } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

export const Container = styled.div`
  padding: 0 ${spacings.small};
`;

export const SubHeaderContainer = styled.div`
  padding: ${spacings.small} ${spacings.small} ${spacings.x_small} 0;
  border-bottom: 1px solid ${colors.ui.background__medium.rgba};
`;

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;

interface TableSubHeaderProps {
  label: string;
}

export const TableSubHeader: FC<TableSubHeaderProps> = ({ label }) => {
  return (
    <Container>
      <SubHeaderContainer>
        <StyledTypography
          group="ui"
          variant="tooltip"
          color={colors.text.static_icons__tertiary.rgba}
        >
          {label}
        </StyledTypography>
      </SubHeaderContainer>
    </Container>
  );
};
