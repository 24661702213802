import { FC } from 'react';

import { BaseChip } from 'components/BaseChip/BaseChip';

import { STATUS_VARIANTS } from './StatusChip.types';
import { PrognosisDto } from 'src/api';
import { StatusIdEnum } from 'src/types/status';

interface StatusChipProps {
  statusId: PrognosisDto['statusId'];
}

export const StatusChip: FC<StatusChipProps> = ({ statusId }) => {
  const variant: StatusIdEnum = statusId;
  const {
    backgroundColor: background,
    label,
    icon,
    textColor,
    iconColor,
    borderColor,
  } = STATUS_VARIANTS[variant];

  return (
    <BaseChip
      label={label}
      icon={icon}
      backgroundColor={background}
      textColor={textColor}
      iconColor={iconColor}
      borderColor={borderColor}
      expandible
    />
  );
};
