import { FC } from 'react';

import {
  colors,
  Icon,
  ListSingleSelectProps,
  SelectOptionRequired,
  shape,
  SingleSelect as SingleSelectACL,
  spacings,
  Typography,
} from '@equinor/amplify-component-lib';
import { lock } from '@equinor/eds-icons';

import { styled } from 'styled-components';

const LOCKED_CONTAINER_HEIGHT = 52;

const LockedContainer = styled.div`
  display: flex;
  /* prettier wraps the line below which leads to a formatting error */
  /* prettier-ignore */
  padding: ${spacings.small} ${spacings.small} ${spacings.small} ${spacings.medium_small};
  border: 1px solid ${colors.ui.background__medium.rgba};
  border-radius: ${shape.corners.borderRadius};
  align-items: center;
  width: 100%;
  height: ${LOCKED_CONTAINER_HEIGHT}px;
`;

const ApproverContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface SingleSelectProps
  extends ListSingleSelectProps<SelectOptionRequired> {
  locked?: boolean;
  lockedValue?: string;
}

export const SingleSelect: FC<SingleSelectProps> = ({
  locked,
  lockedValue,
  ...props
}) => {
  return (
    <>
      {locked ? (
        <LockedContainer>
          <ApproverContainer>
            <Typography
              group="navigation"
              variant="label"
              color={colors.text.static_icons__tertiary.rgba}
            >
              {props.label}
            </Typography>
            <Typography group="table" variant="cell_text_bold">
              {lockedValue}
            </Typography>
          </ApproverContainer>
          <Icon data={lock} color={colors.text.static_icons__tertiary.rgba} />
        </LockedContainer>
      ) : (
        <SingleSelectACL {...props} />
      )}
    </>
  );
};
