import { environment, Field, TopBar } from '@equinor/amplify-component-lib';
import { useFeatureToggling } from '@equinor/subsurface-app-management';
import { useIsFetching } from '@tanstack/react-query';

import { Account } from './Content/Account';
import { Guidelines } from './Content/Guidelines';
import { Settings } from './Content/Settings';
import { TOPBAR_GUIDELINES } from 'src/constants';
import { useApp } from 'src/hooks';
import { useGetUserAccess } from 'src/hooks/useGetUserAccess';

const { getAppName, getEnvironmentName } = environment;

export const ApplicationTopBar = () => {
  const { field, setField } = useApp();
  const isFetching = useIsFetching() > 0;
  const { fields } = useGetUserAccess();
  const { showContent } = useFeatureToggling({
    featureKey: TOPBAR_GUIDELINES,
  });

  const handleSetField = (newField: Field) => {
    setField(newField);
  };

  return (
    <TopBar
      applicationIcon="premo"
      applicationName={getAppName(import.meta.env.VITE_NAME)}
      isFetching={isFetching}
      environment={getEnvironmentName(import.meta.env.VITE_ENVIRONMENT_NAME)}
      currentField={field}
      onSelectField={handleSetField}
      availableFields={fields}
    >
      <TopBar.Actions>
        <Account key="topbar-account" />
        <Settings key="topbar-settings" />
        {showContent && <Guidelines />}
        <TopBar.Resources hideLearnMore />
      </TopBar.Actions>
    </TopBar>
  );
};
