import { spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { colors } = tokens;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MainContent = styled.div`
  display: flex;
  max-width: calc(1600px - ${spacings.large} * 2);
  min-height: calc(100vh - 64px);
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  gap: ${spacings.large};
  padding: ${spacings.large};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacings.small};
  width: 100%;
`;

interface CellDividerProps {
  $amount: number;
}

export const CellDivider = styled.hr<CellDividerProps>`
  grid-column: ${({ $amount }) => `span ${$amount}`};
  height: 1px;
  background: ${colors.ui.background__medium.rgba};
`;
