import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useHelplines } from '../../ManageHelpLines';
import { FullWidthCheckBox } from './SelectData.styles';
import { DataType } from 'src/api';
import { MeasurementType } from 'src/api';
import { SourceTypes } from 'src/api';
import { PressureLabels } from 'src/constants';

export const Pressure = () => {
  const {
    selectedWellbore,
    selectedHelpLines,
    addHelpLine,
    removeHelpLine,
    source,
  } = useHelplines();

  const { wellId, dhpg } = useParams();

  const isChecked = (dataType: DataType) => {
    return selectedHelpLines.some(
      (hl) =>
        hl.id === selectedWellbore?.wellboreUuid &&
        hl.type === SourceTypes.WELLBORE &&
        hl.dataTypes?.includes(dataType) &&
        hl.source === source
    );
  };

  const isEditModeSelectedWell = useMemo(() => {
    return (
      wellId === selectedWellbore?.wellboreUuid && source?.includes(dhpg ?? '1')
    );
  }, [dhpg, selectedWellbore?.wellboreUuid, source, wellId]);

  const handleClick = (dataType: DataType) => {
    const helpLine = {
      id: selectedWellbore?.wellboreUuid,
      type: SourceTypes.WELLBORE,
      dataTypes: [dataType],
      formations: [],
      source: source,
      measureType:
        source === 'Dhpg1' ? MeasurementType.DHPG1 : MeasurementType.DHPG2,
      name: selectedWellbore?.wellName ?? '',
    };

    if (isChecked(dataType)) {
      removeHelpLine(helpLine);
    } else {
      addHelpLine(helpLine);
    }
  };

  return (
    <>
      <FullWidthCheckBox
        label={PressureLabels[DataType.RAW]}
        checked={isChecked(DataType.RAW)}
        onClick={() => handleClick(DataType.RAW)}
      />
      <FullWidthCheckBox
        label={PressureLabels[DataType.DEPTH_CORRECTED]}
        checked={isChecked(DataType.DEPTH_CORRECTED)}
        onClick={() => handleClick(DataType.DEPTH_CORRECTED)}
      />
      <FullWidthCheckBox
        label={PressureLabels[DataType.PICORRECTED]}
        checked={isChecked(DataType.PICORRECTED)}
        onClick={() => handleClick(DataType.PICORRECTED)}
      />
      <FullWidthCheckBox
        label={PressureLabels[DataType.FINAL]}
        disabled={isEditModeSelectedWell}
        checked={
          isEditModeSelectedWell
            ? isEditModeSelectedWell
            : isChecked(DataType.FINAL)
        }
        onClick={() => handleClick(DataType.FINAL)}
      />
    </>
  );
};
