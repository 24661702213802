import { useQuery } from '@tanstack/react-query';

import { MeasuredDataFilterService } from 'src/api';
import { ALL_MEASURED_DATA_FILTERS_KEY } from 'src/constants';

export const useGetFiltersGlobal = () => {
  return useQuery({
    queryKey: [ALL_MEASURED_DATA_FILTERS_KEY],
    queryFn: async () => {
      const allFilters = await MeasuredDataFilterService.getFilters();
      return allFilters.filter((filter) => !filter.wellId);
    },
  });
};
