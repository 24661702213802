import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useAuth, useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrognosisCreateDto, PrognosisService } from 'src/api';
import {
  DRAFT_PROGNOSIS_KEY,
  GET_IMAGE,
  PROGNOSIS_DETAIL,
  PROGNOSIS_KEY,
} from 'src/constants';
import { StatusIdEnum } from 'src/types';
import { EditPrognosisFormValues, parseDate } from 'src/utils';

export const usePostDraftPrognosis = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { watch } = useFormContext<EditPrognosisFormValues>();

  const queryClient = useQueryClient();
  const { account } = useAuth();
  const {
    operation,
    operationPhase,
    mainApprover,
    backupApprover,
    validFrom,
    validTo,
    wellbore,
  } = watch();
  const username = account?.username;
  const createUserShortName = username ? username.split('@')[0] : '';

  const body: PrognosisCreateDto = {
    statusId: StatusIdEnum.DRAFT,
    createUser: createUserShortName,
    title: wellbore?.label,
    operationId: Number(operation?.value),
    operationPhaseId: Number(operationPhase?.value),
    mainApprover: mainApprover?.value,
    backupApprover: backupApprover?.value,
    validFromDate: parseDate(validFrom),
    validToDate: parseDate(validTo),
    wellboreIdentifier: wellbore?.label, // Purposefully using the label here
    wellpathRevision: operation?.label,
  };

  return useMutation({
    mutationKey: [DRAFT_PROGNOSIS_KEY],
    mutationFn: async () => {
      return await PrognosisService.postApiV1Prognoses({ ...body });
    },
    onSuccess: (newID) => {
      queryClient.setQueryData([PROGNOSIS_KEY, newID.toString()], {
        ...body,
        newID,
      });

      // the new prognosis has no details yet
      queryClient.setQueryData([PROGNOSIS_DETAIL, newID.toString()], []);

      // the new prognosis has no images yet
      queryClient.setQueryData([GET_IMAGE, newID.toString()], []);

      navigate(`/dashboard/edit-prognosis/${newID}`);
    },
    onError: (err) => {
      showSnackbar('Failed to create prognosis' + err.message);
      navigate('/dashboard');
    },
  });
};
