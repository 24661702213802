import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth, useSnackbar } from '@equinor/amplify-component-lib';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PrognosisService, PrognosisUpdateDto } from 'src/api';
import { PROGNOSIS_KEY, SAVE_PROGNOSIS_KEY } from 'src/constants/queryKeys';
import { StatusIdEnum } from 'src/types/status';
import { OperationValues, parseDate } from 'src/utils';
import { delay } from 'src/utils/delay';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

export const useSubmitPrognosis = () => {
  const { prognosisId } = useParams();
  const { getValues } = useFormContext<EditPrognosisFormValues>();
  const { showSnackbar } = useSnackbar();
  const formValues = getValues();

  const {
    mainApprover,
    backupApprover,
    wellbore,
    operation,
    operationPhase,
    validFrom,
    validTo,
    stratColumnIdentifier,
  } = formValues;

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { account } = useAuth();

  const username = account?.username;
  const createUserShortName = username ? username.split('@')[0] : '';

  const shouldIncludeOperationPhaseId =
    operation?.value?.toString() === OperationValues.NEW_WELL.toString() ||
    operation?.value?.toString() === OperationValues.INTERVENTION.toString();

  const body: PrognosisUpdateDto = {
    ...formValues,
    operationId: Number(operation?.value),
    statusId: StatusIdEnum.SUBMITTED,
    wellboreIdentifier: wellbore?.label, // Purposefully using the label here
    updateUser: createUserShortName,
    mainApprover: mainApprover?.value,
    backupApprover: backupApprover?.value,
    validFromDate: parseDate(validFrom),
    validToDate: parseDate(validTo),
    wellpathRevision: operation?.label,
    stratColumnIdentifier: stratColumnIdentifier?.value,
    ...(shouldIncludeOperationPhaseId && {
      operationPhaseId: Number(operationPhase?.value),
    }),
  };

  return useMutation({
    mutationKey: [SAVE_PROGNOSIS_KEY, prognosisId],
    mutationFn: async () => {
      // Update prognosis
      await PrognosisService.putApiV1Prognoses(Number(prognosisId), undefined, {
        ...body,
        updateUser: createUserShortName,
      });
    },
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [PROGNOSIS_KEY, prognosisId],
      });

      // Snapshot the previous value
      const previousPrognosis = queryClient.getQueryData([
        PROGNOSIS_KEY,
        prognosisId,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData([PROGNOSIS_KEY, prognosisId], {
        ...body,
      });

      // artificially delay the mutation to make action more realistic
      await delay(100);

      navigate(`/detailed-view/${prognosisId}`);

      return { previousPrognosis };
    },
    onError: (err, values, context) => {
      queryClient.setQueryData(
        [PROGNOSIS_KEY, prognosisId],
        context?.previousPrognosis
      );
      showSnackbar('Failed to submit prognosis. ' + err.message);
      navigate(`/dashboard/edit-prognosis/${prognosisId}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [PROGNOSIS_KEY, prognosisId],
      });
    },
  });
};
