import { PrognosisDetailDto } from 'src/api';

export type PrognosisDetailKeys = {
  [key in keyof PrognosisDetailDto]?: {
    label: string;
    placeholder: string;
    helperText: string;
  };
};

export const referenceKeys: PrognosisDetailKeys = {
  referenceDepth: {
    label: 'Depth',
    placeholder: '0.0',
    helperText: 'm TVD MSL',
  },
  pressureInitial: {
    label: 'Initial',
    placeholder: '0.0',
    helperText: 'Pressure in bar',
  },
  pressureExpected: {
    label: 'Expected',
    placeholder: '0.0',
    helperText: 'Pressure in bar',
  },
  pressureLow: {
    label: 'Low',
    placeholder: '0.0',
    helperText: 'Pressure in bar',
  },
  pressureHigh: {
    label: 'High',
    placeholder: '0.0',
    helperText: 'Pressure in bar',
  },
  pressureMinHistorical: {
    label: 'Min',
    placeholder: '0.0',
    helperText: 'Pressure in bar',
  },
  pressureMax: {
    label: 'Max',
    placeholder: '0.0',
    helperText: 'Pressure in bar',
  },
};

export const datumKeys: PrognosisDetailKeys = {
  datumDepth: {
    label: 'Depth',
    placeholder: '0.0',
    helperText: 'm TVD MSL',
  },
  pressureDatumExpected: {
    label: 'Expected',
    placeholder: '0.0',
    helperText: 'Pressure in bar',
  },
  pressureDatumLow: {
    label: 'Low',
    placeholder: '0.0',
    helperText: 'Pressure in bar',
  },
  pressureDatumHigh: {
    label: 'High',
    placeholder: '0.0',
    helperText: 'Pressure in bar',
  },
  pressureGradientToDatumDepth: {
    label: 'Gradient to datum depth',
    placeholder: '0.0',
    helperText: 'bar/m',
  },
};
