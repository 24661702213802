import { FC } from 'react';

import {
  colors,
  OptionalTooltip,
  Typography,
} from '@equinor/amplify-component-lib';

import { StatusIdEnum } from 'src/types';

import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  max-width: 120px;
`;

export const StyledTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 100%;
`;

interface ApproveValueProps {
  approver: string | null | undefined;
  statusId: StatusIdEnum;
  mainApprover: string | null | undefined;
  backupApprover: string | null | undefined;
}

export const ApproveValue: FC<ApproveValueProps> = ({
  approver = 'N/A',
  statusId,
  mainApprover = 'N/A',
  backupApprover = 'N/A',
}) => {
  const isBackupApproved =
    approver === backupApprover && statusId === StatusIdEnum.APPROVED;

  return (
    <Container>
      {isBackupApproved ? (
        <OptionalTooltip
          placement="top"
          title={`Backup approver for ${mainApprover}`}
        >
          <StyledTypography
            group="table"
            variant="cell_text"
            color={colors.dataviz.lightpink.darker}
          >
            {approver}
          </StyledTypography>
        </OptionalTooltip>
      ) : (
        <StyledTypography group="table" variant="cell_text">
          {mainApprover}
        </StyledTypography>
      )}
    </Container>
  );
};
