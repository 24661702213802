import { FC } from 'react';

import { ErrorPage, Typography } from '@equinor/amplify-component-lib';

import { Container } from './NoItemsFound.styles';
import { TabType } from 'src/constants/tab';
import Sparky from 'src/resources/premo-new-sparky.svg';
import { tabTypeToGenericName } from 'src/utils/tab';

interface NoItemsFoundProps {
  tabType: TabType;
}

export const NoItemsFound: FC<NoItemsFoundProps> = ({ tabType }) => {
  const name = tabTypeToGenericName(tabType);

  return (
    <Container>
      <ErrorPage illustration={<img src={Sparky} />}>
        <Typography variant="body_long">
          There are no <b>{name}</b> to display.
        </Typography>
      </ErrorPage>
    </Container>
  );
};
