import { colors } from '@equinor/amplify-component-lib';
import {
  check,
  error_filled,
  file,
  IconData,
  redo,
  time,
  undo,
} from '@equinor/eds-icons';

import { StatusIdEnum } from 'src/types/status';

interface VariantStyle {
  background: string;
  color: string;
  iconData: IconData;
}

interface ApprovedVariantStyle {
  main: VariantStyle;
  sub: VariantStyle;
}

type VariantStylesObject = Record<
  StatusIdEnum,
  VariantStyle | ApprovedVariantStyle
>;

const variantStyles: VariantStylesObject = {
  [StatusIdEnum.DRAFT]: {
    background: colors.dataviz.lightgray.lighter,
    color: colors.dataviz.darkgray.darker,
    iconData: file,
  },
  [StatusIdEnum.SUBMITTED]: {
    background: colors.dataviz.lightblue.lighter,
    color: colors.dataviz.lightblue.darker,
    iconData: redo,
  },
  [StatusIdEnum.REVISION_REQUIRED]: {
    background: colors.dataviz.lightpink.lighter,
    color: colors.dataviz.lightpink.darker,
    iconData: undo,
  },
  [StatusIdEnum.OUTDATED]: {
    background: colors.dataviz.lightyellow.lighter,
    color: colors.dataviz.lightyellow.darker,
    iconData: error_filled,
  },
  [StatusIdEnum.APPROVED]: {
    main: {
      background: colors.dataviz.lightgreen.default,
      color: colors.dataviz.lightgreen.lighter,
      iconData: check,
    },
    sub: {
      background: colors.dataviz.lightblue.darker,
      color: colors.dataviz.lightblue.lighter,
      iconData: time,
    },
  },
  [StatusIdEnum.HISTORICAL]: {
    background: colors.dataviz.lightgray.lighter,
    color: colors.dataviz.darkgray.darker,
    iconData: time,
  },
} as const;

function getApprovedVariant(
  approvedVariant: ApprovedVariantStyle,
  isSubCard: boolean,
  from_date: string | null | undefined
): VariantStyle {
  if (from_date && from_date > new Date().toISOString()) {
    return approvedVariant.sub;
  }
  return isSubCard ? approvedVariant.sub : approvedVariant.main;
}

export function getVariantStyles(
  statusId: StatusIdEnum,
  isSubCard: boolean,
  from_date: string | null | undefined
): VariantStyle {
  const variant = variantStyles[statusId];

  if (statusId === StatusIdEnum.APPROVED) {
    return getApprovedVariant(
      variant as ApprovedVariantStyle,
      isSubCard,
      from_date
    );
  }
  return variant as VariantStyle;
}
