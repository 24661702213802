import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

export interface OptionalAttributes {
  datumDepth: boolean;
  segment: boolean;
  initialPressure: boolean;
  minimumPressure: boolean;
  maximumPressure: boolean;
  totalDepthRow: boolean; // if coMingledZones is true, this should be disabled
  // useExistingFieldValues: boolean;
  // usePozoZoneTops: boolean;
  // coMingledZones: boolean; // only show if operation is "new well"
  // statWellpick: boolean;
}

interface OptionalAttributeType {
  optionalAttributes: OptionalAttributes;
  setOptionalAttributes: Dispatch<SetStateAction<OptionalAttributes>>;
}

export const OptionalAttributeContext = createContext<
  OptionalAttributeType | undefined
>(undefined);

interface OptionalAttributeProviderProps {
  children: ReactNode;
}

export const OptionalAttributeProvider: FC<OptionalAttributeProviderProps> = ({
  children,
}) => {
  const [optionalAttributes, setOptionalAttributes] =
    useState<OptionalAttributes>({
      datumDepth: false,
      segment: false,
      initialPressure: false,
      minimumPressure: false,
      maximumPressure: false,
      totalDepthRow: false,
    });

  return (
    <OptionalAttributeContext.Provider
      value={{
        optionalAttributes,
        setOptionalAttributes,
      }}
    >
      {children}
    </OptionalAttributeContext.Provider>
  );
};
