import { colors } from '@equinor/amplify-component-lib';
import { add, check, lock } from '@equinor/eds-icons';

import { BaseChipProps } from 'components/BaseChip/BaseChip';

export type VariantOptions = 'default' | 'selected' | 'locked' | 'disabled';

export const ATTRIBUTE_VARIANTS: Record<VariantOptions, BaseChipProps> = {
  ['default']: {
    backgroundColor: colors.ui.background__light.rgba,
    icon: add,
    asButton: true,
    hover: true,
  },
  ['selected']: {
    backgroundColor: colors.interactive.primary__resting.rgba,
    borderColor: colors.interactive.primary__resting.rgba,
    textColor: colors.text.static_icons__primary_white.rgba,
    icon: check,
    asButton: true,
    hover: true,
  },
  ['locked']: {
    backgroundColor: colors.ui.background__light.rgba,
    textColor: colors.text.static_icons__default.rgba,
    icon: lock,
  },
  ['disabled']: {
    backgroundColor: colors.interactive.disabled__fill.rgba,
    textColor: colors.interactive.disabled__text.rgba,
    borderColor: colors.interactive.disabled__border.rgba,
    icon: add,
    disabled: true,
  },
};
