import { colors } from '@equinor/amplify-component-lib';

import { PrognosisDetailCreateDto, PrognosisDetailDto } from 'src/api';
import { OptionalAttributes } from 'src/pages/EditPrognosis/providers/OptionalAttributeProvider';
import { StatusIdEnum } from 'src/types/status';

/**
 * Converts a key of type keyof PrognosisDetailDto to its corresponding string representation.
 *
 * @param key - The key to convert.
 * @returns The string representation of the key.
 */
export function prognosisDetailKeyToString(
  key: keyof PrognosisDetailDto
): string {
  switch (key) {
    case 'referenceDepth':
    case 'datumDepth':
      return 'Depth';
    case 'pressureInitial':
      return 'Initial';
    case 'pressureExpected':
    case 'pressureDatumExpected':
      return 'Expected';
    case 'pressureLow':
    case 'pressureDatumLow':
      return 'Low';
    case 'pressureHigh':
    case 'pressureDatumHigh':
      return 'High';
    case 'pressureGradientToDatumDepth':
      return 'Correction';
    case 'lithostratSubzone':
      return 'Zones';
    case 'segment':
      return 'Segment';
    case 'fluidType':
      return 'Type';
    case 'pressureGradientDepth':
      return 'Value';
    default:
      return 'N/A';
  }
}

/**
 * Cleans the given PrognosisDetailDto object based on the provided arguments.
 *
 * @param detail - The PrognosisDetailDto object to be cleaned.
 * @param args - The arguments specifying which properties to delete.
 * @returns The cleaned PrognosisDetailDto object.
 */
export function cleanPrognosisDetail(
  detail: Partial<PrognosisDetailCreateDto>,
  args: OptionalAttributes
): PrognosisDetailDto {
  const copy: Partial<PrognosisDetailDto> = structuredClone(detail);

  if (!args.segment) {
    delete copy.segment;
  }

  if (!args.datumDepth) {
    delete copy.datumDepth;
    delete copy.pressureDatumExpected;
    delete copy.pressureDatumHigh;
    delete copy.pressureDatumLow;
    delete copy.pressureGradientToDatumDepth;
  }

  return copy as PrognosisDetailDto;
}

/**
 * Determines the stroke color based on the given prognosis status ID and valid from date.
 *
 * @param statusId - The ID of the prognosis status.
 * @param validFromDate - The valid from date.
 * @returns The stroke color as a string.
 */
export function selectStrokeColor(
  statusId: StatusIdEnum,
  validFromDate: string
) {
  switch (statusId) {
    case StatusIdEnum.DRAFT:
      return colors.dataviz.lightgray.lighter;
    case StatusIdEnum.SUBMITTED:
      return colors.dataviz.lightblue.default;
    case StatusIdEnum.REVISION_REQUIRED:
      return colors.dataviz.lightpink.default;
    case StatusIdEnum.OUTDATED:
      return colors.dataviz.lightyellow.default;
    case StatusIdEnum.APPROVED:
      if (validFromDate > new Date().toISOString()) {
        return colors.dataviz.lightblue.darker;
      } else {
        return colors.dataviz.lightgreen.default;
      }
    default:
      return 'transparent';
  }
}

export enum OperationValues {
  NEW_WELL = '0',
  INTERVENTION = '1',
  P_N_A = '2',
  OTHER = '3',
}
