import { colors } from '@equinor/amplify-component-lib';
import { createColumnHelper, RowData } from '@tanstack/react-table';

import { ActionsCell } from 'components/Table/ActionsCell/ActionsCell';
import { CommentCell } from 'components/Table/CommentCell/CommentCell';
import { DetailsCell } from 'components/Table/DetailsCell/DetailsCell';
import { SegmentCell } from 'components/Table/SegmentCell/SegmentCell';
import { TableHeaderKey } from 'components/Table/TableHeaderKey/TableHeaderKey';
import { TableHeaderTitle } from 'components/Table/TableHeaderTitle/TableHeaderTitle';
import { TableSubHeader } from 'components/Table/TableSubHeader/TableSubHeader';
import { ZoneCell } from 'components/Table/ZoneCell/ZoneCell';

import { PrognosisDetailDto } from 'src/api';

export const fallbackData: PrognosisDetailDto[] = [];

const columnHelper = createColumnHelper<PrognosisDetailDto>();

declare module '@tanstack/react-table' {
  // All declarations of 'TableMeta' must have identical type parameters
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    handleOnOpenComment: (detailComment: string | null | undefined) => void;
    handleOnOpenEditDialog: (detailRow: PrognosisDetailDto) => void;
  }
}

export const defaultColumns = [
  columnHelper.group({
    id: 'zone',
    meta: {
      headerProps: {
        style: {
          borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
        },
      },
    },
    columns: [
      columnHelper.group({
        id: 'subHeaderZone',
        header: () => <TableSubHeader label="Name / Segment" />,
        meta: {
          headerProps: {
            style: {
              borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
            },
          },
        },
        columns: [
          columnHelper.accessor('lithostratSubzone', {
            id: 'lithostratSubzone',
            header: ({ header }) => (
              <TableHeaderKey
                label="Zones"
                {...{
                  $isFirstColumn: header.index === 0,
                }}
              />
            ),
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <ZoneCell
                color={info.row.original.colorHtml}
                zoneName={info.getValue<string>()}
                nickname={info.row.original?.reservoirZoneNickname}
                isSelected={info.row.getIsSelected()}
              />
            ),
          }),
          columnHelper.accessor('segment', {
            id: 'segment',
            header: undefined,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            enableHiding: false,
            cell: (info) => (
              <SegmentCell segmentName={info.getValue<string>()} />
            ),
          }),
          columnHelper.accessor('comment', {
            id: 'comment',
            header: undefined,
            meta: {
              headerProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
              cellProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            enableHiding: false,
            cell: (info) => {
              const detailComment = info.getValue<string>();
              const handleOnOpenComment =
                info.table.options.meta?.handleOnOpenComment ??
                (() => {
                  console.warn('No handleOnOpenComment function provided');
                });

              const isPinned = !!info.row.getIsPinned();

              // The total depth row (pinned) should not have a comment cell
              if (isPinned) {
                return null;
              }

              return (
                <CommentCell
                  detailComment={detailComment}
                  handleOnOpenComment={handleOnOpenComment}
                />
              );
            },
          }),
        ],
      }),
    ],
  }),
  columnHelper.group({
    id: 'reference',
    header: () => <TableHeaderTitle label="Reference" />,
    meta: {
      headerProps: {
        style: {
          borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
        },
      },
    },
    columns: [
      columnHelper.group({
        id: 'subHeaderReference',
        header: () => <TableSubHeader label="Depth in meters" />,
        columns: [
          columnHelper.accessor('referenceDepth', {
            id: 'referenceDepth',
            header: () => <TableHeaderKey label="Depth" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            sortUndefined: 'first',
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        id: 'subHeaderPressure',
        header: () => (
          <TableSubHeader label="Pressure at reference depth in bar" />
        ),
        meta: {
          headerProps: {
            style: {
              borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
            },
          },
        },
        columns: [
          columnHelper.accessor('pressureInitial', {
            id: 'pressureInitial',
            header: () => <TableHeaderKey label="Initial" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
          columnHelper.accessor('pressureMinHistorical', {
            id: 'pressureMinHistorical',
            header: () => <TableHeaderKey label="Min" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
          columnHelper.accessor('pressureMax', {
            id: 'pressureMax',
            header: () => <TableHeaderKey label="Max" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
          columnHelper.accessor('pressureExpected', {
            id: 'pressureExpected',
            header: () => <TableHeaderKey label="Expected" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
          columnHelper.accessor('pressureLow', {
            id: 'pressureLow',
            header: () => <TableHeaderKey label="Low" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
          columnHelper.accessor('pressureHigh', {
            id: 'pressureHigh',
            header: () => <TableHeaderKey label="High" />,
            meta: {
              headerProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
              cellProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
        ],
      }),
    ],
  }),
  columnHelper.group({
    id: 'gradients',
    header: () => <TableHeaderTitle label="Gradients" />,
    meta: {
      headerProps: {
        style: {
          borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
        },
      },
    },
    columns: [
      columnHelper.group({
        id: 'subHeaderGradientFluid',
        header: () => <TableSubHeader label="Fluid" />,
        columns: [
          columnHelper.accessor('fluidType', {
            id: 'fluidType',
            header: () => <TableHeaderKey label="Type" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        id: 'subHeaderGradientUnit',
        header: () => <TableSubHeader label="bar/m" />,
        meta: {
          headerProps: {
            style: {
              borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
            },
          },
        },
        columns: [
          columnHelper.accessor('pressureGradientDepth', {
            id: 'pressureGradientDepth',
            header: () => <TableHeaderKey label="Value" />,
            meta: {
              headerProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
              cellProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
        ],
      }),
    ],
  }),
  columnHelper.group({
    id: 'datum',
    header: () => <TableHeaderTitle label="Datum" />,
    meta: {
      headerProps: {
        style: {
          borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
        },
      },
    },
    columns: [
      columnHelper.group({
        id: 'subHeaderDatum',
        header: () => <TableSubHeader label="m TVD MSL" />,
        columns: [
          columnHelper.accessor('datumDepth', {
            id: 'datumDepth',
            header: () => <TableHeaderKey label="Depth" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        id: 'subHeaderPressureDatum',
        header: () => <TableSubHeader label="Pressure at datum depth in bar" />,
        meta: {
          headerProps: {
            style: {
              borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
            },
          },
        },
        columns: [
          columnHelper.accessor('pressureDatumExpected', {
            id: 'pressureDatumExpected',
            header: () => <TableHeaderKey label="Expected" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
          columnHelper.accessor('pressureDatumLow', {
            id: 'pressureDatumLow',
            header: () => <TableHeaderKey label="Low" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
          columnHelper.accessor('pressureDatumHigh', {
            id: 'pressureDatumHigh',
            header: () => <TableHeaderKey label="High" />,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
          columnHelper.accessor('pressureGradientToDatumDepth', {
            id: 'pressureGradientToDatumDepth',
            header: () => <TableHeaderKey label="Gradient" />,
            meta: {
              headerProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
              cellProps: {
                style: {
                  borderRight: `1px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => (
              <DetailsCell
                detailKey={info.column.id}
                index={info.row.index}
                detailValue={info.getValue<string>()}
                prognosisId={info.row.original.id}
              />
            ),
          }),
        ],
      }),
    ],
  }),
  columnHelper.group({
    id: 'actionsTop',
    header: undefined,
    columns: [
      columnHelper.group({
        id: 'actionsMiddle',
        header: undefined,
        columns: [
          columnHelper.display({
            id: 'actions',
            header: undefined,
            meta: {
              headerProps: {
                style: {
                  borderBottom: `2px solid ${colors.ui.background__medium.rgba}`,
                },
              },
            },
            cell: (info) => {
              const detailId = info.row.original.id;
              const detailRow = info.row.original;
              const handleOnOpenEditDialog =
                info.table.options.meta?.handleOnOpenEditDialog ??
                (() => {
                  console.warn('No handleOnOpenComment function provided');
                });
              return (
                <ActionsCell
                  index={info.row.index}
                  detailId={detailId}
                  detailRow={detailRow}
                  handleOnOpenEditModal={handleOnOpenEditDialog}
                />
              );
            },
          }),
        ],
      }),
    ],
  }),
];
