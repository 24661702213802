import { FC } from 'react';

import { spacings, Typography } from '@equinor/amplify-component-lib';

import { styled } from 'styled-components';

interface ContainerProps {
  $isFirstColumn?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  padding: ${spacings.medium_small} ${spacings.small};
  justify-content: flex-end;
  ${({ $isFirstColumn }) => $isFirstColumn && `justify-content: flex-start;`}
`;

interface TableHeaderKeyProps {
  label: string;
  $isFirstColumn?: boolean;
}

export const TableHeaderKey: FC<TableHeaderKeyProps> = ({
  label,
  $isFirstColumn,
}) => {
  return (
    <Container $isFirstColumn={$isFirstColumn}>
      <Typography group="table" variant="cell_header">
        {label}
      </Typography>
    </Container>
  );
};
