import { FC } from 'react';

import { DetailedView } from '../DetailedView/DetailedView';
import { Container } from '../EditPrognosis/EditPrognosis.styles';
import { EditPrognosisProvider } from '../EditPrognosis/providers/EditPrognosisProvider';
import { PreviewFooter } from './components/PreviewFooter';

export const PreviewPrognosis: FC = () => {
  return (
    <EditPrognosisProvider>
      <Container>
        <DetailedView showPreviewChip={true} />
        <PreviewFooter />
      </Container>
    </EditPrognosisProvider>
  );
};
