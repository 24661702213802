import { colors } from '@equinor/amplify-component-lib';
import {
  archive,
  check,
  error_filled,
  file,
  redo,
  undo,
} from '@equinor/eds-icons';

import { BaseChipProps } from '../BaseChip';
import { StatusIdEnum } from 'src/types/status';

export const STATUS_VARIANTS: Record<StatusIdEnum, BaseChipProps> = {
  [StatusIdEnum.DRAFT]: {
    backgroundColor: colors.dataviz.lightgray.lighter,
    textColor: colors.text.static_icons__default.rgba,
    iconColor: colors.dataviz.darkgray.darker,
    label: 'Draft',
    icon: file,
    borderColor: colors.dataviz.lightgray.lighter,
  },
  [StatusIdEnum.SUBMITTED]: {
    backgroundColor: colors.dataviz.lightblue.lighter,
    textColor: colors.dataviz.darkblue.default,
    iconColor: colors.dataviz.lightblue.darker,
    label: 'Submitted',
    icon: redo,
    borderColor: colors.dataviz.lightblue.lighter,
  },
  [StatusIdEnum.REVISION_REQUIRED]: {
    backgroundColor: colors.dataviz.lightpink.lighter,
    textColor: colors.dataviz.darkpink.default,
    iconColor: colors.dataviz.lightpink.darker,
    icon: undo,
    label: 'Rejected',
    borderColor: colors.dataviz.lightpink.lighter,
  },
  [StatusIdEnum.OUTDATED]: {
    backgroundColor: colors.dataviz.lightyellow.lighter,
    textColor: colors.dataviz.darkyellow.darker,
    iconColor: colors.dataviz.darkyellow.default,
    icon: error_filled,
    label: 'Outdated',
    borderColor: colors.dataviz.lightyellow.lighter,
  },
  [StatusIdEnum.HISTORICAL]: {
    backgroundColor: colors.dataviz.darkpurple.lighter,
    textColor: colors.dataviz.darkpurple.darker,
    iconColor: colors.dataviz.darkpurple.default,
    label: 'Archived',
    icon: archive,
    borderColor: colors.dataviz.darkpurple.lighter,
  },
  [StatusIdEnum.APPROVED]: {
    backgroundColor: colors.dataviz.darkgreen.lighter,
    textColor: colors.dataviz.darkgreen.default,
    label: 'Active',
    icon: check,
    borderColor: colors.dataviz.darkgreen.lighter,
  },
};
