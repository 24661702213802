import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DialogAction, Icon, Typography } from '@equinor/amplify-component-lib';
import { Dialog } from '@equinor/amplify-component-lib';
import { add, infinity } from '@equinor/eds-icons';

import {
  AddNewRuleButton,
  AddNewRuleButtonWrapper,
  Body,
  Divider,
  IndefiniteIconInText,
  SpaceBetweenWrapper,
} from './AdvancedFilterDialog.styles';
import { Rule } from './Rule';
import { MeasuredDataFilter } from 'src/api';
import { FilterRule } from 'src/api';
import { useMeasuredDataWellbore } from 'src/hooks/useMeasuredDataWellbore';
import { useEditMeasuredData } from 'src/pages/EditMeasuredData/hooks/useEditMeasuredData';
import { useIsFiltersLoading } from 'src/pages/EditMeasuredData/hooks/useIsFiltersLoading';
import { getNameFromDetailed } from 'src/utils';

interface AdvancedFilterDialogProps {
  filters: MeasuredDataFilter[];
  isUpdatingFilter: boolean;
  onSave: () => void;
  onDelete: () => void;
  onAddNewRule: () => void;
  onDeleteRule: (id: string | null | undefined) => void;
  onAddNewCriteria: (id: string | null | undefined) => void;
  onDeleteCriteria: (
    id: string | null | undefined,
    criteriaId: string | null | undefined
  ) => void;
  updateFilter: (id: string, value: Partial<MeasuredDataFilter>) => void;
}

export const AdvancedFilterDialog: FC<AdvancedFilterDialogProps> = ({
  filters,
  isUpdatingFilter,
  onSave,
  onDelete,
  onAddNewRule,
  onDeleteRule,
  onAddNewCriteria,
  onDeleteCriteria,
  updateFilter,
}) => {
  const { wellbore } = useMeasuredDataWellbore(filters.at(0)?.wellId ?? '');
  const { dhpg } = useParams();
  const { setAdvancedFilterDialogOptions } = useEditMeasuredData();
  const { filtersLoading } = useIsFiltersLoading();

  const isGlobalFilters = useMemo(() => {
    return filters.every(
      (filter) => filter.wellId === null || filter.wellId === undefined
    );
  }, [filters]);

  const handleUpdateRule = (id: string, value: Partial<FilterRule>) => {
    const existingRule = filters.find((f) => f.id === id)?.rule;
    updateFilter(id, {
      rule: {
        ...filters.find((f) => f.id === id)?.rule,
        criteria: existingRule?.criteria ?? [],
        ...value,
      },
    });
  };

  const filterTargetDescriptor = () => {
    if (!wellbore) return 'the currently selected wellbore';
    if (isGlobalFilters) {
      return 'globally to all wellbores for the selected field';
    }

    return getNameFromDetailed(wellbore, Number(dhpg));
  };

  const handleDialogOnClose = () => {
    setAdvancedFilterDialogOptions((prev) => ({ ...prev, open: false }));
  };

  const actionButtons: DialogAction[] = [
    {
      position: 'left',
      onClick: handleDialogOnClose,
      text: 'Cancel',
      variant: 'ghost',
    },
    {
      position: 'right',
      onClick: onSave,
      text: isUpdatingFilter ? 'Update rule' : 'Exclude data',
      color: 'primary',
      isLoading: filtersLoading,
    },
  ];

  const deleteRuleButton: DialogAction = {
    position: 'right',
    onClick: onDelete,
    text: 'Delete rule',
    variant: 'outlined',
    color: 'danger',
  };

  if (isUpdatingFilter) {
    actionButtons.splice(1, 0, deleteRuleButton);
  }

  return (
    <Dialog
      title={`Advanced filters - ${isGlobalFilters ? 'Global filters' : 'Filters for well'}`}
      open
      onClose={handleDialogOnClose}
      width={1100}
      withContentPadding={{ vertical: true, horizontal: true }}
      withBorders
      actions={actionButtons}
    >
      <Body>
        <SpaceBetweenWrapper>
          <Typography>
            Advanced filters allow you to exclude data from the dataset within a
            specified date range, using criteria.
          </Typography>
          <Typography>
            The date range can consist of specific dates, or you can use
            <IndefiniteIconInText data={infinity} />
            to turn either from, to or both to indefinite. This will cause the
            filter to be applied to the data indefinitely in the chosen
            direction.
          </Typography>
          <Typography>
            Each criteria on a rule must resolve to true for the rule to be
            applied.
          </Typography>
          <Typography>
            Please select which data to exclude from{' '}
            <b>{filterTargetDescriptor()}</b> by filling fields below.
          </Typography>
        </SpaceBetweenWrapper>
        {filters.map((filter) => (
          <Rule
            key={`filter-${filter.id}`}
            filter={filter}
            isLastRuleLeft={filters.length < 2}
            onDeleteRule={onDeleteRule}
            onAddNewCriteria={onAddNewCriteria}
            onDeleteCriteria={onDeleteCriteria}
            updateFilter={updateFilter}
            updateRule={handleUpdateRule}
          />
        ))}
      </Body>
      {!isUpdatingFilter && (
        <AddNewRuleButtonWrapper>
          <AddNewRuleButton variant="outlined" onClick={onAddNewRule}>
            <Icon data={add} />
            Add new rule
          </AddNewRuleButton>
          <Divider />
        </AddNewRuleButtonWrapper>
      )}
    </Dialog>
  );
};
