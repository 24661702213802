import { useMemo } from 'react';

import { InterpolatedMapLayer } from '../layers/InterpolatedMapLayer';
import { useGetFieldOutlineFeatureCollection } from 'src/pages/MapView/components/Map/hooks/useGetFieldOutlineFeatureCollection';
import { useMapOptions, usePressurePoints } from 'src/pages/MapView/hooks';
import { useGetFieldConfigForMap } from 'src/pages/MapView/hooks/useGetFieldConfigForMap';
import { MapBackground } from 'src/pages/MapView/MapView.types';

export function useInterpolatedMap() {
  const { backgroundLayer, setIsLoading, setLegendRange } = useMapOptions();
  const { zoom } = useGetFieldConfigForMap();
  const { pressurePointsWithValue } = usePressurePoints();
  const { filteredOutlineFeatures } = useGetFieldOutlineFeatureCollection();
  const showHeatmap = useMemo(
    () => backgroundLayer === MapBackground.INTERPOLATED,
    [backgroundLayer]
  );

  return useMemo(
    () =>
      new InterpolatedMapLayer({
        id: MapBackground.INTERPOLATED,
        showHeatmap,
        pressurePoints: pressurePointsWithValue,
        setIsLoading,
        zoom,
        setLegendRange,
        fieldOutlines: filteredOutlineFeatures ?? [],
      }),
    [
      showHeatmap,
      pressurePointsWithValue,
      setIsLoading,
      zoom,
      setLegendRange,
      filteredOutlineFeatures,
    ]
  );
}
