import { FC } from 'react';

import { colors, formatDate } from '@equinor/amplify-component-lib';
import { Tooltip } from '@visx/xychart';

import { PrognosisDetailReservoirZoneDto } from 'src/api';

import styled from 'styled-components';

const TooltipContainer = styled.div`
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const TooltipTitle = styled.div`
  color: ${(props) => props.color};
  font-weight: bold;
  margin-bottom: 5px;
`;

const TooltipItem = styled.div`
  margin-bottom: 3px;
  color: ${colors.text.static_icons__tertiary.rgba};
  font-size: 12px;
`;

interface DividerColorProps {
  $color: string;
}

const DividerWithColor = styled.div<DividerColorProps>`
  width: 100%;
  height: 2px;
  background: ${({ $color }) => $color};
  margin-bottom: 5px;
`;

interface XyTooltipProps {
  toggleXy?: boolean;
  useDateAsTitle?: boolean;
}

export const XyTooltip: FC<XyTooltipProps> = ({
  useDateAsTitle = false,
  toggleXy = true,
}) => {
  return (
    <Tooltip<PrognosisDetailReservoirZoneDto>
      snapTooltipToDatumX={toggleXy}
      snapTooltipToDatumY={!toggleXy}
      showVerticalCrosshair={toggleXy}
      showHorizontalCrosshair={!toggleXy}
      renderTooltip={({ tooltipData }) => {
        if (!tooltipData) return null;

        const datum = tooltipData.nearestDatum?.datum;

        return (
          <TooltipContainer>
            <TooltipTitle>
              {useDateAsTitle
                ? formatDate(datum?.approveDate)
                : datum?.reservoirZone}
            </TooltipTitle>
            <DividerWithColor
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              $color={tooltipData?.nearestDatum?.datum.colorHtml || '#000'}
            />
            <TooltipItem>Depth: {datum?.referenceDepth}</TooltipItem>
            <TooltipItem>Initial: {datum?.pressureInitial}</TooltipItem>
            <TooltipItem>Low: {datum?.pressureLow}</TooltipItem>
            <TooltipItem>High: {datum?.pressureHigh}</TooltipItem>
          </TooltipContainer>
        );
      }}
    />
  );
};
