export const PAGE_SIZE = 25;

export const APPROVE_PROGNOSIS_KEY = 'approvePrognosis';
export const CREATE_FILTER_KEY = 'createFilter';
export const DRAFT_PROGNOSIS_KEY = 'draftPrognosis';
export const FILTER_KEY = 'filter';
export const FULL_NAME_QUERY = 'full-name-query';
export const LAST_USED_ZONES_KEY = 'lastUsedZones';
export const TOP_ZONES_KEY = 'topUsedZones';
export const LOOKUP_VALUES = 'lookup-values';
export const MIDDLE_PAGE = Math.floor(PAGE_SIZE / 2);
export const PATCH_FILTER_KEY = 'patchFilter';
export const PREVIOUS_PROGNOSIS_DETAILS_KEY = 'previousPrognosisDetails';
export const PROGNOSIS_KEY = 'prognoses';
export const PROGNOSIS_DETAIL = 'prognosis-details';
export const STRATIGRAPHIC_UNIT_ALL_KEY = 'stratigraphicUnitAll';
export const STRATIGRAPHIC_UNIT_PLANNED_KEY = 'stratigraphicUnitPlanned';
export const STRATIGRAPHIC_UNIT_DRILLED_KEY = 'stratigraphicUnitDrilled';
export const STRAT_COLUMN_KEY = 'stratColumn';
export const SAVE_PROGNOSIS_KEY = 'savePrognosis';
export const SUBZONE_KEY = 'subzone';
export const USER_ACCESS_KEY = 'userAccess';
export const USER_KEY = 'user';
export const MAIN_APPROVER = 'mainApprover';
export const BACKUP_APPROVER = 'backupApprover';
export const PROGNOSIS_TYPES_KEY = 'prognosisTypes';
export const REJECT_PROGNOSIS_KEY = 'rejectPrognosis';
export const WELLBORE_ALL_KEY = 'wellbore-all';
export const WELLBORE_BY_IDENTIFIER_KEY = 'wellbore-by-identifier';
export const WELLBORE_DRILLED_KEY = 'wellbore-drilled';
export const WELLBORE_PLANNED_KEY = 'wellbore-planned';
export const WELL_PLANNING_PHASES_KEY = 'wellPlanningPhases';
export const PROGNOSIS_HISTORY_KEY = 'prognosisHistory';
export const RESERVOIR_ZONE_DETAILS_KEY = 'reservoirZoneDetails';
export const OVERLAPPING_PROGNOSES = 'overlapping-prognoses';
export const GET_FIELD_CONFIG_KEY = 'getFieldConfig';

// MEASURED DATA
export const MEASURED_DATA_ACCESS_CHECK_KEY = 'measuredDataAccessCheck';
export const ALL_MEASURED_DATA_FILTERS_KEY = 'allMeasuredDataFilters';
export const AREA_FEATURES_QUERY_KEY = 'map-features';
export const DRILLED_WELLS_FEATURES_KEY = 'getDrilledWellsFeaturesByField';
export const FAULT_LINES_QUERY_KEY = 'map-fault-lines';
export const FIELD_OUTLINE_QUERY_KEY = 'map-field-outline';
export const MEASURED_DATA_FILTERS_KEY = 'measuredDataFilters';
export const MEASURED_DATA_HELPLINES_KEY = 'measured-data-help-lines';
export const MEASURED_DATA_MAP_KEY = 'measured-data-map';
export const PRES_DATA_BY_FIELD_FLAT_KEY = 'getpressureDataByFieldFlat';
export const PRES_DATA_FOR_WELLBORES_FLAT_KEY =
  'getPressureDataForWellboresFlat';
export const WELLBORE_PATHS_QUERY_KEY = 'map-wellbore-paths';
export const GET_SELECTED_MEASUREMENTS_FILTERS =
  'getSelectedMeasurementFilters';
export const COPY_PROGNOSIS = 'copyPrognosis';
export const SAVE_MEASURED_DATA_FILTER = 'saveMeasuredDataFilter';
export const BULK_UPDATE_MEASURED_DATA_FILTER = 'bulkUpdateMeasuredDataFilter';
export const DELETE_MEASURED_DATA_FILTER = 'deleteMeasuredDataFilter';
export const BULK_DELETE_MEASURED_DATA_FILTER = 'bulkDeleteMeasuredDataFilter';
export const NEW_WELL_PHASE = 'newWellPhase';
export const INTERVENTION_PHASE = 'interventionPhase';

export const ALL_MEASURED_DATA_FILTERS_MUTATION_KEYS = [
  DELETE_MEASURED_DATA_FILTER,
  SAVE_MEASURED_DATA_FILTER,
  BULK_DELETE_MEASURED_DATA_FILTER,
  BULK_UPDATE_MEASURED_DATA_FILTER,
];
