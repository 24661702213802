import { FC } from 'react';

import { MAP_FOOTER_HEIGHT } from 'src/constants';
import { HoverCoordinates } from 'src/pages/MapView/components/InfoFooter/HoverCoordinates';
import { Scale } from 'src/pages/MapView/components/InfoFooter/Scale';

import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  bottom: ${MAP_FOOTER_HEIGHT};
  left: 0;
  display: flex;
  flex-direction: column;
`;

export const InfoFooter: FC = () => {
  return (
    <Wrapper>
      <Scale />
      <HoverCoordinates key={`hover-coordinates`} />
    </Wrapper>
  );
};
