import { FC, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  SelectOption,
  SelectOptionRequired,
} from '@equinor/amplify-component-lib';

import { useDeleteDetail } from '../../hooks/useDeleteDetail';
import {
  useGetInterventionPhases,
  useGetNewWellPhases,
} from '../../hooks/useGetOperationPhases';
import { usePostDraftPrognosis } from '../../hooks/usePostDraftPrognosis';
import { usePutDraftPrognosis } from '../../hooks/usePutDraftPrognosis';
import { checkPostDraftPrognosis } from '../../utils/formUtils';
import { ConfirmDialog } from '../ConfimDialog/ConfirmDialog';
import { SingleSelect } from '../SingleSelect/SingleSelect';
import { Form, FormColumn, Separator, TextField } from './FormHeader.styles';
import { useGetPrognosisTypes } from 'src/hooks/useGetPrognosisTypes';
import { useEditPrognosis } from 'src/pages/EditPrognosis/hooks/useEditPrognosis';
import { OperationValues } from 'src/utils';
import { lookupValueToComboBoxRequired } from 'src/utils/lookup';
import { EditPrognosisFormValues } from 'src/utils/zodSchema';

import { useGetBackupApprovers } from 'hooks/useGetBackupApprovers';
import { useGetMainApprovers } from 'hooks/useGetMainApprovers';

export const FormHeader: FC = () => {
  const {
    register,
    control,
    trigger,
    getValues,
    setValue,
    watch,
    unregister,
    formState: { errors },
  } = useFormContext<EditPrognosisFormValues>();
  const { mutate: updateDraftPrognosis } = usePutDraftPrognosis();
  const { mutate: postDraftPrognosis, isPending: isCreating } =
    usePostDraftPrognosis();
  const { isInitializing } = useEditPrognosis();
  const { data: operations } = useGetPrognosisTypes();
  const { data: mainApprovers } = useGetMainApprovers();
  const { data: backupApprovers } = useGetBackupApprovers();
  const { data: newWellPhases } = useGetNewWellPhases();
  const { data: interventionPhases } = useGetInterventionPhases();
  const { mutate: deletePrognosisDetail } = useDeleteDetail();
  const { prognosisId } = useParams();
  const { prognosisDetails, operation } = watch();

  const [open, setOpen] = useState(false);
  const [nextValue, setNextValue] = useState<
    SelectOptionRequired | undefined
  >(); // used to store the next value when the user selects a new operation

  const handleUpdateField = (
    key: keyof Pick<
      EditPrognosisFormValues,
      'operation' | 'operationPhase' | 'mainApprover' | 'backupApprover'
    >,
    item: SelectOption<SelectOptionRequired> | null
  ) => {
    const previousValue: SelectOptionRequired | null = getValues(key);
    const isFieldDirty = item?.value !== previousValue?.value;

    if (isFieldDirty) {
      if (key == 'operation') {
        setValue('operationPhase', null);
        unregister('operationPhase');

        if (prognosisDetails?.length !== 0) {
          setOpen(true);
          setNextValue(item as SelectOptionRequired);
          return;
        }
      }

      setValue(key, item ?? null);

      trigger(key);

      const formData = watch();
      const shouldPostPrognosis = checkPostDraftPrognosis(
        formData,
        prognosisId == undefined,
        isCreating
      );

      if (shouldPostPrognosis) {
        postDraftPrognosis();
      } else {
        updateDraftPrognosis();
      }
    }
  };

  const handleConfirm = () => {
    const previousValue: SelectOptionRequired | undefined =
      getValues('operation');

    if (nextValue) {
      setValue('operation', nextValue);
    }

    prognosisDetails.map((detail) => {
      deletePrognosisDetail(detail.id);
    });

    const isFieldDirty = nextValue?.value !== previousValue?.value;

    if (isFieldDirty) {
      setOpen(false);
      updateDraftPrognosis();
    }
  };

  const handleUpdateDate = () => {
    const validFrom = watch('validFrom');
    const validTo = watch('validTo');

    if (validFrom && validTo) {
      trigger('validTo');
      trigger('validFrom');
    }

    updateDraftPrognosis();
  };

  // convert data to SelectOptionRequired shape
  const operationOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      operations?.map((item) => ({
        value: item.id?.toString() ?? '',
        label: lookupValueToComboBoxRequired(item.name, true).label,
      })) ?? []
    );
  }, [operations]);

  const mainApproverOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      mainApprovers?.map((item) => ({
        value: item.shortName,
        label: item.displayName,
      })) ?? []
    );
  }, [mainApprovers]);

  const backupApproverOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      backupApprovers?.map((item) => ({
        value: item.shortName,
        label: item.displayName,
      })) ?? []
    );
  }, [backupApprovers]);

  const lockedValueText = useMemo(() => {
    if (operation?.value == undefined) {
      return 'Select an operation first';
    } else if (operation?.value === OperationValues.OTHER.toString()) {
      return 'Other';
    } else if (operation?.value === OperationValues.P_N_A.toString()) {
      return 'P&a';
    } else {
      return 'Locked';
    }
  }, [operation]);

  const newWellPhasesOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      newWellPhases?.map((item) => ({
        value: item.id.toString(),
        label: lookupValueToComboBoxRequired(item.name, true).label,
      })) ?? []
    );
  }, [newWellPhases]);

  const interventionPhasesOptions: SelectOptionRequired[] = useMemo(() => {
    return (
      interventionPhases?.map((item) => ({
        value: item.id?.toString() ?? '',
        label: lookupValueToComboBoxRequired(item.name, true).label,
      })) ?? []
    );
  }, [interventionPhases]);

  return (
    <>
      <Form>
        <FormColumn>
          <Controller
            name="operation"
            control={control}
            render={({ field }) => (
              <SingleSelect
                label="Operation"
                items={operationOptions}
                placeholder="Select one..."
                value={field?.value}
                onSelect={(item) => {
                  handleUpdateField('operation', item as SelectOptionRequired);
                }}
                loading={isInitializing}
                lightBackground
                meta="Required"
                variant={errors.operation ? 'error' : undefined}
                helperText={errors.operation?.message}
                data-testid="operation-input"
                clearable={false}
              />
            )}
          />
          <Controller
            name="operationPhase"
            control={control}
            render={({ field }) => (
              <SingleSelect
                label="Operation subtype"
                items={
                  operation?.value === OperationValues.NEW_WELL.toString()
                    ? newWellPhasesOptions
                    : interventionPhasesOptions
                }
                placeholder="Select one..."
                value={field?.value ?? undefined}
                onSelect={(item) => {
                  handleUpdateField(
                    'operationPhase',
                    item as SelectOptionRequired
                  );
                }}
                loading={isInitializing}
                lightBackground
                meta="Required"
                variant={errors.operationPhase ? 'error' : undefined}
                helperText={errors.operationPhase?.message}
                data-testid="operationPhase-input"
                locked={
                  operation?.value.toString() !==
                    OperationValues.NEW_WELL.toString() &&
                  operation?.value.toString() !==
                    OperationValues.INTERVENTION.toString()
                }
                lockedValue={lockedValueText}
              />
            )}
          />
        </FormColumn>
        <Separator />
        <FormColumn>
          <Controller
            name="mainApprover"
            control={control}
            render={({ field }) => (
              <SingleSelect
                label="Approver"
                items={mainApproverOptions}
                placeholder="Select one..."
                value={field.value}
                onSelect={(item) => {
                  handleUpdateField(
                    'mainApprover',
                    item as SelectOptionRequired
                  );
                }}
                loading={isInitializing}
                lightBackground
                meta="Required"
                variant={errors.mainApprover ? 'error' : undefined}
                helperText={errors.mainApprover?.message}
                data-testid="mainApprover-input"
                clearable={false}
              />
            )}
          />
          <Controller
            name="backupApprover"
            control={control}
            render={({ field }) => (
              <SingleSelect
                label="Backup approver"
                items={backupApproverOptions}
                placeholder="Select one..."
                value={field.value}
                onSelect={(item) => {
                  handleUpdateField(
                    'backupApprover',
                    item as SelectOptionRequired
                  );
                }}
                loading={isInitializing}
                lightBackground
                meta="Required"
                variant={errors.backupApprover ? 'error' : undefined}
                helperText={errors.backupApprover?.message}
                data-testid="backupApprover-input"
                clearable={false}
              />
            )}
          />
        </FormColumn>
        <Separator />
        <FormColumn>
          <TextField
            id="validFrom"
            label="Valid from"
            type="date"
            disabled={isInitializing}
            {...register('validFrom', {
              onChange: () => handleUpdateDate(),
            })}
            meta="Required"
            variant={errors.validFrom ? 'error' : undefined}
            helperText={errors.validFrom?.message}
            data-testid="valid-from-input"
          />
          <TextField
            id="validTo"
            label="Valid to"
            type="date"
            disabled={isInitializing}
            {...register('validTo', {
              onChange: () => handleUpdateDate(),
            })}
            meta="Required"
            variant={errors.validTo ? 'error' : undefined}
            helperText={errors.validTo?.message}
            data-testid="valid-to-input"
          />
        </FormColumn>
        <ConfirmDialog
          open={open}
          onClose={() => setOpen(false)}
          onConfirm={() => handleConfirm()}
        />
      </Form>
    </>
  );
};
