import { useQuery } from '@tanstack/react-query';

import { OperationPhaseService } from 'src/api';
import { INTERVENTION_PHASE, NEW_WELL_PHASE } from 'src/constants';

export const useGetNewWellPhases = () => {
  return useQuery({
    queryKey: [NEW_WELL_PHASE],
    queryFn: () =>
      OperationPhaseService.getApiV1PrognosesOperationPhasesNewWell(),
  });
};

export const useGetInterventionPhases = () => {
  return useQuery({
    queryKey: [INTERVENTION_PHASE],
    queryFn: () =>
      OperationPhaseService.getApiV1PrognosesOperationPhasesIntervention(),
  });
};
