import { colors, spacings, Typography } from '@equinor/amplify-component-lib';

import { MapColor } from 'src/types';

import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1000;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacings.x_large};
  p {
    user-select: none;
  }
`;

interface GradientElementProps {
  $colors: MapColor[];
}

export const GradientElement = styled.div<GradientElementProps>`
  width: 100%;
  height: 24px;
  border-radius: 12px;
  background: linear-gradient(
    90deg,
    ${({ $colors }) => {
      const colorSize = 100 / $colors.length;
      return $colors.map(
        (color, index) =>
          `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${
            (color.at(3) ?? 255) / 255
          })${`${index * colorSize}% ${(index + 1) * colorSize}%`}${index !== $colors.length - 1 ? ',' : ''}`
      );
    }}
  );
  position: relative;
`;

interface ValueLabelProps {
  $position: 'left' | 'right';
}

export const ValueLabel = styled(Typography)<ValueLabelProps>`
  height: ${spacings.medium};
  display: flex;
  align-items: center;
  position: absolute;
  ${({ $position }) => `${$position}: ${spacings.x_small}`};
  background: ${colors.ui.background__scrim.rgba};
  color: ${colors.text.static_icons__primary_white.rgba};
  padding: 0 ${spacings.x_small};
  border-radius: ${spacings.small};
  margin-top: ${spacings.x_small};
`;
