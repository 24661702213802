import { colors } from '@equinor/amplify-component-lib';

import { ChartDataPointCategory } from './Chart.types';
import { PrognosisDetailReservoirZoneDto } from 'src/api';

const PAD_PERCENTAGE = 0.2;

export function allPressureValuesFromDataPoint(
  dataPoint: PrognosisDetailReservoirZoneDto
): number[] {
  const values = [
    dataPoint.pressureExpected,
    dataPoint.pressureHigh,
    dataPoint.pressureLow,
  ];
  if (dataPoint.pressureInitial) {
    values.push(dataPoint.pressureInitial);
  }
  return values as number[];
}

export function dataPointColor(
  category: ChartDataPointCategory,
  color?: string,
  graphColors?: string[]
) {
  const colorString = graphColors?.map((color) => `${color}`).join(',');

  let fillColor = color || colors.ui.background__medium.rgba;
  if (colorString) {
    fillColor = `conic-gradient(from 180deg at 50% 50%, ${colorString})`;
  }

  switch (category) {
    case ChartDataPointCategory.EXPECTED:
      return {
        stroke: colors.ui.background__scrim.hex,
        fill: colors.ui.background__default.rgba,
      };

    case ChartDataPointCategory.LOW_HIGH:
      return {
        stroke: color,
        fill: fillColor,
      };
    case ChartDataPointCategory.INITIAL:
      return {
        stroke: colors.text.static_icons__default.hex,
        fill: colors.text.static_icons__default.hex,
      };
  }
}

export function getDepthDomain(
  data: PrognosisDetailReservoirZoneDto[]
): [number, number] {
  const min = Math.min(...data.map((d) => d.referenceDepth!));
  const max = Math.max(...data.map((d) => d.referenceDepth!));

  const diff = (max - min) * PAD_PERCENTAGE;

  return [min - diff, max + diff];
}

export function getPressureDomain(
  data: PrognosisDetailReservoirZoneDto[]
): [number, number] {
  const min = Math.min(...data.flatMap(allPressureValuesFromDataPoint));
  const max = Math.max(...data.flatMap(allPressureValuesFromDataPoint));

  const diff = (max - min) * PAD_PERCENTAGE;

  return [Math.max(min - diff, 0), max + diff];
}
