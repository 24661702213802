/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PrognosisDetailCreateDto } from '../models/PrognosisDetailCreateDto';
import type { PrognosisDetailDto } from '../models/PrognosisDetailDto';
import type { PrognosisDetailReservoirZoneDto } from '../models/PrognosisDetailReservoirZoneDto';
import type { PrognosisDetailUpdateDto } from '../models/PrognosisDetailUpdateDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PrognosisDetailService {
  /**
   * Get prognosis detail
   * @param detailId
   * @returns PrognosisDetailDto OK
   * @throws ApiError
   */
  public static getApiV1PrognosesDetails(
    detailId: number
  ): CancelablePromise<PrognosisDetailDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/Details/{detailId}',
      path: {
        detailId: detailId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get details by prognosis ID
   * @param prognosisId
   * @returns PrognosisDetailDto OK
   * @throws ApiError
   */
  public static getApiV1PrognosesDetails1(
    prognosisId: number
  ): CancelablePromise<Array<PrognosisDetailDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/{prognosisId}/Details',
      path: {
        prognosisId: prognosisId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Create new detail
   * @param prognosisId
   * @param requestBody
   * @returns number Created
   * @throws ApiError
   */
  public static postApiV1PrognosesDetails(
    prognosisId: number,
    requestBody?: PrognosisDetailCreateDto
  ): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/Prognoses/{prognosisId}/Details',
      path: {
        prognosisId: prognosisId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Update detail
   * @param prognosisId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static putApiV1PrognosesDetails(
    prognosisId: number,
    requestBody?: PrognosisDetailUpdateDto
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/Prognoses/{prognosisId}/Details',
      path: {
        prognosisId: prognosisId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Delete detail
   * @param detailId
   * @returns void
   * @throws ApiError
   */
  public static deleteApiV1PrognosesDetails(
    detailId?: number
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/Prognoses/Details',
      query: {
        detailId: detailId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Patch detail comment
   * @param prognosisId
   * @param detailId
   * @param comment
   * @returns void
   * @throws ApiError
   */
  public static patchApiV1PrognosesDetails(
    prognosisId: number,
    detailId: number,
    comment?: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/api/v1/Prognoses/{prognosisId}/Details/{detailId}',
      path: {
        prognosisId: prognosisId,
        detailId: detailId,
      },
      query: {
        comment: comment,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @deprecated
   * Get prognosis details by reservoir zone
   * @param reservoirZone
   * @returns PrognosisDetailReservoirZoneDto OK
   * @throws ApiError
   */
  public static getApiV1PrognosesDetailsReservoirZone(
    reservoirZone: string
  ): CancelablePromise<Array<PrognosisDetailReservoirZoneDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/Details/ReservoirZone/{reservoirZone}',
      path: {
        reservoirZone: reservoirZone,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get historic prognosis details
   * @param wellboreIdentifier
   * @param stratColumn
   * @param reservoirZone
   * @returns PrognosisDetailReservoirZoneDto OK
   * @throws ApiError
   */
  public static getApiV1PrognosesDetailsWellboreStratColumnReservoirZone(
    wellboreIdentifier: string,
    stratColumn: string,
    reservoirZone: string
  ): CancelablePromise<Array<PrognosisDetailReservoirZoneDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/Details/Wellbore/{wellboreIdentifier}/StratColumn/{stratColumn}/ReservoirZone/{reservoirZone}',
      path: {
        wellboreIdentifier: wellboreIdentifier,
        stratColumn: stratColumn,
        reservoirZone: reservoirZone,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * Get historic prognosis details for a commingled prognosis
   * @param prognosisId
   * @param commingledId
   * @returns PrognosisDetailReservoirZoneDto OK
   * @throws ApiError
   */
  public static getApiV1PrognosesDetailsCommingled(
    prognosisId: number,
    commingledId: number
  ): CancelablePromise<Array<PrognosisDetailReservoirZoneDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/Prognoses/Details/{prognosisId}/Commingled/{commingledId}',
      path: {
        prognosisId: prognosisId,
        commingledId: commingledId,
      },
      errors: {
        400: `Bad Request`,
        500: `Internal Server Error`,
      },
    });
  }
}
