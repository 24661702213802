import { FC, useContext } from 'react';

import { DataContext } from '@visx/xychart';

import { PrognosisDetailReservoirZoneDto } from 'src/api';

interface RectGlyphProps {
  x?: number;
  y?: number;
  datum: PrognosisDetailReservoirZoneDto;
  color: string | undefined;
  toggleXY?: boolean;
  selectedZone?: number;
}

export const RectGlyph: FC<RectGlyphProps> = ({
  x,
  y,
  datum,
  color,
  toggleXY = false,
  selectedZone,
}) => {
  const { yScale, xScale } = useContext(DataContext);
  if (!datum.pressureLow || !datum.pressureHigh || !yScale || !xScale) return;

  const scaleLow: number = toggleXY
    ? (yScale(datum.pressureLow) as number)
    : (xScale(datum.pressureLow) as number);
  const scaleHigh: number = toggleXY
    ? (yScale(datum.pressureHigh) as number)
    : (xScale(datum.pressureHigh) as number);

  const barY = scaleLow - scaleHigh;
  const barX = scaleHigh - scaleLow;

  const currentZone = selectedZone === datum.id;

  const barThickness = currentZone ? 12 : 6;
  const radius = currentZone ? 6 : 3;

  return (
    <g>
      <rect
        rx={radius}
        width={toggleXY ? barThickness : barX}
        height={toggleXY ? barY : barThickness}
        // stroke={colors.interactive.primary__resting.hex}
        fill={color}
        x={x}
        y={y}
      ></rect>
    </g>
  );
};
