import { FC, useMemo } from 'react';

import { PressureDataSimple } from 'src/api';
import { useMeasuredData } from 'src/hooks';
import { MapMarkerArea } from 'src/pages/MapView/components/MapElements/MapMarkersForArea/MapMarkerArea';
import { useMapOptions, usePressurePoints } from 'src/pages/MapView/hooks';
import { WellborePurpose } from 'src/types';
import { filterPressurePoints } from 'src/utils';

export const MapMarkersForArea: FC = () => {
  const { wellbores } = useMeasuredData();
  const { pressurePoints } = usePressurePoints();
  const { layers } = useMapOptions();

  const filteredPressurePoints = useMemo(
    () => filterPressurePoints(pressurePoints, wellbores, layers),
    [layers, pressurePoints, wellbores]
  );

  const blocks = Array.from(new Set(wellbores.map((well) => well.block)));

  const areaPressurePoints = useMemo(
    () =>
      blocks.reduce(
        (prev, cur) => {
          if (cur && cur !== '') {
            [WellborePurpose.INJECTION, WellborePurpose.PRODUCTION].forEach(
              (purpose) => {
                const wellboreUuids = wellbores
                  .filter(
                    (w) => w.block === cur && w.purpose === (purpose as string)
                  )
                  .map((wellbore) => wellbore.wellboreUuid)
                  .filter((id) => id);

                if (wellboreUuids.length !== 0) {
                  prev.push({
                    block: cur,
                    purpose: purpose,
                    data: filteredPressurePoints.filter((pp) =>
                      wellboreUuids.includes(pp.wellboreUuid)
                    ),
                  });
                }
              }
            );
          }
          return prev;
        },
        [] as {
          block: string;
          purpose: WellborePurpose;
          data: PressureDataSimple[];
        }[]
      ),
    [blocks, filteredPressurePoints, wellbores]
  );

  if (filteredPressurePoints.length === 0) return null;
  return (
    <>
      {areaPressurePoints.map((item) => {
        return (
          <MapMarkerArea
            key={`${item.block}//${item.purpose}//${item.data.at(0)?.wellboreUuid}`}
            item={item}
          />
        );
      })}
    </>
  );
};

MapMarkersForArea.displayName = 'MapMarker';
