import {
  MAP_RECENTER_OFFSET_LAT,
  MAP_RECENTER_OFFSET_LNG,
  MAP_ZOOM_MAX_OFFSET,
  MAP_ZOOM_MIN_OFFSET,
} from 'src/pages/MapView/MapView.constants';

import { useGetFieldConfig } from 'hooks/useGetFieldConfig';

export function useGetFieldConfigForMap() {
  const { data: fieldConfig } = useGetFieldConfig();

  if (!fieldConfig)
    return {
      longitude: undefined,
      latitude: undefined,
      zoom: undefined,
      maxZoom: undefined,
      minZoom: undefined,
      reCenterLatRange: undefined,
      reCenterLngRange: undefined,
      groupingThreshold: undefined,
    };

  return {
    longitude: fieldConfig.longitude,
    latitude: fieldConfig.latitude,
    zoom: fieldConfig.zoom,
    maxZoom: fieldConfig.zoom + MAP_ZOOM_MAX_OFFSET,
    minZoom: fieldConfig.zoom - MAP_ZOOM_MIN_OFFSET,
    reCenterLatRange: [
      fieldConfig.latitude - MAP_RECENTER_OFFSET_LAT,
      fieldConfig.latitude + MAP_RECENTER_OFFSET_LAT,
    ],
    reCenterLngRange: [
      fieldConfig.longitude - MAP_RECENTER_OFFSET_LNG,
      fieldConfig.longitude + MAP_RECENTER_OFFSET_LNG,
    ],
    groupingThreshold: fieldConfig.zoom + 1,
  };
}
