import { FC } from 'react';

import { Button, Icon, spacings } from '@equinor/amplify-component-lib';
import { edit } from '@equinor/eds-icons';

import { PrognosisDetailDto } from 'src/api';

import { styled } from 'styled-components';

const ActionColumnContainer = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  padding: ${spacings.xx_small};
  justify-content: center;
`;

interface ActionsCell {
  index: number;
  detailId: number;
  detailRow: PrognosisDetailDto;
  handleOnOpenEditModal: (detailRow: PrognosisDetailDto) => void;
}

export const ActionsCell: FC<ActionsCell> = ({
  index,
  detailRow,
  handleOnOpenEditModal,
}) => {
  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleOnOpenEditModal(detailRow);
  };

  return (
    <ActionColumnContainer>
      <Button
        name="editDetailsButton"
        data-testid={`editDetailsButton-${index}`}
        variant="ghost_icon"
        onClick={(event) => handleOnClick(event)}
      >
        <Icon data={edit} />
      </Button>
    </ActionColumnContainer>
  );
};
