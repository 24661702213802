import { FC, Fragment, useState } from 'react';

import { colors } from '@equinor/amplify-component-lib';
import { layers as layers_icon } from '@equinor/eds-icons';

import { MapOptionsMenuItem } from 'src/pages/MapView/components/MapSettings/components/MapOptionsMenuItem';
import { SettingsMenu } from 'src/pages/MapView/components/MapSettings/components/SettingsMenu';
import { useMapOptions } from 'src/pages/MapView/hooks';
import { MapBackground, MapLayer } from 'src/pages/MapView/MapView.types';

import styled from 'styled-components';

const Divider = styled.div`
  border-bottom: 1px solid ${colors.ui.background__medium.rgba};
  margin: 12px 0;
`;

const DIVIDER_AFTER_LAYERS = [MapLayer.AREAS_OUTLINE, MapLayer.MISSING_WELLS];

export const MapLayers: FC = () => {
  const [open, setOpen] = useState(false);
  const { backgroundLayer, toggleBackgroundLayer, layers, toggleLayer } =
    useMapOptions();

  return (
    <SettingsMenu
      open={open}
      setOpen={setOpen}
      title={'Map layers'}
      icon={layers_icon}
      isLastItem
    >
      {Object.values(MapLayer).map((value) => (
        <Fragment key={value}>
          <MapOptionsMenuItem
            checked={layers.includes(value)}
            text={value}
            onClick={() => toggleLayer(value)}
          />
          {DIVIDER_AFTER_LAYERS.includes(value) && <Divider />}
        </Fragment>
      ))}
      <Divider />
      {Object.values(MapBackground).map((value) => (
        <Fragment key={value}>
          <MapOptionsMenuItem
            onClick={() => toggleBackgroundLayer(value)}
            checked={backgroundLayer === MapBackground.INTERPOLATED}
            text="Interpolated map"
          />
        </Fragment>
      ))}
    </SettingsMenu>
  );
};
