import { useMemo } from 'react';

import { PolygonLayer } from '@deck.gl/layers';
import { useQuery } from '@tanstack/react-query';

import { PolygonFeature } from 'src/api';
import { FieldService } from 'src/api';
import { FAULT_LINES_QUERY_KEY, MEASURED_DATA_MAP_KEY } from 'src/constants';
import { useApp } from 'src/hooks';
import { MapLayer } from 'src/pages/MapView/MapView.types';

export function useFaultLines() {
  const { field } = useApp();

  const { data: faultLineFeatureCollection } = useQuery({
    queryKey: [MEASURED_DATA_MAP_KEY, FAULT_LINES_QUERY_KEY, field?.uuid],
    queryFn: async () => FieldService.getFieldFaultLines(field?.uuid ?? ''),
    staleTime: Infinity,
    enabled: !!field?.uuid,
  });

  return useMemo(
    () =>
      new PolygonLayer({
        id: MapLayer.FAULT_LINES,
        filled: true,
        data: faultLineFeatureCollection?.features ?? [],
        getPolygon: (d: PolygonFeature) => d.geometry?.coordinates,
        getLineColor: [190, 190, 190],
        getFillColor: [190, 190, 190],
        getLineWidth: 1,
        lineWidthMinPixels: 2,
      }),
    [faultLineFeatureCollection?.features]
  );
}
