import { FC, ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { EditPrognosisFormValues, useEditPrognosisSchema } from 'src/utils';

interface FormProviderWrapperProps {
  children: ReactNode;
}

export const defaultValues = {
  title: undefined,
  operation: undefined,
  approver: undefined,
  mainApprover: undefined,
  backupApprover: undefined,
  validFrom: undefined,
  validTo: undefined,
  wellbore: undefined,
  comment: undefined,
  prognosisDetails: [],
};

export const FormProviderWrapper: FC<FormProviderWrapperProps> = ({
  children,
}) => {
  const schema = useEditPrognosisSchema();

  const methods = useForm<EditPrognosisFormValues>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
