import { SelectOptionRequired } from '@equinor/amplify-component-lib';

import { PrognosisDetailKeys } from '../components/FormData/DataInputDialog/DataInputDialog.utils';
import { OptionalAttributes } from '../providers/OptionalAttributeProvider';
import { EditPrognosisFormValues, OperationValues } from 'src/utils';

/**
 * Checks if the prognosis form data is valid for posting as a draft.
 *
 * @param data - The form values for editing the prognosis.
 * @param onCreatePage - A boolean indicating if the check is being performed on the create page.
 * @returns A boolean indicating if the form data is valid for posting as a draft.
 */
export const checkPostDraftPrognosis = (
  data: EditPrognosisFormValues,
  onCreatePage: boolean,
  isCreating: boolean
) => {
  const {
    operation,
    mainApprover,
    backupApprover,
    validFrom,
    validTo,
    wellbore,
    operationPhase,
  } = data;

  const shouldIncludePhase =
    operation.value === OperationValues.NEW_WELL.toString() ||
    operation.value === OperationValues.INTERVENTION.toString();

  if (
    onCreatePage &&
    operation &&
    mainApprover &&
    backupApprover &&
    validFrom &&
    validTo &&
    wellbore &&
    (!shouldIncludePhase || (shouldIncludePhase && operationPhase)) &&
    !isCreating
  ) {
    return true;
  }

  return false;
};

/**
 * Determines whether the wellbore section should be shown based on the provided form values and page context.
 *
 * @param data - The form values of the Edit Prognosis form.
 * @param onCreatePage - A boolean indicating if the current page is the create page.
 * @returns A boolean indicating whether the wellbore section should be shown. If the wellbore section should be shown, the value is true; otherwise, it is false.
 */
export const checkShowWellbore = (
  data: EditPrognosisFormValues,
  onCreatePage: boolean
) => {
  const { operation, mainApprover, validFrom, validTo, operationPhase } = data;

  if (!onCreatePage) {
    return true;
  }

  if (operation && mainApprover && validFrom && validTo) {
    if (
      (operation?.value === OperationValues.NEW_WELL.toString() &&
        !operationPhase) ||
      (operation?.value === OperationValues.INTERVENTION.toString() &&
        !operationPhase)
    ) {
      return false;
    }
    return true;
  }

  return false;
};

export interface NextValueProps {
  key: keyof Pick<
    EditPrognosisFormValues,
    'wellbore' | 'stratColumnIdentifier'
  >;
  item: SelectOptionRequired | undefined;
}

/**
 * Filters the keys of a PrognosisDetailKeys object based on optional attributes and conditions.
 *
 * @param keys - The object containing the keys to be filtered.
 * @param optionalAttributes - An object specifying which optional attributes are available.
 * @param isTotalDepthRow - A boolean indicating if the current row is a total depth row.
 * @returns A new PrognosisDetailKeys object with the filtered keys.
 */
export const filterFieldKeys = (
  keys: PrognosisDetailKeys,
  optionalAttributes: OptionalAttributes,
  isTotalDepthRow: boolean | undefined
): PrognosisDetailKeys => {
  return Object.fromEntries(
    Object.entries(keys).filter(([key]) => {
      if (key === 'pressureInitial' && !optionalAttributes.initialPressure) {
        return false;
      }
      if (key === 'referenceDepth' && isTotalDepthRow) {
        return false;
      }
      return true;
    })
  ) as PrognosisDetailKeys;
};
