import { spacings } from '@equinor/amplify-component-lib';
import { tokens } from '@equinor/eds-tokens';

import styled from 'styled-components';

const { shape, colors, elevation } = tokens;

interface MainContentProps {
  $disabled?: boolean;
}
export const MainContent = styled.div<MainContentProps>`
  display: flex;
  padding: ${spacings.large};
  flex-direction: column;
  align-items: center;
  gap: ${spacings.large};
  align-self: stretch;
  border-radius: ${shape.corners.borderRadius};
  background-color: ${colors.ui.background__default.rgba};
  box-shadow: ${elevation.raised};
  opacity: ${({ $disabled }) => ($disabled ? 0.3 : 1)};
`;

export const InitializingContainer = styled.div`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  gap: ${spacings.medium};
  justify-content: center;
  align-items: center;
`;

interface ComboBoxProps {
  $width?: string;
}

export const ComboboxContainer = styled.div<ComboBoxProps>`
  width: 100%;
  max-width: ${({ $width }) => $width || '320px'};
`;
